import { Link } from "react-router-dom";
import React from "react";
import DateFormatDisplay from "../../../../components/Custom/Form/Display/DateFormatDisplay";
import StateBadge from "../../../../components/Custom/UI/States/StateBadge";
import LotExpiration from "../components/LotExpiration";

export const lotDataEntryColumns = [
  {
    id: 'lot_number',
    Header: 'Lotto',
    accessor: 'lot_number',
    Cell: ({ row, value }) =>
      <Link className={`fw-bold fs-4 ${row.original.is_urgent ? 'text-danger' : ''}`}
            to={"/lots/" + row.original.uuid}>
        {value}
      </Link>
  }, {
    Header: 'Codice',
    accessor: 'lot_data.item',
    Cell: ({ row, value }) => <div className='flex flex-column text-nowrap'>
      <div className="fw-bold">{value.code}</div>
      <div>{value?.description}</div>
    </div>
  }, {
    Header: "Stato",
    accessor: 'state',
    Cell: ({ value }) => <StateBadge value={value}/>
  }, {
    Header: "Qtà",
    accessor: 'total_qty',
  }, {
    Header: "DX",
    accessor: 'dx_qty',
  }, {
    Header: "SX",
    accessor: 'sx_qty',
  }, {
    Header: "Casa",
    accessor: 'lot_data.manufacturer',
    Cell: ({ value }) => <>{value?.name}</>
  }, {
    Header: "Veicolo",
    accessor: 'lot_data.vehicle',
    Cell: ({ value }) => <>{value?.name}</>
  }, {
    Header: "Marchio",
    accessor: 'lot_data.brand',
    Cell: ({ value }) => <>{value?.name}</>
  }, {
    Header: "Colore",
    accessor: 'lot_data.color',
    Cell: ({ value }) => <>{value?.name}</>
  }, {
    Header: "Curvatura",
    accessor: 'lot_data.curvature',
    Cell: ({ value }) => <>{value?.name}</>
  }, {
    Header: "Spessore",
    accessor: 'lot_data.thickness',
    Cell: ({ value }) => <>{value?.value}</>
  }, {
    Header: "Emesso",
    accessor: 'lot_data.user',
    Cell: ({ value }) => <>{value?.full_name}</>
  }, {
    Header: "Inizio",
    accessor: 'start_date',
    Cell: ({ value }) => <DateFormatDisplay value={value}/>
  }, {
    Header: "Consegna",
    accessor: 'forecast_date',
    Cell: ({ value, row }) => <LotExpiration value={value} lotState={row.original.state.value}/>
  }, {
    Header: "Consegnato",
    accessor: 'end_date',
    Cell: ({ value, row }) => <LotExpiration value={value} lotState={row.original.state.value}/>
  }
];
