import React from "react";
import {Field} from "formik";

export const FormRadio = (props) => (
    <div className="form-check ">
        <Field type="radio" className="form-check-input " {...props}/>
        <label>{props.label}</label>
    </div>
)

