import React from 'react';

const SerigrafiaLunottiReportsTable = ({ children, action = undefined }) => {

  return (
    <div className='table-responsive'>
      <table className='table table-hover align-middle'>
        <thead>
        <tr>
          <th className='text-end'>Controllo</th>
          <th className='text-center'>Tolleranza</th>
          <th className='text-center'>Aspetto sbordatura o mancanza di serigrafia</th>
        </tr>
        <tr>
          <th className='text-end'>Tolleranza</th>
          <th className='text-center'>+0mm/-2mm</th>
          <th className='text-center'></th>
        </tr>
        </thead>
        <tbody>
        {children}
        </tbody>
        {action}
      </table>
    </div>
  )

}

export default SerigrafiaLunottiReportsTable
