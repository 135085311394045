import React from 'react';
import { useRequest } from 'ahooks';
import { lotService } from '../../../services/lotService';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import tableOptions from '../../../constants/tableOptions';
import CreateButton from '../../../components/Custom/Form/Buttons/CreateButton';
import { industryLotColumns } from './components/industryLotColumns';
import IndustryLotFilters from './components/IndustryLotFilters';

const IndustryLots = () => {

  const { data, loadingLot, run } = useRequest(lotService.fetchAll)
  const navigate = useNavigate()
  const columns = React.useMemo(() => industryLotColumns, [])
  const handleRun = (params) => {
    run({
      ...params,
      filter: {
        ...params.filter,
        state: ['in_progress']
      }
    })
  }

  return (
    <>
      <IndustryLotFilters
        onChangeFilters={(filters) => run(filters)}
      />

      <Card>
        <CardBody>

          <PaginatedTable
            options={tableOptions}
            TableActions={
              <CreateButton handleClick={() => navigate('create')}>Nuovo Lotto</CreateButton>
            }
            columns={columns}
            data={data ?? []}
            isLoading={loadingLot}
            filterable={false}
            onParamsChange={handleRun}
          />

        </CardBody>
      </Card>

    </>
  )
}

export default IndustryLots
