import * as Yup from "yup";

const validation = Yup.object().shape({
    value: Yup.number().min(0.01, "Deve essere maggiore di zero").required("Obbligatorio"),
    description: Yup.string().max(255, "Max 255 caratteri").notRequired(),
    type: Yup.string().required("Obbligatorio"),
    note: Yup.string().notRequired()
})

const initialValues = {
    value: '',
    type: '',
    description: '',
    note: '',
}
export const thicknessForm = {
    validation,
    initialValues
}