import React, { useEffect } from "react"
import { Container, } from "reactstrap"
import { useSanctum } from 'react-sanctum';
import { useNavigate } from 'react-router-dom';
import ActivityCheckerWidget from './Widgets/ActivityCheckerWidget';

const Dashboard = props => {

  const { user } = useSanctum()
  const navigate = useNavigate()

  useEffect(() => {
    if (user.can_manage_data_entry_module &&
      !user.can_manage_administration_module &&
      !user.can_manage_production_module &&
      !user.can_manage_industry_module) {
      navigate('/lots')
    }
  }, [user])

  return (
    <div>
      <Container fluid>
        <div className='row'>
          <div className='col-lg-8'>

          </div>
          <div className='col-lg-4 border-light border-top-0 border-bottom-0 border-end-0 border-2 border'>
            <h6>Area Notifiche</h6>
            {user.can_manage_production_module ? <ActivityCheckerWidget/> : <></>}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Dashboard
