import api from '../helpers/axios';

const BASE_URL = 'users';

const fetchAll = (params) => api.get(BASE_URL, { params }).then(res => res.data);
const fetch = (id) => api.get(BASE_URL + '/' + id);
const store = (attributes = {}) => api.post(BASE_URL, attributes).then(res => res.data);
const update = (id, attributes = {}) => api.put(BASE_URL + '/' + id, attributes).then(res => res.data);
const updatePassword = (id, attributes = {}) => api.put(BASE_URL + '/' + id + '/password', attributes);

const selectable = () => api.get('/selectable/' + BASE_URL).then(res => res.data);
const destroy = (id) => api.delete(BASE_URL + '/' + id);

const userService = {
  fetchAll,
  fetch,
  store,
  update,
  updatePassword,
  selectable,
  destroy
};

export default userService;
