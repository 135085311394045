import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import toolJobService from '../../../../services/toolJobService';
import { toast } from 'react-toastify';
import FormGroup from '../../../../components/Custom/Form/FormGroup';
import LotPicker from '../../../../components/Custom/Form/Pickers/LotPicker/LotPicker';

const LotSearch = ({ toolConfig, setData }) => {

  return (
    <Card>
      <CardBody>
        <Formik
          initialValues={{ lot: '' }}
          validationSchema={Yup.object().shape({ lot: Yup.string().nullable().required() })}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            toolJobService.fetchLot(toolConfig.uuid, values.lot)
            .then(res => setData(res))
            .catch(err => toast.error(err.response.data.message))
            .finally(() => setSubmitting(false))
          }}
        >
          {({ handleSubmit, setFieldValue, isValid, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>

              <FormGroup label="Ricerca per numero di lotto"
                         name="lot"
                         required={true}
                         inline={false}>
                <LotPicker
                  handleChange={v => setFieldValue("lot", v?.uuid ?? '')}
                />
              </FormGroup>
              <Button
                disabled={!isValid || isSubmitting}
                type="submit"
                className="float-end"
                size="sm"
                color="info">
                <i className="fa fa-filter"/> Filtra risultati
              </Button>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>

  )

}

export default LotSearch

