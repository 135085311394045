import React, { useState } from "react";
import { itemService } from "../../../../services/itemService";
import { useNavigate } from "react-router-dom";
import { useRequest } from "ahooks";
import tableOptions from "../../../../constants/tableOptions";
import { PaginatedTable } from "@dvsoftsrl/react-laravel-paginated-table";
import CreateButton from "../../../../components/Custom/Form/Buttons/CreateButton";
import { itemColumns } from "./itemColumns";
import ItemsFilters from './ItemsFilters';
import ItemCreateCanvas from '../component/ItemCreateCanvas';

const Items = () => {

  const { data, loading, run } = useRequest(itemService.fetchAll, { manual: true })
  const navigate = useNavigate()
  const columns = React.useMemo(() => itemColumns, [])
  const [canvasOpen, setCanvasOpen] = useState(false);

  return (
    <>
      <ItemsFilters run={run}/>
      <ItemCreateCanvas
        isOpen={canvasOpen}
        toggle={setCanvasOpen}
        data={undefined}
        handleResponse={res => navigate(res.uuid)}
      />

      <div className='card'>
        <div className='card-body'>
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <h3>Articoli</h3>
            <CreateButton handleClick={() => setCanvasOpen(true)}>Nuovo</CreateButton>
          </div>
          <PaginatedTable
            options={tableOptions}
            columns={columns}
            data={data ?? []}
            isLoading={loading}
            filterable={false}
            sortable
            onParamsChange={run}
          />
        </div>
      </div>


    </>
  );
}


export default Items;
