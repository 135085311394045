import React, { useContext } from "react";
import { FormDisplay } from "../../../../../../components/Custom/Form/Display/FormDisplay";
import HourFormatDisplay from "../../../../../../components/Custom/Form/Display/HourFormatDisplay";
import DateFormatDisplay from "../../../../../../components/Custom/Form/Display/DateFormatDisplay";
import { UncontrolledTooltip } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { activityService } from '../../../../../../services/ActivityService';
import { toast } from 'react-toastify';
import { LotContext } from '../../../LotWrapper';
import { SweetAlert } from '../../../../../../helpers/SwalHelper';
import { lotWorkService } from '../../../../../../services/lotWorkService';
import { WorkTypesEnum } from '../../../../../../constants/enums/WorkTypesEnum';
import MediaOffCanvas from '../Activity/components/MediaOffCanvas/MediaOffCanvas';

const ActivityTableShow = ({
                             withReworked = true,
                             canShow = false,
                             canCheck = false,
                             canDestroy = false,
                             canEdit = false,
                             lot,
                             lotWork,
                             activity
                           }) => {

  const navigate = useNavigate()
  const { mutateLot } = useContext(LotContext)
  const [mediaCanvasOpen, setMediaCanvasOpen] = React.useState(false)

  const handleToggleCheck = () => {
    activityService.toggleCheck(lot?.uuid, lotWork?.uuid, activity?.uuid)
    .then(res => {
      mutateLot(l => ({
        ...l,
        lot_works: l.lot_works.map(lw => lw.uuid !== lotWork.uuid
          ? lw
          : { ...lw, activities: lw.activities.map(a => a.uuid !== activity.uuid ? a : res) }
        )
      }))
    }).catch(err => toast.error(err.response.data.message))
  }

  const handleDelete = () => SweetAlert.fire({ text: "Sei sicuro di voler eliminare l'attività?", icon: "warning" })
  .then(response => {
    if (response.isConfirmed) {
      activityService.destroy(lot?.uuid, lotWork?.uuid, activity?.uuid)
      .then(activityRes => {
        lotWorkService.fetch(lot?.uuid, lotWork?.uuid)
        .then(lotWorkRes => {
          mutateLot(lot => ({ ...lot, lot_works: lot.lot_works.map(lw => lw.uuid !== lotWork?.uuid ? lw : lotWorkRes) }))
        })
        .catch(err => toast.error(err.response.data.message))
        .finally(() => {
          toast.success('Lavorazione eliminata con successo')
          navigate('../')
        })
      })
      .catch(err => toast.error(err.response.data.message))
    }
  })


  return (
    <div className='card-body'>
      <div className='row'>
        <div className='col-3'>
          <FormDisplay inline={false} label="Data attività">
            <span className="fw-bolder"><DateFormatDisplay value={activity?.date}/></span>
          </FormDisplay>
        </div>
        <div className='col-3'>
          <FormDisplay inline={false} label="Inserita da">
            <span className="fw-bolder">{activity?.username}</span>
          </FormDisplay>
        </div>
        <div className='col-6'>
          <div className='d-flex align-items-center justify-content-end gap-2 d-print-none'>


            {canShow && <>
              <UncontrolledTooltip target={`show_btn_${activity.uuid}`}>Dettaglio attività</UncontrolledTooltip>
              <button onClick={() => navigate(activity.uuid)} className='btn btn-primary btn-icon' id={`show_btn_${activity.uuid}`}>
                <i className='fa fa-eye'/>
              </button>
            </>}

            {canCheck && <>
              <UncontrolledTooltip target={`check_btn_${activity.uuid}`}>Attività {activity.checked ? 'controllata' : 'da controllare'}</UncontrolledTooltip>
              <button
                onClick={() => handleToggleCheck()}
                type='button'
                className={`btn btn-${activity.checked ? 'success' : 'danger'} btn-icon`}
                id={`check_btn_${activity.uuid}`}>
                <i className={`fa fa-${activity.checked ? 'check' : 'exclamation'}`}/>
              </button>
            </>}


            {activity?.type.value === WorkTypesEnum.tempera.value && activity?.activity_data?.with_fragmentation &&
              <>
                <UncontrolledTooltip target={`show_media_btn_${activity.uuid}`}>Foto Frammentazione</UncontrolledTooltip>
                <button onClick={() => setMediaCanvasOpen(true)} className='btn btn-primary btn-icon' id={`show_media_btn_${activity.uuid}`}>
                  <i className='fa fa-camera'/>
                </button>

                <MediaOffCanvas
                  toggle={setMediaCanvasOpen}
                  isOpen={mediaCanvasOpen}
                  lot={lot}
                  lotWork={lotWork}
                  activity={activity}
                />

              </>
            }

            {canEdit && <>
              <UncontrolledTooltip target={`edit_btn_${activity.uuid}`}>Modifica attività</UncontrolledTooltip>
              <button onClick={() => navigate('edit', {
                state: {
                  activity: activity
                }
              })} type='button' className='btn btn-primary btn-icon' id={`edit_btn_${activity.uuid}`}>
                <i className='fa fa-pen'/>
              </button>
            </>}

            {canDestroy && <>
              <UncontrolledTooltip target={`delete_btn_${activity.uuid}`}>Elimina attività</UncontrolledTooltip>
              <button
                onClick={() => handleDelete()}
                type='button'
                className='btn btn-danger btn-icon'
                id={`delete_btn_${activity.uuid}`}>
                <i className='fa fa-times'/>
              </button>

            </>}

          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-3 col-6'>
          {activity?.activity_data?.tool &&
            <FormDisplay inline={false} label="Eseguita con">
              {activity?.activity_data?.tool?.name}
            </FormDisplay>
          }
        </div>
        <div className='col-lg-3 col-6'>
          <FormDisplay inline={false} label="Ore lavorate">
            <HourFormatDisplay minutes={activity?.hour_amount}/>
          </FormDisplay>
        </div>
        <div className='col-lg-3 col-6'>
          <FormDisplay inline={false} label="Ore fermo">
            <HourFormatDisplay minutes={activity?.stop_hour_amount}/>
          </FormDisplay>
        </div>
        <div className='col-lg-3 col-6'>
          <FormDisplay inline={false} label="Produzione oraria">
            {activity?.hour_amount
              ? (60 / Math.round(activity?.hour_amount / (activity?.manufactured_qty + activity.manufactured_dx_qty + activity.manufactured_sx_qty))).toFixed(
                2)
              : activity?.hour_amount} pezzi/ora
          </FormDisplay>
        </div>
      </div>
      <div className='table-responsive'>
        <table className='table table-hover'>
          <thead>
          <tr className={activity?.activity_data?.with_fragmentation ? 'table-warning' : 'table-primary'}>
            <th></th>
            <th className='text-center'>Idonei</th>
            <th className='text-center'>Scarti</th>
            {withReworked && <th>Da rilavorare</th>}
            {withReworked && <th>Rilavorati</th>}
          </tr>
          </thead>
          <tbody>
          <tr>
            <td className='text-end'>PIANI</td>
            <td className='text-center'>{activity?.manufactured_qty}</td>
            <td className='text-center'>{activity?.wasted_qty}</td>
            {withReworked && <td>{activity?.to_be_reworked_qty}</td>}
            {withReworked && <td>{activity?.rework_qty}</td>}
          </tr>
          <tr>
            <td className='fw-bold text-end'>DX</td>
            <td className='text-center'>{activity?.manufactured_dx_qty}</td>
            <td className='text-center'>{activity?.wasted_dx_qty}</td>
            {withReworked && <td>{activity?.to_be_reworked_dx_qty}</td>}
            {withReworked && <td>{activity?.rework_dx_qty}</td>}
          </tr>
          <tr>
            <td className='fw-bold text-end'>SX</td>
            <td className='text-center'>{activity?.manufactured_sx_qty}</td>
            <td className='text-center'>{activity?.wasted_sx_qty}</td>
            {withReworked && <td>{activity?.to_be_reworked_sx_qty}</td>}
            {withReworked && <td>{activity?.rework_sx_qty}</td>}
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  )

}

export default ActivityTableShow
