import React from 'react';
import ProvaDiFrammentazione from '../ProvaDiFrammentazione';
import ProvaDiTipoShow from './ProvaDiTipoShow';

const ProvaDiFrammentazioneShow = ({ provaFrammentazione }) => {

  const getColor = letter => {
    if (letter === 'a' || letter === 'A')
      return 'info'
    else if (letter === 'b' || letter === 'B')
      return 'danger'
    else
      return 'light'
  }

  return (
    <ProvaDiFrammentazione>
      {provaFrammentazione.map(pf =>
        <ProvaDiTipoShow
          key={pf.letter}
          provaTipo={pf}
          color={getColor(pf.letter)}
        />
      )}
    </ProvaDiFrammentazione>
  )

}

export default ProvaDiFrammentazioneShow
