import React from 'react';
import HourFormatDisplay from '../../../../components/Custom/Form/Display/HourFormatDisplay';
import DateFormatDisplay from '../../../../components/Custom/Form/Display/DateFormatDisplay';
import { WorkTypesEnum } from '../../../../constants/enums/WorkTypesEnum';
import { FormDisplay } from '../../../../components/Custom/Form/Display/FormDisplay';
import { Link } from 'react-router-dom';

export const ActivityToCheck = ({ activity }) => {

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-3'>
              <FormDisplay inline={false} label="Numero Lotto">
                <Link to={`/lots/${activity?.lot?.uuid}`} className="fw-bolder">{activity?.lot?.lot_number}</Link>
              </FormDisplay>
            </div>
            <div className='col-3'>
              <FormDisplay inline={false} label="Data attività">
                <span className="fw-bolder"><DateFormatDisplay value={activity?.date}/></span>
              </FormDisplay>
            </div>
            <div className='col-3'>
              <FormDisplay inline={false} label="Inserita da">
                <span className="fw-bolder">{activity?.username}</span>
              </FormDisplay>
            </div>
            <div className='col-3'>
              <div className='d-flex align-items-center justify-content-end gap-2'>
                <Link
                  className='btn btn-primary btn-icon'
                  target={'_blank'}
                  to={`/lots/${activity.lot.uuid}/${activity.lot_work.uuid}/${activity.uuid}`}>
                  <i className='fa fa-eye'/>
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3 col-6'>
              {activity?.activity_data?.tool &&
                <FormDisplay inline={false} label="Eseguita con">
                  {activity?.activity_data?.tool?.name}
                </FormDisplay>
              }
            </div>
            <div className='col-lg-3 col-6'>
              <FormDisplay inline={false} label="Ore lavorate">
                <HourFormatDisplay minutes={activity?.hour_amount}/>
              </FormDisplay>
            </div>
            <div className='col-lg-3 col-6'>
              <FormDisplay inline={false} label="Ore fermo">
                <HourFormatDisplay minutes={activity?.stop_hour_amount}/>
              </FormDisplay>
            </div>
            <div className='col-lg-3 col-6'>
              <FormDisplay inline={false} label="Produzione oraria">
                {activity?.hour_amount
                  ? (60 / Math.round(activity?.hour_amount / (activity?.manufactured_qty + activity.manufactured_dx_qty + activity.manufactured_sx_qty))).toFixed(
                    2)
                  : activity?.hour_amount} pezzi/ora
              </FormDisplay>
            </div>
          </div>
          <div className='table-responsive'>
            <table className='table table-hover'>
              <thead>
              <tr className='table-primary'>
                <th></th>
                <th className='text-center'>Idonei</th>
                <th className='text-center'>Scarti</th>
                {activity?.lotWork?.type.value !== WorkTypesEnum.taglio && <th>Da rilavorare</th>}
                {activity?.lotWork?.type.value !== WorkTypesEnum.taglio && <th>Rilavorati</th>}
              </tr>
              </thead>
              <tbody>
              <tr>
                <td className='text-end'>PIANI</td>
                <td className='text-center'>{activity?.manufactured_qty}</td>
                <td className='text-center'>{activity?.wasted_qty}</td>
                {activity?.lotWork?.type.value !== WorkTypesEnum.taglio && <td>{activity?.to_be_reworked_qty}</td>}
                {activity?.lotWork?.type.value !== WorkTypesEnum.taglio && <td>{activity?.rework_qty}</td>}
              </tr>
              <tr>
                <td className='fw-bold text-end'>DX</td>
                <td className='text-center'>{activity?.manufactured_dx_qty}</td>
                <td className='text-center'>{activity?.wasted_dx_qty}</td>
                {activity?.lotWork?.type.value !== WorkTypesEnum.taglio && <td>{activity?.to_be_reworked_dx_qty}</td>}
                {activity?.lotWork?.type.value !== WorkTypesEnum.taglio && <td>{activity?.rework_dx_qty}</td>}
              </tr>
              <tr>
                <td className='fw-bold text-end'>SX</td>
                <td className='text-center'>{activity?.manufactured_sx_qty}</td>
                <td className='text-center'>{activity?.wasted_sx_qty}</td>
                {activity?.lotWork?.type.value !== WorkTypesEnum.taglio && <td>{activity?.to_be_reworked_sx_qty}</td>}
                {activity?.lotWork?.type.value !== WorkTypesEnum.taglio && <td>{activity?.rework_sx_qty}</td>}
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )

}
