import React from 'react';
import { useSanctum } from 'react-sanctum';
import { get } from 'lodash';
import { Alert } from 'reactstrap';

/**
 * Controllo che almeno un modulo sia abilitato (OR)
 * @param children
 * @param module
 * @returns {JSX.Element}
 * @constructor
 */
const CheckUserModule = ({ children, module = [] }) => {

  const { user } = useSanctum()

  if (module.filter(m => user.hasOwnProperty(m) && get(user, m)).length > 0)
    return <>{children}</>

  return <Alert color='danger'>
    <div className='fs-3 text-danger'>
      Accesso non consentito.
    </div>
    Non disponi dei permessi necessari per accedere all'area desiderata.<br/> Contatta un amministratore di sistema.
  </Alert>

}

export default CheckUserModule
