import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const Industria40Dropdown = ({ user }) => {

  if (user.can_manage_industry_module) {
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>Industria 4.0</DropdownToggle>
        <DropdownMenu>
          <DropdownItem href='/tool-configs'>
            <i className="bx bx-history"/>&nbsp;Strumenti
          </DropdownItem>
          <DropdownItem href='/industry-lots'>
            <i className="bx bx-search"/>&nbsp;Consulta lotti
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  return <></>

}

export default Industria40Dropdown
