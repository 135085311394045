import React from 'react'
import {FormText} from "reactstrap";
import {SweetAlert} from "../../../../helpers/SwalHelper";

const CanvasCreateEditButtons = ({value, canvasOpen, setCanvasOpen, handleDelete}) => {

    const deleteClicked = () => {
        SweetAlert.fire({
            text: "Sei sicuro?", icon: "warning"
        }).then(response => {
            if (response.isConfirmed) {
                handleDelete()
            }
        });
    }

    return <FormText>
        <div className='d-flex align-content-center justify-content-start gap-1 my-1'>
            {value
                ? <>
                    <button className='btn btn-sm btn-warning' type="button" onClick={() => setCanvasOpen(!canvasOpen)}>
                        <i className="fa fa-pencil-alt"/>&nbsp;Modifica
                    </button>
                    <button className='btn btn-sm btn-danger' type="button" onClick={() => deleteClicked()}>
                        <i className='fa fa-trash-alt'/>&nbsp;Elimina
                    </button>
                </>
                : <button className='btn btn-sm btn-info' type="button" onClick={() => setCanvasOpen(!canvasOpen)}>
                    <i className="fa fa-plus"/>&nbsp;Crea
                </button>
            }
        </div>

    </FormText>


}

export default CanvasCreateEditButtons