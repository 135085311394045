import React from 'react';

const TemperaReportsTable = ({children, action = undefined}) => {

  return (
    <div className='table-responsive'>
      <table className='table table-hover align-middle'>
        <thead>
        <tr>
          <th className='text-end'>Controllo</th>
          <th className='text-center'>Conformità marchio</th>
          <th className='text-center'>Conformità grafica marchio</th>
          <th className='text-center'>Curvatura +/-2mm</th>
          <th className='text-center'>Perimetrale</th>
          <th className='text-center'>Sfericità</th>
          <th className='text-center'>Aspetto superficie</th>
        </tr>
        </thead>
        <tbody>
        {children}
        </tbody>
        {action}
      </table>
    </div>
  )

}

export default TemperaReportsTable
