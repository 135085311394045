import api from '../helpers/axios';

const buildUrl = (lotUuid, lotShipmentUuid) => lotShipmentUuid
  ? `/lots/${lotUuid}/lot-shipments/${lotShipmentUuid}`
  : `/lots/${lotUuid}/lot-shipments`;

const fetchAll = lotUuid => api.get(buildUrl(lotUuid)).then(res => res.data);
const store = (lotUuid, attributes = {}) => api.post(buildUrl(lotUuid), attributes).then(res => res.data);
const update = (lotUuid, lotShipmentUuid, attributes = {}) => api.put(buildUrl(lotUuid, lotShipmentUuid), attributes).then(res => res.data);
const destroy = (lotUuid, lotShipmentUuid) => api.delete(buildUrl(lotUuid, lotShipmentUuid));

const lotShipmentService = {
  fetchAll,
  store,
  update,
  destroy
};

export default lotShipmentService;
