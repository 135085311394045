import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { FormDisplay } from '../../../../components/Custom/Form/Display/FormDisplay';

const LotResult = ({ lot, job_count, end, job_success, start }) => {

  return lot
    ? <>
      <h3>Lotto numero <Link to={`/industry-lots/${lot.uuid}`} target={'_blank'}>{lot.lot_number}</Link></h3>
      <Row>
        <Col lg={3} md={6}>
          <FormDisplay label='Totale elaborati'>
            <h5>{job_count}</h5>
          </FormDisplay>
        </Col>
        <Col lg={3} md={6}>
          <FormDisplay label='Totale eseguiti con successo'>
            <h5>{job_success}/{job_count}</h5>
          </FormDisplay>
        </Col>
        <Col lg={3} md={6}>
          <FormDisplay label='Data e ora inizio'>
            <h5>{start}</h5>
          </FormDisplay>
        </Col>
        <Col lg={3} md={6}>
          <FormDisplay label='Data e ora fine'>
            <h5>{end}</h5>
          </FormDisplay>
        </Col>
      </Row>
    </>
    : <></>

}

export default LotResult
