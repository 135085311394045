import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {FormDisplay} from "../../../../components/Custom/Form/Display/FormDisplay";
import DateFormatDisplay from "../../../../components/Custom/Form/Display/DateFormatDisplay";

const ArchivedLotDetail = ({lot}) => {


    return lot ? (
        <Card>
            <CardBody>
                <h3>Scheda di produzione</h3>

                <Row>
                    <Col lg={4}>
                        <FormDisplay label="Lotto di produzione">
                            <h3>{lot?.lot_number}</h3>
                        </FormDisplay>
                    </Col>
                    <Col lg={4}>
                        <FormDisplay label="Data" inline={false}>
                            <DateFormatDisplay value={lot?.lot_date}/>
                        </FormDisplay>
                    </Col>
                    <Col lg={4}>
                        <FormDisplay label="Data consegna" inline={false}>
                            <DateFormatDisplay value={lot?.forecast_date}/>
                        </FormDisplay>
                    </Col>
                </Row>

                <Row>
                    <Col lg={4}>
                        <FormDisplay label="Quantità richiesta" inline={false}>
                            <h5>{lot?.qty}</h5>
                        </FormDisplay>
                    </Col>
                    <Col lg={4}>
                        <FormDisplay label="Dei quali lato DX" inline={false}>
                            <h5>{lot?.dx_qty}</h5>
                        </FormDisplay>
                    </Col>
                    <Col lg={4}>
                        <FormDisplay label="Dei quali lato SX" inline={false}>
                            <h5>{lot?.sx_qty}</h5>
                        </FormDisplay>
                    </Col>
                </Row>

                <Row>
                    <Col lg={4}>
                        <FormDisplay label="Cliente" inline={false}>
                            {lot?.customer}
                        </FormDisplay>
                    </Col>
                    <Col lg={4}>
                        <FormDisplay label="Deroga numero" inline={false}>
                            {lot?.derogation}
                        </FormDisplay>
                    </Col>
                    <Col lg={4}>
                        <FormDisplay inline={false} label='Documento emesso'>
                            {lot?.created_by}
                        </FormDisplay>
                    </Col>
                </Row>

                <FormDisplay label="Note lotto" inline={false}>
                    {lot?.note}
                </FormDisplay>

                <hr/>
                <h3>Descrizione codice</h3>
                <Row>
                    <Col lg={4}>
                        <FormDisplay label="Codice">{lot.item_code}</FormDisplay>
                    </Col>
                    <Col lg={4}>
                        <FormDisplay label="Casa automobilistica">{lot.manufacturer}</FormDisplay>
                    </Col>
                    <Col lg={4}>
                        <FormDisplay label="Modello vettura">{lot.vehicle}</FormDisplay>
                    </Col>
                </Row>
                <FormDisplay label="Descrizione">{lot.item_description}</FormDisplay>

                <hr/>
                <h3>Caratteristiche</h3>
                <Row>
                    <Col lg={6}>
                        <FormDisplay label="Descrizione">{lot.brand}</FormDisplay>
                    </Col>
                    <Col lg={6}>
                        <FormDisplay label="Colore">{lot.color}</FormDisplay>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <FormDisplay label="Curvatura">{lot.curvature}</FormDisplay>
                    </Col>
                    <Col lg={6}>
                        <FormDisplay label="Curvatura">{lot.thickness}</FormDisplay>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    ) : <></>

}

export default ArchivedLotDetail
