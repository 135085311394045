import React from 'react'
import { Outlet } from "react-router-dom";
import CheckUserModule from '../../../components/Custom/Permissions/CheckUserModule';

const ItemsWrapper = () => {

  return (
    <CheckUserModule module={['can_manage_production_module']}>
      <Outlet/>
    </CheckUserModule>
  )

}

export default ItemsWrapper
