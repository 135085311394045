import * as Yup from "yup";
import { format, parseJSON } from "date-fns";

const setValidation = shipmentStatus => Yup.object().shape({
  qty: Yup.number("Numero non valido").min(0).max(shipmentStatus?.shippable).required("Quantità obbligatoria"),
  dx_qty: Yup.number("Numero non valido").min(0).max(shipmentStatus?.dx_shippable).required("Quantità obbligatoria"),
  sx_qty: Yup.number("Numero non valido").min(0).max(shipmentStatus?.sx_shippable).required("Quantità obbligatoria"),
  shipment_date: Yup.date("Data non valida").required("Data di spedizione obbligatoria"),
})

const initialValues = {
  qty: 0,
  dx_qty: 0,
  sx_qty: 0,
  shipment_date: format(new Date(), 'yyyy-MM-dd'),
}

const setInitialValues = data => ({
  qty: data?.qty || 0,
  dx_qty: data?.dx_qty || 0,
  sx_qty: data?.sx_qty || 0,
  shipment_date: data?.shipment_date ? format(parseJSON(data.shipment_date), 'yyyy-MM-dd') : '',
})


export const lotShipmentFormConfig = {
  setValidation,
  setInitialValues,
  initialValues
}
