import { useSanctum } from "react-sanctum";
import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import Layout from "../components/Layout/Layout";
import { ToastContainer } from "react-toastify";

const PrivateRoute = () => {
  const { authenticated } = useSanctum()

  if (authenticated != null) {
    return authenticated
      ? <Layout>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss={true}
          draggable={false}
          pauseOnHover={true}
          theme={"light"}
        />
        <Outlet/>
      </Layout>
      : <Navigate to={{ pathname: "/login", state: { from: location } }} replace/>;
  }
  return <></>
}

export default PrivateRoute;
