import React, {useEffect, useState} from "react";
import {Card, CardBody} from "reactstrap";
import {useRequest} from "ahooks";
import toolService from "../../../../services/toolService";
import tableOptions from "../../../../constants/tableOptions";
import CreateButton from "../../../../components/Custom/Form/Buttons/CreateButton";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import ToolOffCanvas from "./ToolOffCanvas";
import WorkTypeList from "../../../../components/Custom/UI/WorkType/WorkTypeList";

const Tools = () => {

    const {data, loading, run} = useRequest(toolService.fetchAll,{manual: true})

    const [isCanvasOpen, setIsCanvasOpen] = useState(false)
    const [currentSelected, setCurrentSelected] = useState(undefined);

    useEffect(() => {
        if(currentSelected) {
            setIsCanvasOpen(true)
        }
    }, [currentSelected])

    useEffect(() => {
        if(!isCanvasOpen) {
            setCurrentSelected(undefined)
        }
    }, [isCanvasOpen])

    return <Card>
        <CardBody>
            <PaginatedTable
                options={tableOptions}
                TableActions={
                    <CreateButton handleClick={() => setIsCanvasOpen(true)}>Nuovo Strumento</CreateButton>
                }
                columns={[
                    {
                        id: 'name',
                        Header: 'Strumento',
                        accessor: 'name',
                        Cell: ({row, value}) => <span className='fw-bold link-primary' style={{cursor: 'pointer'}} onClick={() => setCurrentSelected(row.original)}>{value}</span>
                    }, {
                        Header: 'Descrizione',
                        disableSortBy: true,
                        accessor: 'description',
                    }, {
                        Header: 'Lavorazioni',
                        disableSortBy: true,
                        accessor: 'work_types',
                        Cell: ({value}) => <WorkTypeList workTypes={value}/>
                    }
                ]}
                data={data ?? []}
                isLoading={loading}
                filterable
                sortable
                onParamsChange={run}
            />


            <ToolOffCanvas
                toggle={setIsCanvasOpen}
                isOpen={isCanvasOpen}
                data={currentSelected}
                handleResponse={(res) => {
                    setCurrentSelected(undefined)
                    run()
                }}
            />
        </CardBody>
    </Card>

}

export default Tools
