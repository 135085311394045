import React from 'react';
import LotWorkRecapTable from '../../LotWork/components/LotWorkRecapTable';
import { useNavigate } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import LotWorkHeader from '../../LotWork/components/LotWorkHeader';
import LotWorkState from '../../../../../../components/Custom/UI/States/LotWorkState';

const LotWorkDetail = ({ lotWork }) => {

  const navigate = useNavigate()

  return (
    <>
      <UncontrolledTooltip target={`lot_print_btn_${lotWork.uuid}`}>Dettaglio attività</UncontrolledTooltip>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <h3 className='mb-0'>
          {lotWork.type.label}&nbsp;<LotWorkState isCompleted={lotWork?.is_completed}/>
        </h3>
        <button
          id={`lot_print_btn_${lotWork.uuid}`}
          className='btn btn-primary btn-icon'
          type='button'
          onClick={() => navigate(lotWork.uuid)}
        >
          <i className='fa fa-list'/>
        </button>
      </div>
      <LotWorkHeader lotWork={lotWork}/>
      <LotWorkRecapTable lotWork={lotWork}/>
    </>
  )

}

export default LotWorkDetail
