import React from "react";
import FormGroup from "../../../../../../../components/Custom/Form/FormGroup";
import { InputField } from "../../../../../../../components/Custom/Form/InputField";

const ActivityBottomForm = () => {

  return (
    <>
      <div className='row'>
        <div className='col-lg-3 col-md-6'>
          <FormGroup name="stop_hour_amount" label="Ore fermo" required={true}
                     inline={false}>
            <InputField name="stop_hour_amount" type="time"/>
          </FormGroup>
        </div>
        <div className='col-lg-9 col-md-6'>
          <FormGroup name="stop_cause" label="Motivazione" required={false}
                     inline={false}>
            <InputField name="stop_cause"/>
          </FormGroup>
        </div>
      </div>
      <FormGroup label="Note" name="note" required={false} inline={false}>
        <InputField name="note" component="textarea" rows={3}/>
      </FormGroup>
    </>
  );

}

export default ActivityBottomForm
