import {useSanctum} from "react-sanctum";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import React from "react";
import NonAuthLayout from "../components/Layout/NonAuthLayout";

const GuestRoute = ({children, ...rest}) => {
    const location = useLocation();
    const {authenticated} = useSanctum();
    if (authenticated != null) {
        return authenticated
            ? <Navigate to={{pathname: "/", state: {from: location}}} replace/>
            : <NonAuthLayout>
                <Outlet/>
            </NonAuthLayout>;
    }
    return <></>
}
export default GuestRoute;
