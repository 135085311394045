import { FormDisplay } from "../../../../../../components/Custom/Form/Display/FormDisplay";
import DateFormatDisplay from "../../../../../../components/Custom/Form/Display/DateFormatDisplay";
import React, { useContext } from "react";
import { useSanctum } from 'react-sanctum';
import { LotContext } from '../../../LotWrapper';

const LotDetails = () => {

  const { user } = useSanctum()
  const { lot } = useContext(LotContext);

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h3>Scheda di produzione</h3>
          <div className="row">
            <div className="col-md-4">
              <FormDisplay label="Lotto di produzione">
                <h3>{lot?.lot_number}</h3>
              </FormDisplay>
            </div>
            <div className="col-md-4">
              <FormDisplay label="Data" inline={false}>
                <DateFormatDisplay value={lot?.start_date}/>
              </FormDisplay>
            </div>
            <div className="col-md-4">
              <FormDisplay label="Data consegna" inline={false}>
                <DateFormatDisplay value={lot?.forecast_date}/>
              </FormDisplay></div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <FormDisplay label="Quantità richiesta" inline={false}>
                <h5>{lot?.total_qty}</h5>
              </FormDisplay>
            </div>
            <div className="col-md-4">
              <FormDisplay label="Dei quali lato DX" inline={false}>
                <h5>{lot?.dx_qty}</h5>
              </FormDisplay>
            </div>
            <div className="col-md-4">
              <FormDisplay label="Dei quali lato SX" inline={false}>
                <h5>{lot?.sx_qty}</h5>
              </FormDisplay>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <FormDisplay label="Cliente" inline={false}>
                {lot?.lot_data?.customer?.business_name}
              </FormDisplay>
            </div>
            <div className="col-md-4">
              <FormDisplay label="Deroga numero" inline={false}>
                {lot?.derogation}
              </FormDisplay>
            </div>
            <div className="col-md-4">
              <FormDisplay inline={false} label='Documento emesso'>
                {lot?.lot_data?.user?.full_name}
              </FormDisplay>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <FormDisplay label="Urgente" inline={false}>
                {lot?.is_urgent ? "SI" : "NO"}
              </FormDisplay>
            </div>
            <div className="col-md-8">
              <FormDisplay label="Note lotto" inline={false}>
                {lot?.note}
              </FormDisplay>
            </div>
          </div>
        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <h3>Descrizione codice</h3>

          <div className="row">
            <div className='col-md-4'>
              <FormDisplay label="Codice">{lot?.lot_data?.item?.code}</FormDisplay>
            </div>
            <div className='col-md-4'>
              <FormDisplay
                label="Casa automobilistica">{lot?.lot_data?.manufacturer?.name}</FormDisplay>
            </div>
            <div className='col-md-4'>
              <FormDisplay label="Modello vettura">{lot?.lot_data?.vehicle?.name}</FormDisplay>
            </div>
          </div>

          <FormDisplay label="Descrizione">{lot?.lot_data?.item?.description}</FormDisplay>
        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <h3>Caratteristiche</h3>
          <div className="row">
            <div className='col-md-4'>
              <FormDisplay label="Marchio">{lot?.lot_data?.brand?.name}</FormDisplay>
            </div>
            <div className='col-md-4'>
              <FormDisplay label="Colore">{lot?.lot_data?.color?.name}</FormDisplay>
            </div>
            <div className='col-md-4'>
              <FormDisplay label="Curvatura">{lot?.lot_data?.curvature?.name}</FormDisplay>
            </div>
            <div className='col-md-4'>
              <FormDisplay label="Spessore">{lot?.lot_data?.thickness?.value}</FormDisplay>
            </div>
          </div>
          <FormDisplay label="Note articolo">{lot?.lot_data?.item?.note}</FormDisplay>
        </div>
      </div>
    </>
  )

}

export default LotDetails
