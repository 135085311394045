import api from '../helpers/axios';

const fetchAll = (toolConfigUuid, params) => api.get(`tool-configs/${toolConfigUuid}/tool-jobs`, { params }).then(res => res.data);
const fetchLot = (toolConfigUuid, lotUuid) => api.get(`tool-configs/${toolConfigUuid}/lots/${lotUuid}`).then(res => res.data);

const toolJobService = {
  fetchAll,
  fetchLot
};

export default toolJobService
