import React from "react"
import { createRoot } from 'react-dom/client';
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { Sanctum } from "react-sanctum";
import api from "./helpers/axios";


const sanctumConfig = {
  axiosInstance: api,
  apiUrl: process.env.REACT_APP_API_URL,
  csrfCookieRoute: "csrf-cookie",
  signInRoute: "login",
  signOutRoute: "logout",
  userObjectRoute: "user",
  usernameKey: "username",
};


const root = createRoot(document.getElementById("root"));

root.render(
  <Sanctum config={sanctumConfig}>
      <App/>
  </Sanctum>
)

// ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
