import React, { useContext } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import LotWorkRecapTable from './components/LotWorkRecapTable';
import { Spinner, UncontrolledTooltip } from 'reactstrap';
import LotWorkHeader from './components/LotWorkHeader';
import { LotContext } from '../../LotWrapper';
import { find } from 'lodash';
import LotWorkState from '../../../../../components/Custom/UI/States/LotWorkState';

const LotWork = () => {

  const { lot_work_id } = useParams()
  const { lot } = useContext(LotContext)
  const lotWork = find(lot.lot_works, lw => lw.uuid === lot_work_id);


  return lotWork ? (
    <>
      <div className='card'>
        <div className='card-body'>
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <div className='card-title fs-3'>{lotWork.type.label}&nbsp;<LotWorkState isCompleted={lotWork?.is_completed}/></div>
            <div className='d-flex align-items-center justify-content-end gap-1'>
              <UncontrolledTooltip target={`activity_add_btn_${lotWork.uuid}`}>Aggiungi nuova attività</UncontrolledTooltip>
              <Link
                id={`activity_add_btn_${lotWork.uuid}`}
                className='btn btn-primary btn-icon d-print-none'
                to={'create'}>
                <i className='fa fa-plus'/>
              </Link>
            </div>
          </div>
          <LotWorkHeader lotWork={lotWork}/>
          <LotWorkRecapTable lotWork={lotWork}/>
        </div>
      </div>

      <div className='card'>
        <Outlet/>
      </div>
    </>
  ) : <Spinner/>

}
export default LotWork
