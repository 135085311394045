import React from 'react';
import { useFormikContext } from 'formik';
import { Spinner } from 'reactstrap';

const SearchButton = () => {

  const { isSubmitting, isValid } = useFormikContext()

  return (
    <button
      type='submit'
      className='btn btn-light'
      disabled={isSubmitting || !isValid}
    >
      {isSubmitting
        ? <><Spinner size='sm'/>&nbsp;In Corso</>
        : <><i className='fa fa-search'/>&nbsp;Cerca</>}
    </button>
  )

}

export default SearchButton
