import React from 'react'
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {InputField} from "../../../components/Custom/Form/InputField";
import FormGroup from "../../../components/Custom/Form/FormGroup";
import GenericOffCanvas from "../../../components/Custom/UI/Canvas/GenericOffCanvas";
import GenericCanvasActions from "../../../components/Custom/UI/Canvas/GenericCanvasActions";
import {toast} from "react-toastify";
import userService from '../../../services/userService';

const UserPasswordOffCanvas = ({isOpen, toggle, data, handleResponse, onDelete}) => {

    const validation = Yup.object().shape({
        password: Yup.string().required("Password is required"),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], "Le password non coincidono")
    })

    const initialValues = {
        password: "",
        password_confirmation: ""
    }

    const handleSubmit = (values) => {
        if (data) {
            userService.updatePassword(data.uuid, values).then(res => {
                handleResponse(res)
                toggle(false)
            }).catch(err => toast.error(err.response.data.message))
        }
    }

    return (
        <GenericOffCanvas
            title='Aggiorna password'
            isOpen={isOpen}
            toggle={toggle}>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                    setSubmitting(false)
                }}>
                {({values}) => (
                    <Form>
                        <div className="alert alert-info">
                            <span className="fw-bold">Attenzione!</span><br/>
                            <span>La password non sarà più visibile, quindi copiala</span>
                            <p className="fw-bold fs-5">{values.password || 'nessuna password inserita'}</p>
                        </div>
                        <FormGroup label="Password" name="password" required={false} inline={false}>
                            <InputField name="password" type="password"/>
                        </FormGroup>
                        <FormGroup label="Conferma Password" name="password_confirmation"
                                   required={false} inline={false}>
                            <InputField name="password_confirmation" type="password"/>
                        </FormGroup>
                        <GenericCanvasActions toggle={toggle}/>
                    </Form>
                )}
            </Formik>
        </GenericOffCanvas>
    )

}

export default UserPasswordOffCanvas
