import api from '../helpers/axios';

const csrf = () => api.get('/csrf-cookie')
const forgotPassword = async (email) => {
  await csrf();
  return api.post('/forgot-password', { email })
}
const resetPassword = async (email, password, password_confirmation, token) => {
  await csrf();
  return api.post('/reset-password', {
    email,
    password,
    password_confirmation,
    token
  })
}

const authService = { resetPassword, forgotPassword }
export default authService;
