import React from 'react'
import FormGroup from "../../../../../../../../../components/Custom/Form/FormGroup";
import { InputField } from "../../../../../../../../../components/Custom/Form/InputField";
import SerigrafiaLunottiReportsTable from '../SerigrafiaLunottiReportsTable';
import SelectAllCheckboxesButton from '../../../components/SelectAllCheckboxesButton';
import ReportCheckboxFormRows from '../../../components/ReportCheckboxFormRows';
import ActivityCopyExtraDataButton from '../../../components/ActivityCopyExtraDataButton';
import { useFormikContext } from 'formik';

const SerigrafiaLunottiFaseDueForm = ({ workConfig }) => {

  const { setValues } = useFormikContext()
  const handleUpdateValues = v => setValues(values => ({
    ...values,
    activity_data: {
      ...values.activity_data,
      silver_paste_supplier: v.silver_paste_supplier ?? '',
      silver_paste_lot: v.silver_paste_lot ?? '',
      silver_paste_qty: v.silver_paste_qty ?? ''
    }
  }))

  return (
    <>
      <div className='row'>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Fornitore pasta argento"
                     name="activity_data.silver_paste_supplier"
                     required={true}
                     inline={false}>
            <InputField name="activity_data.silver_paste_supplier"/>
          </FormGroup>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Lotto pasta argento n°"
                     name="activity_data.silver_paste_lot"
                     required={true}
                     inline={false}>
            <InputField name="activity_data.silver_paste_lot"/>
          </FormGroup>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Quantità pasta argento (grammi)"
                     name="activity_data.silver_paste_qty"
                     required={true}
                     inline={false}>
            <InputField
              type="number"
              step={0.01}
              name="activity_data.silver_paste_qty"
            />
          </FormGroup>
        </div>
        <div className='col-lg-12 col-md-6 d-flex align-items-end pb-3 justify-content-end'>
          <ActivityCopyExtraDataButton handleUpdateData={handleUpdateValues}/>
        </div>
      </div>

      <SerigrafiaLunottiReportsTable action={<SelectAllCheckboxesButton workConfig={workConfig}/>}>
        <ReportCheckboxFormRows workConfig={workConfig}/>
      </SerigrafiaLunottiReportsTable>

    </>
  )
}

export default SerigrafiaLunottiFaseDueForm
