import React from "react";
import { Outlet } from "react-router-dom";
import CheckUserModule from '../../../../components/Custom/Permissions/CheckUserModule';


const UsersWrapper = () => {


  return (<>
    <CheckUserModule module={['can_manage_administration_module']}>
      <Outlet/>
    </CheckUserModule>
  </>)

}

export default UsersWrapper;

