import React from "react";
import SystemTables from "../../pages/Production/SystemTables/SystemTables";
import { Navigate } from "react-router-dom";
import Tools from "../../pages/Production/SystemTables/Tools/Tools";
import Templates from "../../pages/Production/SystemTables/Templates/Templates";
import Programs from "../../pages/Production/SystemTables/Programs/Programs";
import Customers from "../../pages/Production/SystemTables/Customers/Customers";

const systemTableRoutes = {
  path: '/system-tables',
  element: <SystemTables/>,
  children: [
    {
      path: 'templates',
      breadcrumb: "Dime e telai",
      element: <Templates/>
    },
    {
      path: 'programs',
      breadcrumb: "Programmi",
      element: <Programs/>
    },
    {
      path: 'tools',
      breadcrumb: "Strumenti",
      element: <Tools/>
    },
    {
      path: 'customers',
      breadcrumb: "Clienti",
      element: <Customers/>
    },
    {
      breadcrumb: "Tabelle di sistema",
      path: '',
      element: <Navigate to={'templates'}/>
    }
  ]
}

export default systemTableRoutes
