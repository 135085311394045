import React, { useMemo } from "react";
import { lotShipmentFormConfig } from "./lotShipmentForm.config";
import lotShipmentService from "../../../../../../services/lotShipmentService";
import { toast } from "react-toastify";
import GenericOffCanvas from "../../../../../../components/Custom/UI/Canvas/GenericOffCanvas";
import { Form, FormikProvider, useFormik } from "formik";
import GenericCanvasActions from "../../../../../../components/Custom/UI/Canvas/GenericCanvasActions";
import DeleteButton from "../../../../../../components/Custom/Form/Buttons/DeleteButton";
import { InputField } from "../../../../../../components/Custom/Form/InputField";
import FormGroup from "../../../../../../components/Custom/Form/FormGroup";

const LotShipmentOffCanvas = ({ isOpen, toggle, data, handleResponse, lot, shipmentStatus }) => {

  const initialValues = useMemo(() => lotShipmentFormConfig.setInitialValues(data), [data])
  const validationSchema = useMemo(() => lotShipmentFormConfig.setValidation(shipmentStatus), [shipmentStatus]);
  const handleSubmit = (values) => data
    ? lotShipmentService.update(lot.uuid, data.uuid, values)
    : lotShipmentService.store(lot.uuid, values);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => handleSubmit(values)
    .then(res => {
      toast.success('Operazione eseguita con successo')
      handleResponse(res)
      toggle(false)
    })
    .catch(err => toast.error(err.response.data.message))
    .finally(() => setSubmitting(false))
  })


  const handleDelete = () => lotShipmentService.destroy(lot.uuid, data.uuid)
  .then(res => {
    toast.success('Operazione eseguita con successo')
    handleResponse(undefined)
    toggle(false)
  })
  .catch(err => toast.error(err.response.data.message))


  return <GenericOffCanvas
    title={data ? 'Modifica ' + data.name : 'Spedizione'}
    isOpen={isOpen}
    toggle={toggle}>
    <FormikProvider value={formik}>
      <Form>
        <FormGroup name="qty" label="Piani" required={true}>
          <InputField name="qty" type="number" min={0}/>
        </FormGroup>
        <FormGroup name="dx_qty" label="Destri" required={true}>
          <InputField name="dx_qty" type="number" min={0}/>
        </FormGroup>
        <FormGroup name="sx_qty" label="Sinistri" required={true}>
          <InputField name="sx_qty" type="number" min={0}/>
        </FormGroup>
        <FormGroup name="shipment_date" label="Data" required={true}>
          <InputField name="shipment_date" type="date"/>
        </FormGroup>
        <GenericCanvasActions toggle={toggle}>
          {data?.uuid && <DeleteButton handleClick={() => handleDelete()}/>}
        </GenericCanvasActions>
      </Form>
    </FormikProvider>
  </GenericOffCanvas>

}

export default LotShipmentOffCanvas
