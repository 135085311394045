import React, { useContext, useMemo } from "react";
import { ApplicationContext } from "../../../../../components/Layout/Layout";
import { useRequest } from "ahooks";
import templateService from "../../../../../services/templateService";
import programService from "../../../../../services/programService";
import itemWorkService from "../../../../../services/itemWorkService";
import { useParams } from "react-router-dom";
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import itemWorkFormConfigs from './form.config';
import SubmitButton from '../../../../../components/Custom/Form/Buttons/SubmitButton';
import { toast } from 'react-toastify';
import ItemWorkForm from './ItemWorkForm';
import { mutate } from 'swr';

const ItemWorks = () => {

  const { lot_work_configs } = useContext(ApplicationContext)
  const { id } = useParams();

  const { data: itemWorks, mutate } = useRequest(itemWorkService.fetchAll, { defaultParams: [id] })
  const { data: templates } = useRequest(templateService.selectable)
  const { data: programs } = useRequest(programService.selectable)

  const initialValues = useMemo(() => itemWorkFormConfigs.setInitialValues(lot_work_configs, itemWorks), [itemWorks]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: itemWorkFormConfigs.validation,
    onSubmit: (values, { setSubmitting }) => {
      itemWorkService.store(id, values)
      .then(res => {
        toast.success('Lavorazioni aggiornate con successo')
        mutate(res)
      })
      .catch(err => toast.error(err.response.data.message))
      .finally(() => setSubmitting(false))
    }
  })

  return (
    <FormikProvider value={formik}>
      <h3>Lavorazioni</h3>
      <Form>

        {formik.dirty &&
          <div className='alert alert-warning d-flex align-items-center justify-content-between gap-4'>
            <div>
              <div className='fw-bold text-danger'><i className='fa fa-exclamation-triangle' />&nbsp;Attenzione!</div>
              Una o più lavorazioni sono state modificate, per rendere effettivi i cambiamenti clicca su salva!
            </div>
            <button type='submit' className='btn btn-danger btn-sm' disabled={formik.isSubmitting || !formik.isValid}>
              <i className='fa fa-save'/>&nbsp;Salva
            </button>
          </div>
        }

        <FieldArray
          name='item_works'
          render={() => (
            <div className='row'>
              {formik.values.item_works.map((itemWork, index) => (
                <div key={itemWork.work_type} className='col-lg-6 d-flex align-items-stretch'>
                  <ItemWorkForm
                    index={index}
                    work={itemWork}
                    templates={templates}
                    programs={programs}
                  />
                </div>
              ))}
            </div>
          )}
        />
        <div className='d-flex align-items-center justify-content-end'>
          <SubmitButton/>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default ItemWorks
