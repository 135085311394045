import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import ProvaDiTipoForm from './ProvaDiTipoForm';
import ProvaDiFrammentazione from '../ProvaDiFrammentazione';

const ProvaDiFrammentazioneForm = () => {

  const { values } = useFormikContext()

  const getColor = letter => {
    if (letter === 'a' || letter === 'A')
      return 'info'
    else if (letter === 'b' || letter === 'B')
      return 'danger'
    else
      return 'light'
  }

  return (
    <ProvaDiFrammentazione>
      <FieldArray
        name='prova_frammentazione'
        render={() => (
          <>
            {values.activity_data?.prova_frammentazione?.map((pf, index) => (
              <ProvaDiTipoForm
                index={index}
                key={pf.letter}
                letter={pf.letter}
                color={getColor(pf.letter)}
              />
            ))}
          </>
        )}
      />
    </ProvaDiFrammentazione>
  )

}

export default ProvaDiFrammentazioneForm
