import React, {useEffect, useState} from 'react'
import {LotStatesEnum} from "../../../../constants/enums/LosStatesEnum";
import DateFormatDisplay from "../../../../components/Custom/Form/Display/DateFormatDisplay";
import {isBefore, parseISO} from "date-fns";

const LotExpiration = ({value, lotState}) => {


    const [isBeforeDate, setIsBeforeDate] = useState(false)

    useEffect(() => {
        setIsBeforeDate(getExpiration());
    }, [value, lotState]);


    const getExpiration = () => {
        if (lotState !== LotStatesEnum.closed) {
            return isBefore(parseISO(value), new Date());
        }
        return false;
    }


    return <span className='d-flex align-items-center justify-content-start flex-row text-nowrap'>
        {isBeforeDate
            ? <i className="fas fa-exclamation-triangle text-danger me-1 align-self-center" />
            : <i className="fas fa-check-circle text-success me-1 align-self-center" />
        }
        <DateFormatDisplay value={value} formatString='dd/MM/yyyy'/>
    </span>

}

export default LotExpiration
