import React from "react";
import {NumberDisplay} from "../../../../components/Custom/Form/Display/NumberDisplay";
import {Card, CardBody, Table} from "reactstrap";
import ArchivedLotEntry from "./ArchivedLotEntry";

const ArchivedLotEntries = ({entries = [], title}) => {


    return (
        <Card>
            <CardBody><h5>{title}</h5></CardBody>
            <Table size='xs' responsive={true} striped={true}>
                <thead>
                <tr>
                    <th>Data</th>
                    <th>Piani</th>
                    <th>DX</th>
                    <th>SX</th>
                </tr>
                </thead>
                <tbody>
                {(entries || []).length > 0
                    ? entries.map((entry, index) => <ArchivedLotEntry key={index} entry={entry}/>)
                    : <tr>
                        <td colSpan="4" className="text-center">Nessun risultato presente.</td>
                    </tr>}
                </tbody>
                <tfoot>
                <tr>
                    <td>Totale</td>
                    <td>
                        <NumberDisplay
                            value={_.sumBy((entries || []), 'qty')}
                            suffix={'pz'}
                        />
                    </td>
                    <td>
                        <NumberDisplay
                            value={_.sumBy((entries || []), 'dx_qty')}
                            suffix={'pz'}
                        />
                    </td>
                    <td>
                        <NumberDisplay
                            value={_.sumBy((entries || []), 'sx_qty')}
                            suffix={'pz'}
                        />
                    </td>
                </tr>
                </tfoot>
            </Table>
        </Card>
    )

}

export default ArchivedLotEntries
