import React from 'react';
import Chart from 'react-apexcharts'

const MixedStackedBarChart = ({ categories = [], series = [], name }) => {

  const options = {
    fill: {
      colors: ['#34aadc', '#F44336'],
    },
    chart: {
      height: 400,
      redrawOnParentResize: true,
      toolbar: { show: true }
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.seriesIndex === 1
          ? val + "%"
          : val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#111"]
      }
    },
    stroke: {
      width: [0, 0, 4]
    },
    title: {
      text: name
    },
    xaxis: {
      categories: categories,
      type: "category"
    },
    yaxis: [
      {
        title: {
          text: "NR pezzi"
        }
      },
      {
        max: 15,
        min: 0,
        opposite: true,
        title: {
          text: "% di scarto"
        }
      }
    ]
  };


  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width="100%"
      height="600px"/>
  )

}

export default MixedStackedBarChart
