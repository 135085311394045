import React from 'react'
import ReportCheckboxFormRows from '../../components/ReportCheckboxShowRows';
import ForaturaELavaggioReportsTable from './ForaturaELavaggioReportsTable';

const ForaturaELavaggioShow = ({ activity, workConfig }) => {


  return (
    <>
      <ForaturaELavaggioReportsTable>
        <ReportCheckboxFormRows activity={activity} workConfig={workConfig}/>
      </ForaturaELavaggioReportsTable>

    </>
  )
}

export default ForaturaELavaggioShow
