import React, {useEffect, useState} from "react";
import {Card, CardBody} from "reactstrap";
import {useRequest} from "ahooks";
import tableOptions from "../../../../constants/tableOptions";
import CreateButton from "../../../../components/Custom/Form/Buttons/CreateButton";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import WorkTypeList from "../../../../components/Custom/UI/WorkType/WorkTypeList";
import templateService from "../../../../services/templateService";
import TemplateOffCanvas from "./TemplateOffCanvas";
import DisplayBooleanOrUndefined from "../../../../components/Custom/Form/Display/DisplayBooleanOrUndefined";

const Templates = () => {

    const {data, loading, run} = useRequest(templateService.fetchAll, {manual: true})

    const [isCanvasOpen, setIsCanvasOpen] = useState(false)
    const [currentSelected, setCurrentSelected] = useState(undefined);

    useEffect(() => {
        if (currentSelected) {
            setIsCanvasOpen(true)
        }
    }, [currentSelected])

    useEffect(() => {
        if (!isCanvasOpen) {
            setCurrentSelected(undefined)
        }
    }, [isCanvasOpen])

    return <Card>
        <CardBody>
            <PaginatedTable
                options={tableOptions}
                TableActions={
                    <CreateButton handleClick={() => setIsCanvasOpen(true)}>Nuova dima/telaio</CreateButton>
                }
                columns={[
                    {
                        id: 'name',
                        Header: 'Dima/Telaio',
                        accessor: 'name',
                        Cell: ({row, value}) => <span className='fw-bold link-primary' style={{cursor: 'pointer'}} onClick={() => setCurrentSelected(row.original)}>{value}</span>
                    }, {
                        Header: 'Descrizione',
                        disableSortBy: true,
                        accessor: 'description',
                    }, {
                        Header: 'Lavorazioni',
                        disableSortBy: true,
                        accessor: 'work_types',
                        Cell: ({value}) => <WorkTypeList workTypes={value}/>
                    }, {
                        Header: 'Articolo',
                        disableSortBy: true,
                        accessor: 'items_works_count',
                        Cell: ({value}) => <DisplayBooleanOrUndefined value={value > 0}/>
                    }
                ]}
                data={data ?? []}
                isLoading={loading}
                filterable
                sortable
                onParamsChange={run}
            />

            <TemplateOffCanvas
                toggle={setIsCanvasOpen}
                isOpen={isCanvasOpen}
                data={currentSelected}
                handleResponse={(res) => {
                    setCurrentSelected(undefined)
                    run()
                }}
            />

        </CardBody>
    </Card>

}

export default Templates
