import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./assets/scss/theme.scss";
import "./assets/scss/custom/sekurglass.scss";
import 'react-circular-progressbar/dist/styles.css';
import 'react-tooltip/dist/react-tooltip.css'

import appRoutes from "./routes";

const router = createBrowserRouter(appRoutes);


const App = () => <RouterProvider router={router}/>;
export default App
