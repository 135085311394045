import React from 'react'
import { Outlet } from "react-router-dom";
import CheckUserModule from '../../../components/Custom/Permissions/CheckUserModule';

const LotsWrapper = () => {

  return (
    <CheckUserModule module={['can_manage_production_module', 'can_manage_data_entry_module']}>
      <Outlet/>
    </CheckUserModule>
  )

}

export default LotsWrapper
