import React from 'react'
import * as Yup from "yup";
import { isUndefined } from "lodash";
import { Form, Formik } from "formik";
import { InputField } from "../../../components/Custom/Form/InputField";
import FormGroup from "../../../components/Custom/Form/FormGroup";
import userService from "../../../services/userService";
import GenericOffCanvas from "../../../components/Custom/UI/Canvas/GenericOffCanvas";
import GenericCanvasActions from "../../../components/Custom/UI/Canvas/GenericCanvasActions";
import DeleteButton from "../../../components/Custom/Form/Buttons/DeleteButton";
import { FormCheckbox } from '../../../components/Custom/Form/FormCheckbox';
import { toast } from 'react-toastify';

const UserOffCanvas = ({ isOpen, toggle, data, handleResponse, onDelete }) => {


  const validation = Yup.object().shape({
    first_name: Yup.string().max(50, "Max 50 caratteri").required("Obbligatorio"),
    last_name: Yup.string().max(50, "Max 50 caratteri").required("Obbligatorio"),
    username: Yup.string().max(50, "Max 50 caratteri").required("Obbligatorio"),
    can_manage_data_entry_module: Yup.boolean().nullable().notRequired(),
    can_manage_industry_module: Yup.boolean().nullable().notRequired(),
    can_manage_administration_module: Yup.boolean().nullable().notRequired(),
    can_manage_production_module: Yup.boolean().nullable().notRequired(),
    email: Yup.string().email().notRequired()
  })

  const initialValues = {
    first_name: isUndefined(data) ? "" : data.first_name || "",
    last_name: isUndefined(data) ? "" : data.last_name || "",
    username: isUndefined(data) ? "" : data?.username || "",
    can_manage_data_entry_module: isUndefined(data) ? false : data?.can_manage_data_entry_module,
    can_manage_industry_module: isUndefined(data) ? false : data?.can_manage_industry_module,
    can_manage_administration_module: isUndefined(data) ? false : data?.can_manage_administration_module,
    can_manage_production_module: isUndefined(data) ? false : data?.can_manage_production_module,
    email: isUndefined(data) ? "" : data?.email || ""
  }

  const handleSubmit = (values) => {
    !isUndefined(data)
      ? userService.update(data.uuid, values)
      .then(res => {
        handleResponse(res)
        toggle(false)
      })
      .catch(err => toast.error(err.response.data.message))
      .finally(() => toggle(false))
      : userService.store(values)
      .then(res => {
        handleResponse(res)
        toggle(false)
      })
      .catch(err => toast.error(err.response.data.message))
  }

  const handleDelete = () => {
    userService.destroy(data.uuid)
    .then(res => {
      onDelete(res)
      toggle(false)
    })
    .catch(err => toast.error(err.response.data.message))
  }

  return (
    <GenericOffCanvas
      title={data ? 'Modifica ' + data.full_name : 'Crea Utente'}
      isOpen={isOpen}
      toggle={toggle}>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values)
          setSubmitting(false)
        }}>
        {({ values }) => (
          <Form>
            <FormGroup name="first_name" label="Nome" required={true} inline={false}>
              <InputField name="first_name" type="text" maxLength={100}/>
            </FormGroup>
            <FormGroup name="last_name" label="Cognome" required={true} inline={false}>
              <InputField name="last_name" type="text" maxLength={100}/>
            </FormGroup>
            <FormGroup name="username" label="Nome utente" required={true} inline={false}>
              <InputField name="username" type="text" maxLength={100}/>
            </FormGroup>
            <FormGroup name="email" label="Email" required={false} inline={false}>
              <InputField name="email" type="email" maxLength={100}/>
            </FormGroup>
            {
              isUndefined(data) && (
                <>
                  <div className="alert alert-info mt-3">
                    <span className="fw-bold">Attenzione!</span><br/>
                    <span>La password non sarà più visibile, quindi copiala</span>
                    <p className="fw-bold fs-5">{values.password || 'nessuna password inserita'}</p>
                  </div>
                  <FormGroup label="Password" name="password" required={false} inline={false}>
                    <InputField name="password" type="password"/>
                  </FormGroup>
                  <FormGroup label="Conferma Password" name="password_confirmation"
                             required={false} inline={false}>
                    <InputField name="password_confirmation" type="password"/>
                  </FormGroup>
                </>
              )
            }
            <FormCheckbox
              name="can_manage_production_module"
              label="Accesso area Produzione"
            />
            <FormCheckbox
              name="can_manage_administration_module"
              label="Accesso area Amministrazione"
            />
            <FormCheckbox
              name="can_manage_industry_module"
              label="Accesso area Industria 4.0"
            />
            <FormCheckbox
              name="can_manage_data_entry_module"
              label="Accesso area tempi"
            />

            <GenericCanvasActions toggle={toggle}>
              <DeleteButton handleClick={handleDelete}/>
            </GenericCanvasActions>
          </Form>
        )}
      </Formik>
    </GenericOffCanvas>
  )

}

export default UserOffCanvas
