import React from 'react';
import { useRequest } from 'ahooks';
import toolConfigService from '../../../../../services/toolConfigService';
import FormGroup from '../../../../../components/Custom/Form/FormGroup';
import { FormSelect } from '../../../../../components/Custom/Form/FormSelect';
import { useFormikContext } from 'formik';

export const FileListReader = ({ toolUuid = "" }) => {

  const { values, setFieldValue } = useFormikContext()

  const { data = [], loading = false, run: getFilesList } = useRequest(toolConfigService.getFilesList, {
    defaultParams: [toolUuid],
    ready: toolUuid !== undefined
  })

  return (
    <FormGroup label="Nome del file"
               name="file_name"
               required={true}
               inline={false}>
      <div className='d-flex flex-grow-1 justify-content-start align-items-center gap-2'>
        <div className='w-100'>
          <FormSelect
            name='file_name'
            loading={loading}
            placeholder="Seleziona un file"
            value={values.file_name}
            onChange={f => setFieldValue('file_name', f?.value ?? "")}
            options={data}
            disabled={loading}
            multiple={false}
          />
        </div>
        <button
          disabled={loading}
          className='btn btn-primary'
          type='button'
          onClick={() => getFilesList(toolUuid)}>
          {loading ? 'Attendi' : 'Aggiorna'}
        </button>
      </div>
    </FormGroup>
  )

}
