import React, { useContext } from 'react';
import { ApplicationContext } from '../../../../../../components/Layout/Layout';
import { FormDisplay } from '../../../../../../components/Custom/Form/Display/FormDisplay';
import { find, get } from 'lodash';

const LotWorkHeader = ({ lotWork }) => {

  const { lot_work_configs } = useContext(ApplicationContext)
  const lotWorkConfig = lot_work_configs.find(lwc => lwc.value === lotWork.type.value)

  return lotWorkConfig !== undefined && (
    <div className='row'>
      {lotWorkConfig.has_template &&
        <div className='col-lg-3 col-6'>
          <FormDisplay label='Dima/telaio'>
            <div className='d-flex flex-column gap-1'>
              <span>{lotWork?.lot_work_data?.template?.name}</span>
              <div className='d-flex align-items-center justify-content-between text-center'>
                <span><span className='fw-bold'>Posizione:</span><br/> {lotWork?.lot_work_data?.template?.position ?? '---'}</span>
                <span><span className='fw-bold'>Contenitore:</span><br/> {lotWork?.lot_work_data?.template?.container ?? '---'}</span>
                <span><span className='fw-bold'>Campione:</span><br/> {lotWork?.lot_work_data?.template?.sample ?? '---'}</span>
              </div>
            </div>
          </FormDisplay>
        </div>}
      {lotWorkConfig.has_program &&
        <div className='col-lg-3 col-6'>
          <FormDisplay label='Programma'>
            {lotWork?.lot_work_data?.program?.name}
          </FormDisplay>
        </div>}
      {lotWorkConfig.extra_data.map((et, index) =>
        <div className='col-lg-3 col-6' key={index}>
          <FormDisplay label={et.label}>
            {find(get(lotWork, 'lot_work_data.extraData'), extraData => extraData.name === et.name)?.value?.replace('_', ' ').toUpperCase()}
          </FormDisplay>
        </div>
      )}
    </div>
  )

}

export default LotWorkHeader
