import React, { useContext } from 'react';
import { LotContext } from '../../../LotWrapper';
import LotWorkDetail from './LotWorkDetail';

const LotWorksDetail = () => {

  const { lot } = useContext(LotContext)

  return (
    <div className='card'>
      <div className='card-body'>
        <h3>Lavorazioni</h3>
        {lot.lot_works
        .filter(lw => lw.selected)
        .map(lw =>
          <LotWorkDetail
            key={lw.uuid}
            lotWork={lw}
          />
        )}
      </div>
    </div>
  )
}

export default LotWorksDetail
