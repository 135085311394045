import React from 'react';
import CheckUserModule from '../../../components/Custom/Permissions/CheckUserModule';
import { Outlet } from 'react-router-dom';
const IndustryLotsWrapper = () => {

  return (
    <CheckUserModule module={['can_manage_industry_module']}>
      <Outlet />
    </CheckUserModule>
  )

}
export default IndustryLotsWrapper
