import React from "react";
import { FormDisplay } from "../../../../../../../components/Custom/Form/Display/FormDisplay";

const ActivityOperatorsShow = ({ activity }) => {


  let operators = activity?.activity_data?.operator
    ? Array.isArray(activity?.activity_data?.operator)
      ? activity?.activity_data?.operator
      : [activity?.activity_data?.operator]
    : false

  let load_operators = activity?.activity_data?.load_operator
    ? Array.isArray(activity?.activity_data?.load_operator)
      ? activity?.activity_data?.load_operator
      : [activity?.activity_data?.load_operator]
    : false

  let unload_operators = activity?.activity_data?.unload_operator
    ? Array.isArray(activity?.activity_data?.unload_operator)
      ? activity?.activity_data?.unload_operator
      : [activity?.activity_data?.unload_operator]
    : false


  return (
    <div className='row'>
      {operators &&
        <div className='col-lg-4'>
          <FormDisplay label="Operatori">
            <div className="d-flex align-items-center justify-content-start gap-2">
              {operators.map((op, index) =>
                <span className="badge bg-light font-size-12" key={index}>{op.first_name} {op.last_name}</span>
              )}
            </div>
          </FormDisplay>
        </div>
      }
      {load_operators &&
        <div className='col-lg-4'>
          <FormDisplay label="Operatori al carico">
            <div className="d-flex align-items-center justify-content-start gap-2">
              {load_operators.map((op, index) =>
                <span className="badge bg-light font-size-12" key={index}>{op.first_name} {op.last_name}</span>
              )}
            </div>
          </FormDisplay>
        </div>
      }
      {unload_operators &&
        <div className='col-lg-4'>
          <FormDisplay label="Operatori allo scarico">
            <div className="d-flex align-items-center justify-content-start gap-2">
              {unload_operators.map((op, index) =>
                <span className="badge bg-light font-size-12" key={index}>{op.first_name} {op.last_name}</span>
              )}
            </div>
          </FormDisplay>
        </div>
      }
    </div>
  )

}

export default ActivityOperatorsShow
