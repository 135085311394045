import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormDisplay } from '../../../../components/Custom/Form/Display/FormDisplay';

const IndustryLotDetail = ({ lot }) => {

  return (
    <>
      <h3>Scheda di produzione LOTTO {lot?.lot_number}</h3>
      <Row>
        <Col lg={4}>
          <FormDisplay label="Quantità richiesta" inline={false}>
            <h5>{lot?.total_qty}</h5>
          </FormDisplay>
        </Col>
        <Col lg={4}>
          <FormDisplay label="Dei quali lato DX" inline={false}>
            <h5>{lot?.dx_qty}</h5>
          </FormDisplay>
        </Col>
        <Col lg={4}>
          <FormDisplay label="Dei quali lato SX" inline={false}>
            <h5>{lot?.sx_qty}</h5>
          </FormDisplay>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <FormDisplay label="Cliente" inline={false}>
            {lot?.lot_data?.customer?.business_name}
          </FormDisplay>
        </Col>
        <Col lg={4}>
          <FormDisplay label="Deroga numero" inline={false}>
            {lot?.derogation}
          </FormDisplay>
        </Col>
        <Col lg={4}>
          <FormDisplay inline={false} label='Documento emesso'>
            {lot?.lot_data?.user?.full_name}
          </FormDisplay>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <FormDisplay label="Urgente" inline={false}>
            {lot?.is_urgent ? "SI" : "NO"}
          </FormDisplay>
        </Col>
        <Col lg={8}>
          <FormDisplay label="Note lotto" inline={false}>
            {lot?.note}
          </FormDisplay>
        </Col>
      </Row>

      <hr/>
      <h3>Descrizione codice</h3>
      <Row>
        <Col lg={4}>
          <FormDisplay label="Codice">{lot?.lot_data?.item?.code}</FormDisplay>
        </Col>
        <Col lg={4}>
          <FormDisplay
            label="Casa automobilistica">{lot?.lot_data?.manufacturer?.name}</FormDisplay>
        </Col>
        <Col lg={4}>
          <FormDisplay label="Modello vettura">{lot?.lot_data?.vehicle?.name}</FormDisplay>
        </Col>
      </Row>
      <FormDisplay label="Descrizione">{lot?.lot_data?.item?.description}</FormDisplay>
      <hr/>
      <h3>Caratteristiche</h3>
      <Row>
        <Col lg={6}>
          <FormDisplay label="Descrizione">{lot?.lot_data?.brand?.name}</FormDisplay>
        </Col>
        <Col lg={6}>
          <FormDisplay label="Colore">{lot?.lot_data?.color?.name}</FormDisplay>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <FormDisplay label="Curvatura">{lot?.lot_data?.curvature?.name}</FormDisplay>
        </Col>
        <Col lg={6}>
          <FormDisplay label="Spessore">{lot?.lot_data?.thickness?.value}</FormDisplay>
        </Col>
      </Row>

      <FormDisplay label="Note articolo">{lot?.lot_data?.item?.note}</FormDisplay>

      <hr/>

    </>
  )

}

export default IndustryLotDetail
