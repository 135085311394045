import React from 'react'
import {Badge} from "reactstrap";

const WorkTypeBadge = ({workType}) => {

    return <>
        <Badge color={workType?.color || 'primary'} className="mx-1 p-2">
            {workType?.label}
        </Badge>
    </>

}

export default WorkTypeBadge