import React from 'react';
import { InputField } from '../../../../../../../../../../components/Custom/Form/InputField';
import { FormRadio } from '../../../../../../../../../../components/Custom/Form/FormRadio';
import { FieldArray, useFormikContext } from 'formik';

const ProvaDiTipoForm = ({ color, letter, index }) => {

  const { setFieldValue, values } = useFormikContext()

  return (
    <tbody>
    <tr className={`table-${color}`}>
      <td>Classifica di esecuzione della prova</td>
      <td>TIPO {letter}</td>
      <td>Acquisizione digitale</td>
      <td>
        <div className='d-flex align-items-center justify-content-start text-dark gap-2'>
          <FormRadio
            value="1"
            name={`activity_data.prova_frammentazione[${index}].acquisizione_digitale`}
            label='SI'
          />
          <FormRadio
            value="0"
            name={`activity_data.prova_frammentazione[${index}].acquisizione_digitale`}
            label='NO'
          />
          <button
            type='button'
            className='btn btn-sm btn-icon btn-light mb-2'
            onClick={() => setFieldValue(`activity_data.prova_frammentazione[${index}].acquisizione_digitale`, undefined)}
          >
            <i className='fa fa-times'/>
          </button>
        </div>
      </td>
    </tr>

    <FieldArray
      name='prove'
      render={() => (
        <>
          {values.activity_data?.prova_frammentazione[index]?.prove.map((prova, provaIndex) =>
            <tr key={provaIndex}>
              <td>Prova di rottura nel punto</td>
              <td>
                <div className='d-flex align-items-center justify-content-start gap-2'>
                  <span className='text-nowrap'>3 Or{provaIndex > 0 ? 'e' : 'a'}</span>
                  <InputField name={`activity_data.prova_frammentazione[${index}].prove[${provaIndex}].orario`} type="time"/>
                </div>
              </td>
              <td>Esito</td>
              <td>
                <div className='d-flex align-items-center justify-content-start gap-2'>
                  <FormRadio
                    value="1"
                    name={`activity_data.prova_frammentazione[${index}].prove[${provaIndex}].esito`}
                    label='Positivo'
                  />
                  <FormRadio
                    value="0"
                    name={`activity_data.prova_frammentazione[${index}].prove[${provaIndex}].esito`}
                    label='Negativo'
                  />
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-light mb-2'
                    onClick={() => setFieldValue(`activity_data.prova_frammentazione[${index}].prove[${provaIndex}].esito`, undefined)}
                  >
                    <i className='fa fa-times'/>
                  </button>
                </div>
              </td>
            </tr>
          )}
        </>
      )}
    />

    <tr>
      <td></td>
      <td>
        <div className='d-flex align-items-center justify-content-start gap-2'>
          <span className='text-nowrap'>Eseguito da&nbsp;</span>
          <InputField name={`activity_data.prova_frammentazione[${index}].eseguito_da`}/>
        </div>
      </td>
      <td colSpan={2}>
        <div className='d-flex align-items-center justify-content-start gap-2'>
          <span className='text-nowrap'>Li</span>
          <InputField name={`activity_data.prova_frammentazione[${index}].li`}/>
        </div>
      </td>
    </tr>
    <tr>
      <td></td>
      <td colSpan={3}>
        <div className='d-flex align-items-center justify-content-start gap-2'>
          <span className='text-nowrap'>Note</span>
          <InputField name={`activity_data.prova_frammentazione[${index}].note`}/>
        </div>
      </td>
    </tr>
    </tbody>
  )

}

export default ProvaDiTipoForm
