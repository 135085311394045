import React, { useEffect, useState } from 'react';
import GenericOffCanvas from '../../../../../../components/Custom/UI/Canvas/GenericOffCanvas';
import { formConfig } from './form.config';
import { LotStatesEnum } from '../../../../../../constants/enums/LosStatesEnum';
import { Form, FormikProvider, useFormik } from 'formik';
import { FormDisplay } from '../../../../../../components/Custom/Form/Display/FormDisplay';
import FormGroup from '../../../../../../components/Custom/Form/FormGroup';
import DateFormatDisplay from '../../../../../../components/Custom/Form/Display/DateFormatDisplay';
import { InputField } from '../../../../../../components/Custom/Form/InputField';
import CustomerPicker from '../../../../../../components/Custom/Form/Pickers/CustomerPicker/CustomerPicker';
import { FormCheckbox } from '../../../../../../components/Custom/Form/FormCheckbox';
import BrandPicker from '../../../../../../components/Custom/Form/Pickers/BrandPicker/BrandPicker';
import ColorPicker from '../../../../../../components/Custom/Form/Pickers/ColorPicker/ColorPicker';
import ThicknessPicker from '../../../../../../components/Custom/Form/Pickers/ThicknessPicker/ThicknessPicker';
import CancelButton from '../../../../../../components/Custom/Form/Buttons/CancelButton';
import SubmitButton from '../../../../../../components/Custom/Form/Buttons/SubmitButton';
import { lotService } from '../../../../../../services/lotService';
import { toast } from 'react-toastify';

const LotDetailsCanvas = ({ isOpen, toggle, handleUpdate, lot }) => {

  const [initialValues, setInitialValues] = useState(formConfig.initialValues)
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => lotService.update(lot.uuid, values)
  .then(res => handleUpdate(res))
  .catch(err => toast.error(err.response.data.message))
  .finally(() => setSubmitting(false))


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formConfig.validation,
    onSubmit: handleSubmit
  })

  const { setValues, values, setFieldTouched } = formik;

  useEffect(() => {
    setInitialValues(formConfig.setInitialValues(lot))
    setDisabled(lot.state.value === LotStatesEnum.in_progress);
  }, [isOpen]);


  return (
    <GenericOffCanvas title='Modifica lotto' isOpen={isOpen} toggle={toggle}>
      <FormikProvider value={formik}>
        <Form>
          <h3>Scheda di produzione</h3>
          <div className='row'>
            <div className='col-lg-6'>
              <FormGroup
                disabled={true}
                disabledValue={<DateFormatDisplay value={lot?.start_date}/>}
                name="start_date"
                label="Data"
                required={true}>
                <InputField name="start_date" type="date"/>
              </FormGroup>
            </div>

            <div className='col-lg-6'>
              <FormGroup name="forecast_date" label="Data consegna">
                <InputField name="forecast_date" type="date"/>
              </FormGroup>
            </div>

            <div className='col-lg-4'>
              <FormGroup
                name="total_qty"
                label="Quantità richiesta"
                required={true}>
                <InputField
                  name="total_qty"
                  type="number"
                />
                <div className='d-flex align-content-center justify-content-start gap-1 my-1'>
                  <button
                    className='btn btn-sm btn-info'
                    tabIndex={-1}
                    type="button"
                    onClick={() => setValues({ ...values, dx_qty: 0, sx_qty: 0 })}>
                    <i className="fa fa-window-restore"/>&nbsp;Reset quantità DX/SX
                  </button>
                </div>
              </FormGroup>
            </div>

            <div className="col-lg-4">
              <FormGroup
                name="dx_qty"
                label="Dei quali lato DX"
                required={true}
              >
                <InputField
                  name="dx_qty"
                  type="number"
                  onBlur={() => {
                    setFieldTouched('dx_qty')
                    const v = values.dx_qty || 0;
                    setValues({ ...values, dx_qty: v, sx_qty: v ? (values.total_qty || 0) - v : 0 })
                  }}
                  onChange={(e) => {
                    setFieldTouched('dx_qty')
                    const v = e.target.value || 0;
                    setValues({ ...values, dx_qty: v, sx_qty: v ? (values.total_qty || 0) - v : 0 })
                  }}
                />
              </FormGroup>
            </div>

            <div className="col-lg-4">
              <FormGroup name="sx_qty" label="Dei quali lato SX" required={true}>
                <InputField
                  name="sx_qty"
                  type="number"
                  onBlur={() => {
                    setFieldTouched('sx_qty')
                    const v = values.sx_qty || 0;
                    setValues({ ...values, dx_qty: v ? (values.total_qty || 0) - v : 0, sx_qty: v, })
                  }}
                  onChange={(e) => {
                    setFieldTouched('sx_qty')
                    const v = e.target.value || 0;
                    setValues({ ...values, dx_qty: v ? (values.total_qty || 0) - v : 0, sx_qty: v, })
                  }}
                />
              </FormGroup>
            </div>

            <div className="col-lg-4">
              <FormGroup
                disabled={true}
                disabledValue={lot?.lot_data?.customer?.business_name}
                name="customer_id" label="Cliente">
                <CustomerPicker
                  name='customer_id'
                  withCreate={false}
                  onSelected={() => {
                  }}/>
              </FormGroup>
            </div>

            <div className="col-lg-4">
              <FormGroup
                name="derogation"
                label="Deroga numero">
                <InputField name="derogation"/>
              </FormGroup>
            </div>

            <div className='col-lg-4'>
              <FormDisplay label='Documento emesso'>
                {lot?.lot_data?.user?.full_name}
              </FormDisplay>

            </div>
          </div>

          <FormCheckbox
            name="is_urgent"
            label="Il lotto è urgente"
          />

          <FormGroup name="note" label="Note lotto">
            <InputField name="note" component="textarea" rows={6}/>
          </FormGroup>

          <h3>Descrizione codice</h3>
          <div className='row'>
            <div className="col-md-6">
              <FormDisplay label="Codice">{lot?.lot_data?.item?.code}</FormDisplay>
            </div>
            <div className="col-md-6">
              <FormDisplay label="Casa automobilistica">{lot?.lot_data?.manufacturer?.name}</FormDisplay>
            </div>
            <div className="col-md-6">
              <FormDisplay label="Modello vettura">{lot?.lot_data?.vehicle?.name}</FormDisplay>
            </div>
            <div className="col-md-6">
              <FormDisplay label="Descrizione">{lot?.lot_data?.item?.description}</FormDisplay>
            </div>
          </div>

          <h3>Caratteristiche</h3>
          <div className="row">
            <div className="col-lg-6">
              <FormGroup
                disabled={true}
                disabledValue={lot?.lot_data?.brand?.name}
                name='brand_id'
                label="Marchio">
                <BrandPicker
                  name="brand_id"
                  withCreate={false}
                  onSelected={() => {
                  }}/>
              </FormGroup>

            </div>
            <div className="col-lg-6">
              <FormGroup
                name='color_id'
                label="Colore">
                <ColorPicker
                  name="color_id"
                  withCreate={false}
                  onSelected={() => {
                  }}
                />
              </FormGroup>
            </div>
            <div className="col-lg-6">
              <FormDisplay label="Curvatura">{lot?.lot_data?.curvature?.name}</FormDisplay>
            </div>
            <div className="col-lg-6">
              <FormGroup
                name='thickness_id'
                label="Spessore">
                <ThicknessPicker
                  name="thickness_id"
                  withCreate={false}
                  onSelected={() => {
                  }}
                />
              </FormGroup>
            </div>
          </div>

          <div className="d-flex align-content-center justify-content-end gap-1 mb-3">
            <CancelButton handleClick={() => toggle(false)}/>
            <SubmitButton/>
          </div>

        </Form>
      </FormikProvider>
    </GenericOffCanvas>
  )

}

export default LotDetailsCanvas
