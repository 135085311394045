import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { lotService } from "../../../../services/lotService";
import { Form, Formik } from "formik";
import CancelButton from "../../../../components/Custom/Form/Buttons/CancelButton";
import SaveButton from "../../../../components/Custom/UI/Buttons/SaveButton";
import { formConfig } from "./form.config";
import LotDetailCardForm from "./components/LotDetailCardForm";
import LotItemDescriptionForm from "./components/LotItemDescriptionForm";
import LotItemDetailForm from "./components/LotItemDetailForm";
import { toast } from "react-toastify";
import SubmitButton from '../../../../components/Custom/Form/Buttons/SubmitButton';

const LotCreate = () => {

  const navigate = useNavigate()

  const [item, setItem] = useState(undefined)

  return (
    <Formik
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validation}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => lotService.store(values)
      .then(res => {
        toast.success('Lotto creato con successo')
        navigate('../' + res.uuid)
      })
      .catch(err => toast.error(err.response.data.message))
      .finally(() => setSubmitting(false))
      }>

      {({ isSubmitting, isValid }) => (
        <Form>
            <LotDetailCardForm/>
            <LotItemDescriptionForm item={item} setItem={setItem}/>
            <LotItemDetailForm item={item}/>

          <div className="d-flex align-content-center justify-content-end mb-3">
            <CancelButton handleClick={() => navigate('/lots')}/>
            <SubmitButton disabled={isSubmitting || !isValid} loading={isSubmitting}/>
          </div>

        </Form>
      )}
    </Formik>
  )


}

export default LotCreate
