import React from 'react';
import { activityService } from '../../../services/ActivityService';
import { useRequest } from 'ahooks';
import { ActivitiesCheckerFilters } from './components/ActivitiesCheckerFilters';
import { Spinner } from 'reactstrap';
import { ActivityToCheck } from './components/ActivityToCheck';

export const ActivitiesChecker = () => {

  const { data: activities = [], run, loading } = useRequest(activityService.fetchFromDB, {
    defaultParams: [{
      filter: {
        checked: false,
        user_id: '',
        work_type: '',
        lot_number: ''
      }
    }]
  })

  return (
    <>
      <ActivitiesCheckerFilters handleUpdateActivities={run}/>
      {loading
        ? <Spinner/>
        : activities.map(activity =>
          <ActivityToCheck activity={activity} key={activity.uuid}/>
        )
      }
    </>
  )


}
