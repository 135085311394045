import React from 'react';
import { useFormikContext } from 'formik';

const SelectAllCheckboxesButton = ({ workConfig }) => {

  const { values, setFieldValue } = useFormikContext()

  return (
    <tfoot>
    <tr>
      <td colSpan={workConfig.checkbox_count}></td>
      <td className='text-center'>
        <button
          className='btn btn-light'
          type='button'
          onClick={() => setFieldValue('control_reports', Array(values.control_reports.length).fill(Array(workConfig.checkbox_count).fill(true)))}
        >Seleziona tutto
        </button>
      </td>
    </tr>
    </tfoot>
  )


}


export default SelectAllCheckboxesButton
