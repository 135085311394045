import React, {useEffect, useState} from 'react'
import {useRequest} from "ahooks";
import brandService from "../../../../../services/brandService";
import {FormSelect} from "../../FormSelect";
import BrandOffCanvas from "./BrandOffCanvas";
import {useFormikContext} from "formik";
import CanvasCreateEditButtons from "../../../UI/Canvas/CanvasCreateEditButtons";
import {toast} from "react-toastify";

const BrandPicker = ({name, onSelected, withCreate}) => {

    const {data, loading, runAsync} = useRequest(brandService.selectable);

    const [currentSelected, setCurrentSelected] = useState(undefined);
    const {values, setFieldValue} = useFormikContext()
    const [isCanvasOpen, setIsCanvasOpen] = useState(false);

    useEffect(() => {
        if (loading)
            return;

        if (values[name]) {
            const option = data.find(d => d.value === values[name])
            option
                ? brandService.fetch(option.uuid).then(res => setCurrentSelected(res))
                : setCurrentSelected(undefined)
        } else {
            setCurrentSelected(undefined)
        }

    }, [data, values[name]])

    const handleResponse = (value) => {
        runAsync().then(() => {
            onSelected({
                value: value.id,
                label: value.name
            })
            setIsCanvasOpen(false)
        })
    }

    const handleDelete = () => {
        brandService.destroy(currentSelected.uuid)
            .then(res => runAsync()
                .then(() => {
                    setFieldValue(name, undefined)
                    toast.success('Operazione eseguita con successo')
                })
            ).catch(err => toast.error(err.response.data.message))
    }


    return (
        <React.Fragment>

            <FormSelect
                name={name}
                onChange={(brand) => onSelected(data.find(d => d.value === brand))}
                loading={loading}
                placeholder="Seleziona un marchio"
                options={data}
                multiple={false}
            />

            {withCreate &&
                <>
                    <CanvasCreateEditButtons
                        value={values[name]}
                        canvasOpen={isCanvasOpen}
                        setCanvasOpen={setIsCanvasOpen}
                        handleDelete={handleDelete}
                    />


                    <BrandOffCanvas
                        isOpen={isCanvasOpen}
                        toggle={setIsCanvasOpen}
                        data={currentSelected}
                        handleResponse={handleResponse}
                    />
                </>
            }

        </React.Fragment>
    )
}

export default BrandPicker