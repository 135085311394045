import React, { useState } from "react";
import StateBadge from "./StateBadge";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { SweetAlert } from "../../../../helpers/SwalHelper";
import { LotContext } from "../../../../pages/Production/Lots/LotWrapper";
import { LotStatesEnum, LotStatusEnumArray } from "../../../../constants/enums/LosStatesEnum";
import { lotService } from "../../../../services/lotService";
import { toast } from "react-toastify";

const StateChanger = () => {

  const { lot, mutateLot, states, fetchStates, loadingStates } = React.useContext(LotContext)
  const [options, setOptions] = React.useState([]);
  const [isOpen, setIsOpen] = useState(false);

  React.useEffect(() => {
    setOptions(LotStatusEnumArray.filter(s => states.available_states.includes(s.value) && s.value !== lot?.state.value));
  }, [lot, states])

  const handleChange = (state) => {
    lotService.changeState(lot.uuid, state.value)
    .then(res => {
        mutateLot({
          ...lot,
          ...res,
          notified_at: res.state.value === LotStatesEnum.in_progress ? null : lot.notified_at
        });
        fetchStates(lot.uuid);
        toast.success('Operazione eseguita con successo')
      }
    ).catch(err =>
      toast.error('Impossibile aggiornare lo stato'))
  }

  const handleSelectedNewState = (option) => {
    SweetAlert.fire({
      text: `Sei sicuro di voler cambiare lo stato da ${states?.state?.label} a ${option.label}?`, icon: "warning"
    }).then(response => {
      if (response.isConfirmed) {
        handleChange(option);
        setIsOpen(false);
      }
    })
  };


  if (options.length === 0 && !loadingStates) {
    return (<StateBadge className='p-2' value={states?.state}/>)
  }

  return (
    <>
      {!loadingStates ?
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
          <DropdownToggle caret color={states.state.color}>
            {states.state.label}
            {options.length > 0 && <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>}
          </DropdownToggle>
          {options.length > 0 &&
            <DropdownMenu>
              {options.map((option, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => handleSelectedNewState(option)}>
                  {option.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          }
        </Dropdown>
        : <></>
      }
    </>
  )

}

export default StateChanger
