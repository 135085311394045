import * as Yup from "yup";
import { find, get } from 'lodash';

const validation = Yup.object().shape({
  lot_works: Yup.array().required()
});

const initialValues = {
  lot_works: []
};

const setInitialValues = (lotWorkConfigs = [], lotWorks = []) => ({
  lot_works: lotWorkConfigs.map(lotWorkConfig => {
    const configuredLotWork = lotWorks.find(lw => lw?.type?.value === lotWorkConfig.value)
    return {
      uuid: get(configuredLotWork, 'uuid', undefined),
      has_mono: lotWorkConfig.has_mono,
      has_dx: lotWorkConfig.has_dx,
      has_sx: lotWorkConfig.has_sx,
      work_type: lotWorkConfig.value,
      work_type_name: lotWorkConfig.label,
      selected: !!configuredLotWork?.selected,
      template_id: get(configuredLotWork, 'lot_work_data.template.id', undefined),
      program_id: get(configuredLotWork, 'lot_work_data.program.id', undefined),
      extra_data: lotWorkConfig.extra_data.map(et => ({
        name: et.name,
        label: et.label,
        value: find(get(configuredLotWork, 'lot_work_data.extraData'), (extraData) => extraData.name === et.name)?.value,
        options: et.values
      })),
      has_template: lotWorkConfig.has_template,
      has_program: lotWorkConfig.has_program,
    }
  })
})

const lotWorkFormConfigs = {
  validation,
  initialValues,
  setInitialValues
}

export default lotWorkFormConfigs
