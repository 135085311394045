import React from 'react'
import { SweetAlert } from "../../../../helpers/SwalHelper";

const DeleteButton = ({ disabled, message, handleClick }) => {

  const handleConfirm = () => {
    SweetAlert.fire({
      text: message || "Sei sicuro?", icon: "warning"
    }).then(response => {
      if (response.isConfirmed) {
        handleClick()
      }
    })
  }

  return (
    <button
      disabled={disabled || false}
      onClick={handleConfirm}
      type="button"
      className='btn btn-danger'>
      <i className="fa fa-trash-alt"/>&nbsp;Elimina
    </button>
  )
}

export default DeleteButton
