import React from 'react'
import { Link } from "react-router-dom";

export const itemColumns = [
  {
    id: 'code',
    Header: 'Codice',
    accessor: 'code',
    Cell: ({ row, value }) => <Link className="fw-bold" to={row.original.uuid}>{value}</Link>
  }, {
    Header: 'Descrizione',
    disableSortBy: true,
    accessor: 'description',
  }
]
