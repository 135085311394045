import React from "react";
import clsx from "clsx";

export const NumberDisplay = ({value, limit = 0, suffix = null}) => {

    if (value) {
        return <span className={clsx("fw-bold", {
            'text-danger': value < limit,
            'text-primary': value >= limit,
        })}>{value} {suffix || ''}</span>
    }

    return <>---</>
}
