import React from 'react';
import { useRequest } from 'ahooks';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { lotService } from '../../../../../services/lotService';
import { LotStatesEnum } from '../../../../../constants/enums/LosStatesEnum';

const LotPicker = props => {

  const { loading, runAsync } = useRequest(lotService.selectable, {
    defaultParams: [],
    manual: true
  });
  const bouncedHandleLoadOptions = (value, callback) => {
    runAsync({
      filter: {
        number: value || '',
        state: props.states ?? []
      },
    }).then((res) => callback(res))
    .catch(err => callback([]));
  };

  const handleLoadOptions = debounce(bouncedHandleLoadOptions, 500);

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={[]}
      loadOptions={handleLoadOptions}
      isLoading={loading}
      isClearable
      onChange={props.handleChange}
      loading={loading}
      placeholder={'Ricerca lotto'}
      {...props}
    />
  );
}

export default LotPicker;
