import Swal from 'sweetalert2';

export const SweetAlert = Swal.mixin({
    showCancelButton: true,
    buttonsStyling: false,
    confirmButtonText: "Si, continua",
    cancelButtonText: "No, annulla",
    customClass: {
        confirmButton: "btn fw-bold btn-danger",
        cancelButton: "btn fw-bold btn-active-light-primary"
    }
})


export const SweetAlertToast = Swal.mixin({
    toast: true,
    position: 'top-end',
    iconColor: 'white',
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
