import React from "react";
import {useRequest} from "ahooks";
import {useParams} from "react-router-dom";
import {archiveService} from "../../../services/archiveService";
import {Card, CardBody, Col, ListGroup, Row, Table} from "reactstrap";
import ArchivedLotEntry from "./_components/ArchivedLotEntry";
import {NumberDisplay} from "../../../components/Custom/Form/Display/NumberDisplay";
import ArchivedLotEntries from "./_components/ArchivedLotEntries";
import ArchivedLotDetail from "./_components/ArchivedLotDetail";

const ArchivedLot = () => {

    const {id} = useParams()

    const {data, loading} = useRequest(archiveService.fetch, {defaultParams: [id]})

    return (
        <>
            {!loading &&
                <>
                    <ArchivedLotDetail lot={data} />

                    <Row>
                        <Col lg={6}>
                            <ArchivedLotEntries
                                title="Imballi"
                                entries={data?.archived_lot_packings}
                            />
                        </Col>
                        <Col lg={6}>
                            <ArchivedLotEntries
                                title="Spedizioni"
                                entries={data?.archived_lot_shipments}
                            />
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}

export default ArchivedLot
