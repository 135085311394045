import React from 'react';
import DenverShow from './_components/Denver/DenverShow';
import ForvetShow from './_components/Forvet/ForvetShow';
import MalnatiShow from './_components/Malnati/MalnatiShow';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import toolConfigService from '../../../services/toolConfigService';
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import { FormDisplay } from '../../../components/Custom/Form/Display/FormDisplay';

const ToolConfig = () => {

  const { id } = useParams()
  const { data, loading } = useRequest(toolConfigService.fetch, { defaultParams: [id] })

  const getContent = () => {
    switch (data?.manufacturer) {
      case 'Denver':
        return <DenverShow toolConfig={data}/>;
      case 'Forvet':
        return <ForvetShow toolConfig={data}/>;
      case 'Malnati':
        return <MalnatiShow toolConfig={data}/>;
      default:
        return <>Produttore macchina non trovato.</>;
    }
  }

  return loading
    ? <Spinner/>
    : <>
      <h3>{data?.tool_name}</h3>
      <Card>
        <CardBody>
          <Row>
            <Col lg={4} md={6}>
              <FormDisplay label="Produttore">{data?.manufacturer}</FormDisplay>
            </Col>
            <Col lg={4} md={6}>
              <FormDisplay label="S/N">{data?.tool_sn}</FormDisplay>
            </Col>
            <Col lg={4} md={6}>
              <FormDisplay label="Percorso">{data?.path}</FormDisplay>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {getContent()}
    </>

}

export default ToolConfig
