import React from "react";

export const FormDisplay = ({children, label, inline = false, color = 'muted'}) => {

    return inline
        ? (<div className="row mb-2">
            <label className={`form-label fw-bold text-${color} col-lg-6`}>{label}</label>
            <div className="fs-6 col-lg-6">{children || '---'}</div>
        </div>)
        : (<div className="mb-2">
            <label className={`form-label fw-bold text-${color}`}>{label}</label>
            <div className="fs-6">{children || '---'}</div>
        </div>)

}
