import React from 'react';
import { useFormikContext } from 'formik';
import FormGroup from '../../../../components/Custom/Form/FormGroup';
import { InputField } from '../../../../components/Custom/Form/InputField';
import ManufacturerPicker from '../../../../components/Custom/Form/Pickers/ManufacturerPicker/ManufacturerPicker';
import VehiclePicker from '../../../../components/Custom/Form/Pickers/VehiclePicker/VehiclePicker';
import BrandPicker from '../../../../components/Custom/Form/Pickers/BrandPicker/BrandPicker';
import ColorPicker from '../../../../components/Custom/Form/Pickers/ColorPicker/ColorPicker';
import CurvaturePicker from '../../../../components/Custom/Form/Pickers/CurvaturePicker/CurvaturePicker';
import ThicknessPicker from '../../../../components/Custom/Form/Pickers/ThicknessPicker/ThicknessPicker';

const ItemForm = () => {

  const formik = useFormikContext()

  return (
    <React.Fragment>
      <h3>Articolo</h3>
      <FormGroup name="description" label="Descrizione" required={true} inline={false}>
        <InputField name="description" type="text" maxLength={100}/>
      </FormGroup>

      <div className='row'>
        <div className='col-lg-6'>
          <FormGroup name="manufacturer_id" label="Casa automobilistica">
            <ManufacturerPicker
              withCreate={true}
              name="manufacturer_id"
              onSelected={(manufacturer) => formik.setValues({
                ...formik.values,
                'manufacturer_id': manufacturer?.value || "",
                'vehicle_id': ""
              })}/>
          </FormGroup>
        </div>
        <div className='col-lg-6'>
          <FormGroup name='vehicle_id' label="Modello Vettura">
            <VehiclePicker
              withCreate={true}
              name="vehicle_id"
              onSelected={(vehicle) => formik.setValues({
                ...formik.values,
                'manufacturer_id': vehicle?.manufacturer_id || formik.values.manufacturer_id,
                'vehicle_id': vehicle?.value || ""
              })}
            />
          </FormGroup>
        </div>
      </div>

      <h3>Caratteristiche</h3>
      <div className='row'>
        <div className='col-lg-6'>
          <FormGroup name='brand_id' label="Marchio" inline={false}>
            <BrandPicker
              name="brand_id"
              withCreate={true}
              onSelected={(brand) => formik.setValues({ ...formik.values, brand_id: brand?.value || '' })}
            />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <FormGroup name='color_id' label="Colore" inline={false}>
            <ColorPicker
              name="color_id"
              withCreate={true}
              onSelected={(color) => formik.setValues({ ...formik.values, color_id: color?.value || '' })}
            />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <FormGroup name='curvature_id' label="Curvatura" inline={false}>
            <CurvaturePicker
              withCreate={true}
              name="curvature_id"
              onSelected={(curvature) => formik.setValues({ ...formik.values, curvature_id: curvature?.value || '' })}
            />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <FormGroup name='thickness_id' label="Spessore" inline={false}>
            <ThicknessPicker
              withCreate={true}
              name="thickness_id"
              onSelected={(thickness) => formik.setValues({ ...formik.values, thickness_id: thickness?.value || '' })}
            />
          </FormGroup>
        </div>
      </div>

      <FormGroup name="note" label="Note" inline={false}>
        <InputField name="note" component="textarea" rows={6}/>
      </FormGroup>
    </React.Fragment>
  )

}

export default ItemForm
