import React from "react";
import { FormDisplay } from "../../../../../../../components/Custom/Form/Display/FormDisplay";

const ActivityNotesShow = ({ activity }) => {

  return (
    <div className='row'>
      <div className='col-lg-6'>
        <FormDisplay label="Motivazione fermo">{activity?.stop_cause}</FormDisplay>
      </div>
      <div className='col-lg-6'>
        <FormDisplay label="Note">{activity?.note}</FormDisplay>
      </div>
    </div>
  )

}

export default ActivityNotesShow
