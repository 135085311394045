import React from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import FormGroup from "../../../../components/Custom/Form/FormGroup";
import {InputField} from "../../../../components/Custom/Form/InputField";
import {FormSelect} from "../../../../components/Custom/Form/FormSelect";
import {LotStatusEnumArray} from "../../../../constants/enums/LosStatesEnum";
import {Form, Formik} from "formik";
import ItemPicker from "../../../../components/Custom/Form/Pickers/ItemPicker/ItemPicker";

const IndustryLotFilters = ({onChangeFilters}) => {

    const initialValues = {
        filter: {
            lot_number: '',
            item_id: '',
            state: ['in_progress']
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, {setSubmitting}) => {
                onChangeFilters(values)
                setSubmitting(false)
            }}>
            {({handleSubmit, setFieldValue}) => (
                <Form onSubmit={handleSubmit}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={6}>
                                    <FormGroup name="filter.lot_number" label="Nr. Lotto" inline={false}>
                                        <InputField name="filter.lot_number" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col lg={6}>
                                    <FormGroup name="filter.item_id" label="Codice Articolo" inline={false}>
                                        <ItemPicker
                                            name="filter.item_id"
                                            onSelected={(item) => setFieldValue('filter.item_id', item?.id)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button type="submit" className="float-end" size="sm" color="info">
                                <i className="fa fa-filter"/> Filtra risultati
                            </Button>
                        </CardBody>
                    </Card>
                </Form>
            )}
        </Formik>
    );

}

export default IndustryLotFilters;
