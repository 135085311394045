import React from 'react';

const forvetCols = [
  {
    Header: 'Data',
    accessor: 'date',
  },
  {
    Header: 'Commessa',
    accessor: 'lot',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'ID Vetro',
    accessor: 'data[2]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Nome',
    accessor: 'data[3]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  // {
  //   Header: 'FromSide',
  //   accessor: 'data[4]',
  //   Cell: ({ value }) => <>{value || ""}</>
  // },
  // {
  //   Header: 'Dest',
  //   accessor: 'data[5]',
  //   Cell: ({ value }) => <>{value || ""}</>
  // },
  {
    Header: 'Qtà da fare',
    accessor: 'data[6]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Qtà fatta',
    accessor: 'data[7]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Dimensione X',
    accessor: 'data[8]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Dimensione Y',
    accessor: 'data[9]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  // {
  //   Header: 'Thick',
  //   accessor: 'data[10]',
  //   Cell: ({ value }) => <>{value || ""}</>
  // },
  {
    Header: 'Qtà forata',
    accessor: 'data[11]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Qtà mill',
    accessor: 'data[12]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Inizio',
    accessor: 'data[13]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  // {
  //   Header: 'ProcessLine',
  //   accessor: 'data[14]',
  //   Cell: ({ value }) => <>{value || ""}</>
  // },
  {
    Header: 'Fine',
    accessor: 'data[15]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Scarico',
    accessor: 'data[16]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  // {
  //   Header: 'TimeFlush',
  //   accessor: 'data[17]',
  //   Cell: ({ value }) => <>{value || ""}</>
  // },
];

export default forvetCols
