import React, { useState } from 'react'
import { lotService } from "../../../../services/lotService";
import { lotColumns } from "./lotColumns";
import { useRequest } from "ahooks";
import { useNavigate } from "react-router-dom";
import CreateButton from "../../../../components/Custom/Form/Buttons/CreateButton";
import LotFilters from "../components/LotFilters";
import { LotStatesEnum } from '../../../../constants/enums/LosStatesEnum';
import { useSanctum } from 'react-sanctum';
import { lotDataEntryColumns } from './lotDataEntryColumns';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import tableOptions from '../../../../constants/tableOptions';

const Lots = () => {

  const {
    data = [],
    loading,
    run,
  } = useRequest(lotService.fetchAll, { manual: true })
  const { user } = useSanctum()

  const defaultParams = {
    filter: {
      lot_number: '',
      description: '',
      item_id: '',
      thickness: '',
      color: '',
      curvature: '',
      state: user.can_manage_production_module ? [] : [LotStatesEnum.in_progress],
      lot_works_not_completed: user.can_manage_production_module ? null : true
    }
  }
  const [extraParams, setExtraParams] = useState(defaultParams);

  const navigate = useNavigate()
  const columns = React.useMemo(() =>
      user.can_manage_administration_module ? lotColumns : lotDataEntryColumns,
    [])


  return (
    <>
      <LotFilters
        filters={extraParams}
        handleResetFilters={() => setExtraParams(defaultParams)}
        onChangeFilters={f => setExtraParams(f)}
      />

      <div className='card'>
        <div className='card-body'>
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <h3>Lotti</h3>
            {user.can_manage_production_module &&
              <CreateButton handleClick={() => navigate('create')}>Nuovo</CreateButton>
            }
          </div>
          <PaginatedTable
            options={tableOptions}
            columns={columns}
            data={data ?? []}
            isLoading={loading}
            filterable={false}
            extraParams={extraParams}
            onParamsChange={run}
          />
        </div>
      </div>

    </>
  );

}

export default Lots
