import programService from '../../../../../services/programService';
import { useFormikContext } from 'formik';
import { useRequest } from 'ahooks';
import { FormSelect } from '../../FormSelect';
import React from 'react';
const ProgramPicker = ({ name }) => {

  const { data, loading, runAsync } = useRequest(programService.selectable);

  const { values, setFieldValue } = useFormikContext()

  const onSelected = program => setFieldValue(name, program)

  return (
    <FormSelect
      name={name}
      onChange={(program) => onSelected(program)}
      loading={loading}
      placeholder="Seleziona un programma"
      options={data}
      multiple={false}
    />
  )
}

export default ProgramPicker