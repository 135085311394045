import React, {useEffect} from 'react'
import {useFormikContext} from "formik";
import {useRequest} from "ahooks";
import {lotService} from "../../../../../services/lotService";
import {Button, Spinner} from "reactstrap";
import {InputField} from "../../../../../components/Custom/Form/InputField";
import CreateButton from "../../../../../components/Custom/Form/Buttons/CreateButton";

const LotNumberGetter = ({name}) => {

    const {setFieldValue} = useFormikContext();
    const {data: lotNumber, loading, runAsync: getLotNumber} = useRequest(lotService.nextNumber, {manual: true})


    const refreshNumber = () =>
        getLotNumber().then(res => {
            setFieldValue(name, res.number)
        })

    useEffect(() => {
        refreshNumber()
    }, [])

    return <>
        {loading ? <Spinner size='sm'/> :
            <>
                <InputField name="lot_number" type="number"/>
                <button type='button' className='btn btn-link btn-sm p-1 fw-bold' onClick={() => refreshNumber()}>
                    <i className="fa fa-arrow-right me-1" /> Richiedi numero
                </button>
            </>}
    </>

}

export default LotNumberGetter
