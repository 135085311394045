import React from 'react';
import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";
import Login from "../pages/Authentication/Login";
import NewPassword from "../pages/Authentication/NewPassword";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import Dashboard from "../pages/Dashboard/Dashboard";
import itemsRoutes from "./private/itemsRoutes";
import usersRoutes from "./private/usersRoutes";
import lotsRoutes from "./private/lotsRoutes";
import systemTableRoutes from "./private/systemTableRoutes";
import Stats from "../pages/Administration/Stats/Stats";
import Archive from "../pages/Production/Archive/Archive";
import ArchivedLot from "../pages/Production/Archive/ArchivedLot";
import toolConfigsRoutes from './private/toolConfigsRoutes';
import CheckUserModule from '../components/Custom/Permissions/CheckUserModule';
import industryLotsRoutes from './private/industryLotsRoutes';
import { ActivitiesChecker } from '../pages/Production/ActivitiesChecker/ActivitiesChecker';

const appRoutes = [
  {
    path: "/",
    element: <PrivateRoute/>,
    breadcrumb: "Dashboard",
    children: [
      {
        index: true,
        element: <Dashboard/>,
      },
      {
        path: 'stats/*',
        breadcrumb: "Statistiche",
        element: <CheckUserModule module={['can_manage_administration_module']}>
          <Stats/>
        </CheckUserModule>
      },
      {
        path: 'archives/*',
        breadcrumb: "Archivio",
        element: <CheckUserModule module={['can_manage_production_module']}>
          <Archive/>
        </CheckUserModule>
      },
      {
        path: 'archives/:id',
        breadcrumb: "Lotto",
        element: <CheckUserModule module={['can_manage_production_module']}>
          <ArchivedLot/>
        </CheckUserModule>
      },
      itemsRoutes,
      usersRoutes,
      lotsRoutes,
      systemTableRoutes,
      toolConfigsRoutes,
      industryLotsRoutes,
      {
        path: 'activities-checker',
        element: <CheckUserModule module={['can_manage_production_module']}>
          <ActivitiesChecker/>
        </CheckUserModule>
      }
    ]
  },
  {
    path: "/",
    element: <GuestRoute/>,
    children: [
      { path: "/login", element: <Login/> },
      { path: "/forgot-password", element: <ForgetPassword/> },
      { path: "/new-password/:token", element: <NewPassword/> }
    ]
  },
  { path: "*", element: <>NOT FOUND 404</> },

]

export default appRoutes
