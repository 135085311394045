import { InputField } from "../../../../../../../components/Custom/Form/InputField";
import React from "react";

const ActivityTableForm = ({
                             withReworked = true,
                             hasMono = false,
                             hasDx = false,
                             hasSx = false
                           }) => {

  return (
    <div className='table-responsive'>
      <table className='table table-hover'>
        <thead>
        <tr>
          <th></th>
          <th>Idonei</th>
          <th>Scarti</th>
          {withReworked && <th>Da rilavorare</th>}
          {withReworked && <th>Rilavorati</th>}
        </tr>
        </thead>
        <tbody>
        {hasMono &&
          <tr>
            <td className='text-end'>PIANI</td>
            <td><InputField name="manufactured_qty" type="number" min={0}/></td>
            <td><InputField name="wasted_qty" type="number" min={0}/></td>
            {withReworked && <td><InputField name="to_be_reworked_qty" type="number" min={0}/></td>}
            {withReworked && <td><InputField name="rework_qty" type="number" min={0}/></td>}
          </tr>}
        {hasDx &&
          <tr>
            <td className='text-end'>DX</td>
            <td><InputField name="manufactured_dx_qty" type="number" min={0}/></td>
            <td><InputField name="wasted_dx_qty" type="number" min={0}/></td>
            {withReworked && <td><InputField name="to_be_reworked_dx_qty" type="number" min={0}/></td>}
            {withReworked && <td><InputField name="rework_dx_qty" type="number" min={0}/></td>}
          </tr>}
        {hasSx &&
          <tr>
            <td className='text-end'>SX</td>
            <td><InputField name="manufactured_sx_qty" type="number" min={0}/></td>
            <td><InputField name="wasted_sx_qty" type="number" min={0}/></td>
            {withReworked && <td><InputField name="to_be_reworked_sx_qty" type="number" min={0}/></td>}
            {withReworked && <td><InputField name="rework_sx_qty" type="number" min={0}/></td>}
          </tr>}
        </tbody>
      </table>
    </div>
  )

}

export default ActivityTableForm
