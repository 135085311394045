import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const AmministrazioneDropdown = ({ user }) => {

  if (user.can_manage_administration_module) {
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>Amministrazione</DropdownToggle>
        <DropdownMenu>
          <DropdownItem href='/users'>
            <i className="bx bx-user"/>&nbsp;Utenti
          </DropdownItem>
          <DropdownItem href='/stats'>
            <i className="bx bx-line-chart"/>&nbsp;Statistiche
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }
  return <></>;

}

export default AmministrazioneDropdown
