import React from 'react'
import CancelButton from "../../Form/Buttons/CancelButton";
import SaveButton from "../Buttons/SaveButton";
import {useFormikContext} from "formik";
import SubmitButton from '../../Form/Buttons/SubmitButton';

const GenericCanvasActions = ({toggle, children}) => {

    return (
        <div className="d-flex align-content-center justify-content-end gap-1">
            <CancelButton handleClick={() => toggle(false)}/>
            <SubmitButton />
            {children}
        </div>
    )
}

export default GenericCanvasActions
