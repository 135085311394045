import React, {useEffect, useState} from 'react'
import {useRequest} from "ahooks";
import {Card, CardBody} from "reactstrap";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import tableOptions from "../../../../constants/tableOptions";
import CreateButton from "../../../../components/Custom/Form/Buttons/CreateButton";
import customerService from "../../../../services/customerService";
import CustomerOffCanvas from "./CustomerOffCanvas";

const Customers = () => {

    const {data, loading, run} = useRequest(customerService.fetchAll, {manual: true})

    const [isCanvasOpen, setIsCanvasOpen] = useState(false)
    const [currentSelected, setCurrentSelected] = useState(undefined);

    useEffect(() => {
        if (currentSelected) {
            setIsCanvasOpen(true)
        }
    }, [currentSelected])

    useEffect(() => {
        if (!isCanvasOpen) {
            setCurrentSelected(undefined)
        }
    }, [isCanvasOpen])

    return <Card>
        <CardBody>
            <PaginatedTable
                options={tableOptions}
                TableActions={
                    <CreateButton handleClick={() => setIsCanvasOpen(true)}>Nuovo Cliente</CreateButton>
                }
                columns={[
                    {
                        id: 'id',
                        Header: 'Ragione Sociale',
                        accessor: 'business_name',
                        Cell: ({row, value}) => <span className='fw-bold link-primary' style={{cursor: 'pointer'}}
                                                      onClick={() => setCurrentSelected(row.original)}>{value}</span>
                    }, {
                        Header: 'Note',
                        disableSortBy: true,
                        accessor: 'note',
                    }
                ]}
                data={data ?? []}
                isLoading={loading}
                filterable
                sortable
                onParamsChange={run}
            />

            <CustomerOffCanvas
                toggle={setIsCanvasOpen}
                isOpen={isCanvasOpen}
                data={currentSelected}
                handleResponse={(res) => {
                    setCurrentSelected(undefined)
                    run()
                }}
            />

        </CardBody>
    </Card>

}

export default Customers
