import React from 'react';
import {ErrorMessage, useField} from "formik";
import clsx from 'clsx';
import {FormDisplay} from "./Display/FormDisplay";


export const FormGroup = ({name, inline, required, label, description, children, disabled, disabledValue}) => {

    const [field, meta] = useField(name);
    const {value, error, touched} = meta;
    const isInvalid = required && (error || value == null || value.length === 0) && touched;

    const labelClasses = {
        "required": required,
        "text-danger font-weight-bold": isInvalid
    }

    if (disabled) {
        return <FormDisplay label={label}>{disabledValue}</FormDisplay>
    }

    return (
        <div className="mb-3">
            {inline ?
                <div className="row">
                    <label htmlFor={name} className={clsx("col-lg-4 fw-bold text-muted", labelClasses)}>
                        {isInvalid && <i className="fa fa-square-exclamation ms-1"/>}
                        {label}
                    </label>
                    <div className="col-lg-8">
                        {children}
                        {description && <span className="text-muted">{description}</span>}
                        <ErrorMessage name={name} component="div" className="d-block invalid-feedback"/>
                    </div>
                </div>
                : <>
                  {label ?
                    <label htmlFor={name} className={clsx("form-label fw-bold text-muted", labelClasses)}>
                        {isInvalid && <i className="fa fa-square-exclamation ms-1"/>}
                        {label}
                    </label> : <></>
                  }
                    {children}
                    {description && <span className="text-muted">{description}</span>}
                    <ErrorMessage name={name} component="div" className="d-block invalid-feedback"/>
                </>
            }
        </div>
    );
}

export default FormGroup;
