import React from 'react'
import PropTypes from "prop-types";

const DisplayBooleanOrUndefined = ({value}) => {

    const handleValue = () => {
        if (value === 1 || value === '1' || value === true) {
            return <><i className="fa fa-check text-success"/>&nbsp;SI</>
        } else if (value === 0 || value === '0' || value === false) {
            return <><i className="fa fa-times text-danger"/>&nbsp;NO</>
        } else {
            return <>---</>
        }
    }

    return <>{handleValue()}</>


}

DisplayBooleanOrUndefined.propTypes = {
    value: PropTypes.any
}

export default DisplayBooleanOrUndefined