import React from 'react'
import LotsWrapper from "../../pages/Production/Lots/LotsWrapper";
import Lots from "../../pages/Production/Lots/Lots/Lots";
import LotCreate from "../../pages/Production/Lots/LotCreate/LotCreate";
import LotWrapper from "../../pages/Production/Lots/LotWrapper";
import Lot from "../../pages/Production/Lots/Lot/Lot";
import LotWork from '../../pages/Production/Lots/Lot/LotWork/LotWork';
import LotWorkActivities from '../../pages/Production/Lots/Lot/LotWork/Activities/LotWorkActivities';
import ShowActivity from '../../pages/Production/Lots/Lot/LotWork/Activity/ShowActivity/ShowActivity';
import CreateActivity from '../../pages/Production/Lots/Lot/LotWork/Activity/CreateActivity/CreateActivity';
import EditActivity from '../../pages/Production/Lots/Lot/LotWork/Activity/EditActivity/EditActivity';

const usersRoutes = {
  path: '/lots',
  element: <LotsWrapper/>,
  children: [
    {
      path: '',
      index: true,
      breadcrumb: "Elenco lotti",
      element: <Lots/>
    },
    {
      path: 'create',
      breadcrumb: "Nuovo lotto",
      element: <LotCreate/>
    },
    {
      path: ':id',
      element: <LotWrapper/>,
      children: [
        {
          path: '',
          breadcrumb: "Lotto",
          element: <Lot/>,
        },
        {
          path: ':lot_work_id/',
          handle: { crumb: () => 'Dettaglio lavorazione' },
          element: <LotWork/>,
          children: [
            {
              path: '',
              breadcrumb: "Lavorazione",
              element: <LotWorkActivities/>,
            },
            {
              breadcrumb: "Attività",
              path: ':activity_id',
              handle: { crumb: () => 'Dettaglio attività' },
              element: <ShowActivity/>
            },
            {
              breadcrumb: "Nuova attività",
              path: 'create',
              handle: { crumb: () => 'Crea attività' },
              element: <CreateActivity/>
            },
            {
              breadcrumb: "Modifica attività",
              path: ':activity_id/edit',
              handle: { crumb: () => 'Modifica attività' },
              element: <EditActivity/>
            }
          ]
        }
      ]
    }
  ]
}

export default usersRoutes
