import * as Yup from "yup";

const validation = Yup.object().shape(
    {name: Yup.string().max(255, 'Max 255 caratteri').required('Obbligatorio')},
    {code: Yup.number().min(1, 'deve essere maggiore di 0').required('Obbligatorio')}
)

const initialValues = {
    name: '',
    code: ''
}
export const itemCategoryForm = {
    validation,
    initialValues
}