import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import FormGroup from "../../../../../components/Custom/Form/FormGroup";
import {FormDisplay} from "../../../../../components/Custom/Form/Display/FormDisplay";
import BrandPicker from "../../../../../components/Custom/Form/Pickers/BrandPicker/BrandPicker";
import ColorPicker from "../../../../../components/Custom/Form/Pickers/ColorPicker/ColorPicker";
import ThicknessPicker from "../../../../../components/Custom/Form/Pickers/ThicknessPicker/ThicknessPicker";
import {InputField} from "../../../../../components/Custom/Form/InputField";

const LotItemDetailForm = ({item}) => {

    return (
        <Card>
            <CardBody>
                <h3>Caratteristiche</h3>
                <Row>
                    <Col lg={6}>
                        <FormGroup name='brand_id' label="Marchio" inline={false}>
                            <BrandPicker
                                name="brand_id"
                                withCreate={false}
                                onSelected={() => {
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <FormGroup name='color_id' label="Colore" inline={false}>
                            <ColorPicker
                                name="color_id"
                                withCreate={false}
                                onSelected={() => {
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <FormDisplay label="Curvatura">{item?.curvature?.name}</FormDisplay>
                    </Col>
                    <Col lg={6}>
                        <FormGroup name='thickness_id' label="Spessore" inline={false}>
                            <ThicknessPicker
                                name="thickness_id"
                                withCreate={false}
                                onSelected={() => {
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup name="item_note" label="Note articolo" inline={false}>
                    <InputField name="item_note" component="textarea" rows={3}/>
                </FormGroup>

            </CardBody>
        </Card>
    )

}

export default LotItemDetailForm
