import React from 'react';
import SelectAllCheckboxesButton from '../../components/SelectAllCheckboxesButton';
import ReportCheckboxFormRows from '../../components/ReportCheckboxFormRows';
import ForaturaELavaggioReportsTable from './ForaturaELavaggioReportsTable';

const ForaturaELavaggioForm = ({ workConfig }) => {

  return (
    <>
      <ForaturaELavaggioReportsTable action={<SelectAllCheckboxesButton workConfig={workConfig}/>}>
        <ReportCheckboxFormRows workConfig={workConfig}/>
      </ForaturaELavaggioReportsTable>
    </>
  )

}

export default ForaturaELavaggioForm
