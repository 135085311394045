import React from 'react'
import { FormDisplay } from "../../../../../../../../../components/Custom/Form/Display/FormDisplay";
import SerigrafiaLunottiReportsTable from '../SerigrafiaLunottiReportsTable';
import ReportCheckboxFormRows from '../../../components/ReportCheckboxShowRows';

const SerigrafiaLunottiFaseTreShow = ({ activity, workConfig }) => {

  return (
    <>
      <div className='row'>
        <div className='col-lg-4 col-md-6'>
          <FormDisplay label="Fornitore vernice nera">
            {activity?.activity_data?.black_ink_supplier}
          </FormDisplay>
        </div>
        <div className='col-lg-4 col-md-6'>
          <FormDisplay label="Lotto vernice nera n°">
            {activity?.activity_data?.black_ink_lot}
          </FormDisplay>
        </div>
      </div>
      <SerigrafiaLunottiReportsTable>
        <ReportCheckboxFormRows activity={activity} workConfig={workConfig}/>
      </SerigrafiaLunottiReportsTable>
    </>
  )
}

export default SerigrafiaLunottiFaseTreShow
