import React, {useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Formik} from "formik"
import {InputField} from "../../components/Custom/Form/InputField";
import FormGroup from "../../components/Custom/Form/FormGroup";
import * as Yup from "yup";
import {Alert} from "reactstrap";
import authService from "../../services/authService";

const NewPassword = () => {

    const {token} = useParams();
    const navigate = useNavigate();

    const initialValues = {
        email: "",
        password: "",
        password_confirmation: ""
    }

    const validation = Yup.object().shape({
        email: Yup.string().email().required("Email obbligatoria"),
        password: Yup.string().required("Password richiesta"),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], "Le password non coincidono")
    });

    const [message, setMessage] = useState(undefined);

    return (<>
        <h3>Nuova password</h3>

        {message && <Alert color={message.color}>{message.message}</Alert>}

        <Formik initialValues={initialValues}
                validationSchema={validation}
                onSubmit={(values, {resetForm, setSubmitting}) => {
                    authService.resetPassword(values.email, values.password, values.password_confirmation, token)
                        .then(res => {
                            setMessage({
                                color: 'success',
                                message: res.data.message
                            })
                            resetForm()
                        })
                        .catch(err => setMessage({
                            color: 'danger',
                            message: err.response.data.message
                        }))
                        .finally(() => setSubmitting(false))
                }}>
            {({isSubmitting, handleSubmit, isValid, values}) => (
                <form onSubmit={handleSubmit} className="form-horizontal">
                    <FormGroup name="email" label="Email" required={true} inline={false}>
                        <InputField name="email" type="email"/>
                    </FormGroup>
                    <FormGroup label="Password" name="password" required={false} inline={false}>
                        <InputField name="password" type="password"/>
                    </FormGroup>
                    <FormGroup label="Conferma Password" name="password_confirmation" required={false} inline={false}>
                        <InputField name="password_confirmation" type="password"/>
                    </FormGroup>
                    <div className="d-flex align-content-center justify-content-between">
                        <Link to="/login"
                              type="button"
                              className="text-muted align-self-center">
                            Annulla
                        </Link>
                        <button className="btn btn-primary btn-block"
                                disabled={isSubmitting || !isValid}
                                type="submit">
                            Aggiorna Password
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    </>)

}

export default NewPassword;