import React from 'react';

const denverCols = [
  {
    Header: 'Data',
    accessor: 'date'
  },
  {
    Header: 'Commessa',
    accessor: 'lot'
  },
  {
    Header: 'Tipo Macchina',
    accessor: 'data[2]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Matricola',
    accessor: 'data[3]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Ore Macchina',
    accessor: 'data[4]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Ore Mandrino',
    accessor: 'data[5]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Ore Pampa Vuoto',
    accessor: 'data[6]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Programma',
    accessor: 'data[8]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Numero Ripetizioni',
    accessor: 'data[9]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Evento',
    accessor: 'data[10]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Stato',
    accessor: 'data[11]',
    Cell: ({ value }) => <>{value || ""}</>
  },
];

export default denverCols
