import {Link} from "react-router-dom";
import React from "react";
import DateFormatDisplay from "../../../components/Custom/Form/Display/DateFormatDisplay";

export const archivedLotColumns = [
    {
        id: 'lot_number',
        Header: 'Lotto',
        accessor: 'lot_number',
        Cell: ({row, value}) => <Link className="fw-bold" to={row.original.uuid}>{value}</Link>
    }, {
        Header: 'Codice',
        accessor: 'item_code',
        Cell: ({row, value}) => <div className='flex flex-column text-nowrap'>
            <div className="fw-bold">{value}</div>
            <div>{row.original?.item_description}</div>
        </div>
    }, {
        Header: "Qtà",
        accessor: 'qty',
    }, {
        Header: "DX",
        accessor: 'dx_qty',
    }, {
        Header: "SX",
        accessor: 'sx_qty',
    }, {
        Header: "Casa",
        accessor: 'manufacturer',
    }, {
        Header: "Veicolo",
        accessor: 'vehicle',
    }, {
        Header: "Marchio",
        accessor: 'brand',
    }, {
        Header: "Colore",
        accessor: 'color',
    }, {
        Header: "Curvatura",
        accessor: 'curvature',
    }, {
        Header: "Spessore",
        accessor: 'thickness',
    }, {
        Header: "Emesso",
        accessor: 'created_by',
    }, {
        Header: "Inizio",
        accessor: 'start_date',
        Cell: ({value}) => <DateFormatDisplay value={value}/>
    }, {
        Header: "Consegna",
        accessor: 'forecast_date',
        Cell: ({value, row}) => <DateFormatDisplay value={value}/>
    }
];
