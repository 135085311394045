import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const ProduzioneDropdown = ({ user }) => {

  if (user.can_manage_production_module || user.can_manage_data_entry_module) {
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>Produzione</DropdownToggle>
        <DropdownMenu>

          {user.can_manage_production_module &&
            <DropdownItem href='/items'>
              <i className="bx bx-layer"/>&nbsp;Articoli
            </DropdownItem>}

          {(user.can_manage_production_module || user.can_manage_data_entry_module) &&
            <DropdownItem href='/lots'>
              <i className="bx bx-tag"/>&nbsp;Lotti
            </DropdownItem>}

          {user.can_manage_production_module &&
            <DropdownItem href='/system-tables'>
              <i className="bx bx-data"/>&nbsp;Tabelle di sistema
            </DropdownItem>}


          {user.can_manage_production_module &&
            <DropdownItem href='/archives'>
              <i className="bx bx-archive"/>&nbsp;Archivio
            </DropdownItem>}

        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  return (<></>)

}

export default ProduzioneDropdown
