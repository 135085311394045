import React from 'react';
import ReportsTableLabelCeil from './ReportsTableLabelCeil';
import ReactSwitch from 'react-switch';
import { get } from 'lodash';
import { FieldArray, useFormikContext } from 'formik';

const ReportCheckboxFormRows = ({ workConfig }) => {


  const { values, setFieldValue } = useFormikContext()

  return (
    <FieldArray name='control_reports' render={() => (
      <>
        {values.control_reports?.map((fc, index) => (
          <tr key={`checkbox_${index}`}>

            <ReportsTableLabelCeil
              index={index}
              workConfig={workConfig}
            />


            {Array(workConfig.checkbox_count).fill({}).map((obj, checkIndex) => (
              <td className='text-center' key={`check_container_${checkIndex}`}>
                <ReactSwitch
                  checked={get(values, `control_reports[${index}][${checkIndex}]`, false)}
                  onChange={status => setFieldValue(`control_reports[${index}][${checkIndex}]`, status)}
                />
              </td>
            ))}
          </tr>
        ))}
      </>
    )}
    />
  )

}
export default ReportCheckboxFormRows
