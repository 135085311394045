import { lotService } from "./lotService";
import api from '../helpers/axios';

const BASE_URL = '/lot-works';

const configs = () => api.get(BASE_URL).then(res => res.data);
const fetchAll = (lotId, filters) => api.get(`${lotService.BASE_URL}/${lotId}${BASE_URL}`, { params: filters }).then(res => res.data);
const fetch = (lotId, lotWorkId) => api.get(`${lotService.BASE_URL}/${lotId}${BASE_URL}/${lotWorkId}`).then(res => res.data);
const store = (lotId, attributes) => api.post(`${lotService.BASE_URL}/${lotId}${BASE_URL}`, attributes).then(res => res.data);
const destroy = (lotId, lotWorkId) => api.delete(`${lotService.BASE_URL}/${lotId}${BASE_URL}/${lotWorkId}`).then(res => res.data);

export const lotWorkService = {
  configs,
  fetchAll,
  fetch,
  store,
  destroy,
  BASE_URL
};
