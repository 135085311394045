import api from '../helpers/axios';

const BASE_URL = '/archived-lots';

const fetchAll = filters => api.get(BASE_URL, { params: filters }).then(res => res.data);
const fetch = id => api.get(BASE_URL + '/' + id).then(res => res.data);

export const archiveService = {
  fetchAll,
  fetch,
  BASE_URL
};
