import React from 'react'

const CreateButton = ({ children, disabled, handleClick }) =>
  <button
    disabled={disabled || false}
    onClick={handleClick}
    type="button"
    className='btn btn-info'
  >
    <i className="fa fa-plus me-1"/>&nbsp;
    {children || "Nuovo"}
  </button>

export default CreateButton
