import React from 'react'
import { FormDisplay } from "../../../../../../../../../components/Custom/Form/Display/FormDisplay";
import SerigrafiaLunottiReportsTable from '../SerigrafiaLunottiReportsTable';
import ReportCheckboxFormRows from '../../../components/ReportCheckboxShowRows';

const SerigrafiaLunottiFaseDueShow = ({ activity, workConfig }) => {

  return (
    <>
      <div className='row'>
        <div className='col-lg-4 col-md-6'>
          <FormDisplay label="Fornitore pasta argento">
            {activity?.activity_data?.silver_paste_supplier}
          </FormDisplay>
        </div>
        <div className='col-lg-4 col-md-6'>
          <FormDisplay label="Lotto pasta argento n°">
            {activity?.activity_data?.silver_paste_lot}
          </FormDisplay>
        </div>
        <div className='col-lg-4 col-md-6'>
          <FormDisplay label="Quantità pasta argento (grammi)">
            {activity?.activity_data?.silver_paste_qty}
          </FormDisplay>
        </div>
      </div>

      <SerigrafiaLunottiReportsTable>
        <ReportCheckboxFormRows activity={activity} workConfig={workConfig}/>
      </SerigrafiaLunottiReportsTable>
    </>
  )
}

export default SerigrafiaLunottiFaseDueShow
