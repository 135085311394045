import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import LotResult from '../LotResult';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import tableOptions from '../../../../../constants/tableOptions';
import malnatiCols from './malnatiCols';
import LotSearch from '../LotSearch';

const MalnatiShow = ({ toolConfig }) => {

  const [data, setData] = useState(undefined)

  return (
    <>
      <Row>
        <Col lg={3} md={6}>
          <LotSearch
            toolConfig={toolConfig}
            setData={setData}
          />
        </Col>
      </Row>

      {data ?
        <Card>
          <CardBody>
            <LotResult
              lot={data?.lot}
              job_count={data?.job_count}
              job_success={data?.job_success}
              start={data?.start}
              end={data?.end}
            />

            <h3>Dettaglio</h3>
            <PaginatedTable
              options={tableOptions}
              columns={malnatiCols}
              data={data.tool_jobs}
            />
          </CardBody>


        </Card>
        : <></>
      }
    </>
  )

}

export default MalnatiShow
