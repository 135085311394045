import React, {useEffect, useState} from 'react'
import {Form, Formik} from "formik";
import {customerForm} from "./customerForm";
import GenericOffCanvas from "../../../../components/Custom/UI/Canvas/GenericOffCanvas";
import {InputField} from "../../../../components/Custom/Form/InputField";
import GenericCanvasActions from "../../../../components/Custom/UI/Canvas/GenericCanvasActions";
import customerService from "../../../../services/customerService";
import FormGroup from "../../../../components/Custom/Form/FormGroup";
import DeleteButton from "../../../../components/Custom/Form/Buttons/DeleteButton";
import {toast} from "react-toastify";


const CustomerOffCanvas = ({isOpen, toggle, data, handleResponse}) => {

    const [initialValues, setInitialValues] = useState(customerForm.initialValues)
    const handleSubmit = (values) => data ? customerService.update(data.uuid, values) : customerService.store(values);

    const handleDelete = () => customerService.destroy(data.uuid)
        .then(res => {
            toast.success('Operazione eseguita con successo')
            handleResponse(undefined)
            toggle(false)
        })
        .catch(err => toast.error(err.response.data.message))

    useEffect(() => {
        data ? setInitialValues({
            business_name: data.business_name || '',
            note: data.note || '',
        }) : setInitialValues(customerForm.initialValues)
    }, [data]);


    return <GenericOffCanvas
        title={data ? 'Modifica ' + data.name : 'Crea Cliente'}
        isOpen={isOpen}
        toggle={toggle}>
        <Formik initialValues={initialValues}
                validationSchema={customerForm.validation}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                        .then(res => {
                            toast.success('Operazione eseguita con successo')
                            handleResponse(res)
                            toggle(false)
                        })
                        .catch(err => toast.error(err.response.data.message))
                        .finally(() => setSubmitting(false))
                }}>
            {() => (
                <Form>
                    <FormGroup name="business_name" label="Ragione Sociale" required={true} inline={false}>
                        <InputField name="business_name" type="text"/>
                    </FormGroup>
                    <FormGroup name="note" label="Note" required={false} inline={false}>
                        <InputField name="note" component="textarea" rows={6}/>
                    </FormGroup>
                    <GenericCanvasActions toggle={toggle}>
                        {data?.uuid &&
                            <DeleteButton handleClick={() => handleDelete()}/>
                        }
                    </GenericCanvasActions>
                </Form>
            )}
        </Formik>
    </GenericOffCanvas>

}

export default CustomerOffCanvas
