import React, {useEffect, useState} from 'react'
import {isUndefined} from 'lodash';
import {Form, Formik} from 'formik';
import {InputField} from '../../InputField';
import FormGroup from '../../FormGroup';
import itemCategoryService from '../../../../../services/itemCategoryService';
import GenericOffCanvas from '../../../UI/Canvas/GenericOffCanvas';
import GenericCanvasActions from '../../../UI/Canvas/GenericCanvasActions';
import {itemCategoryForm} from "./itemCategoryForm";
import {toast} from "react-toastify";

const ItemCategoryOffCanvas = ({isOpen, toggle, data, handleResponse}) => {

    const [initialValues, setInitialValues] = useState(itemCategoryForm.initialValues)
    const handleSubmit = (values) => data ? itemCategoryService.update(data.uuid, values) : itemCategoryService.store(values);

    useEffect(() => {
        data ? setInitialValues({
            name: data.name || '',
            code: data.code || '',
        }) : setInitialValues(itemCategoryForm.initialValues)
    }, [data]);

    return (
        <GenericOffCanvas title={data ? 'Modifica ' + data.name : 'Crea radice codice'}
                          isOpen={isOpen}
                          toggle={toggle}>
            <Formik initialValues={initialValues}
                    validationSchema={itemCategoryForm.validation}
                    enableReinitialize={true}
                    onSubmit={(values, {setSubmitting}) => {
                        handleSubmit(values)
                            .then(res => {
                                toast.success('Operazione eseguita con successo')
                                handleResponse(res)
                                toggle(false)
                            })
                            .catch(err => toast.error(err.response.data.message))
                            .finally(() => setSubmitting(false))

                    }}>
                {() => (<Form>
                    <FormGroup name='code' label='Codice' required={true} inline={false}>
                        <InputField disabled={!isUndefined(data)} name='code' type='number' min={1} max={999999}
                                    step={1}/>
                    </FormGroup>
                    <FormGroup name='name' label='Nome' required={true} inline={false}>
                        <InputField name='name' type='text' maxLength={100}/>
                    </FormGroup>
                    <GenericCanvasActions toggle={toggle}/>
                </Form>)}
            </Formik>
        </GenericOffCanvas>)

}


export default ItemCategoryOffCanvas