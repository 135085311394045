import React from 'react';
import { useFormikContext } from 'formik';
import { Spinner } from 'reactstrap';

const SubmitButton = () => {

  const { isSubmitting, isValid } = useFormikContext()

  return (
    <button
      type='submit'
      className='btn btn-info'
      disabled={isSubmitting || !isValid}
    >
      {isSubmitting
        ? <><Spinner size='sm'/>&nbsp;In Corso</>
        : <><i className='fa fa-save'/>&nbsp;Salva</>}
    </button>
  )

}

export default SubmitButton
