import React from 'react'
import ReportCheckboxFormRows from '../../components/ReportCheckboxShowRows';
import LavaggioReportsTable from './LavaggioReportsTable';

const LavaggioShow = ({ activity, workConfig }) => {


  return (
    <>
      <LavaggioReportsTable>
        <ReportCheckboxFormRows activity={activity} workConfig={workConfig}/>
      </LavaggioReportsTable>

    </>
  )
}

export default LavaggioShow
