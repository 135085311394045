import React from 'react'
import { FormDisplay } from "../../../../../../../../components/Custom/Form/Display/FormDisplay";
import DisplayBooleanOrUndefined from "../../../../../../../../components/Custom/Form/Display/DisplayBooleanOrUndefined";
import ReportCheckboxFormRows from '../../components/ReportCheckboxShowRows';
import MolaturaReportsTable from './MolaturaReportsTable';

const TaglioShow = ({ activity, workConfig }) => {

  return (
    <>
      <div className='row'>
        <div className='col-lg-4 col-md-6'>
          <FormDisplay label="Eseguito passaggio al nastro">
            <DisplayBooleanOrUndefined value={activity?.activity_data?.belt_passage}/>
          </FormDisplay>
        </div>
      </div>
      <MolaturaReportsTable>
        <ReportCheckboxFormRows activity={activity} workConfig={workConfig}/>
      </MolaturaReportsTable>
    </>
  )
}

export default TaglioShow
