import React from 'react';
import ToolConfigWrapper from '../../pages/Industry/ToolConfigs/ToolConfigWrapper';
import ToolConfigs from '../../pages/Industry/ToolConfigs/ToolConfigs';
import ToolConfig from '../../pages/Industry/ToolConfigs/ToolConfig';

const toolConfigsRoutes = {
  path: '/tool-configs/*',
  breadcrumb: "Configurazione macchine",
  element: <ToolConfigWrapper/>,
  children: [
    { path: '', element: <ToolConfigs/> },
    {
      path: ':id',
      breadcrumb: "Dettaglio",
      element: <ToolConfig/>
    }
  ]
}

export default toolConfigsRoutes
