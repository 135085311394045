import * as Yup from "yup";

const validation = Yup.object().shape({
    name: Yup.string().max(100, "Max 100 caratteri").required("Obbligatorio"),
    work_types: Yup.array().min(1, "Almeno una lavorazione").required("Obbligatorio"),
    description: Yup.string().max(255, "Max 255 caratteri").notRequired(),
    position: Yup.string().max(20, "Max 20 caratteri").notRequired(),
    container: Yup.string().max(20, "Max 20 caratteri").notRequired(),
    sample: Yup.string().max(20, "Max 20 caratteri").notRequired(),
    note: Yup.string().notRequired()
})

const initialValues = {
    name: '',
    work_types: [],
    description: '',
    position: '',
    container: '',
    sample: '',
    note: '',
}
export const templateForm = {
    validation,
    initialValues
}