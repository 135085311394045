import React from 'react';
import { useRequest } from 'ahooks';
import toolConfigService from '../../../services/toolConfigService';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FormDisplay } from '../../../components/Custom/Form/Display/FormDisplay';
import { Link } from 'react-router-dom';

const ToolConfigs = () => {

  const { data = [], loading } = useRequest(toolConfigService.fetchAll)


  return (
    <Row>
      {data.map(toolConfig =>
        <Col lg={4} md={6} key={toolConfig.uuid}>
          <Card>
            <CardBody>
              <Link to={toolConfig.uuid} className={'fs-5 fw-bold'}>{toolConfig.tool_name}</Link>
              <Row>
                <Col lg={4}>
                  <FormDisplay label="Produttore">{toolConfig.manufacturer}</FormDisplay>
                </Col>
                <Col lg={4}>
                  <FormDisplay label="S/N">{toolConfig.tool_sn}</FormDisplay>
                </Col>
                <Col lg={4}>
                  <FormDisplay label="Percorso">{toolConfig.path}</FormDisplay>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  )

}

export default ToolConfigs
