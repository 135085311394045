import React from 'react';
import { SweetAlert } from '../../../../../../helpers/SwalHelper';
import { lotService } from '../../../../../../services/lotService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const LotDestroy = ({ lot }) => {

  const navigate = useNavigate()

  const handleConfirm = () => {
    SweetAlert.fire({
      text: `Sei sicuro di voler elimnare il lotto ${lot?.lot_number}? Verranno persi tutti i dati delle lavorazioni e delle attività senza possibilità di recupero. Continuare?`,
      icon: "error"
    }).then(response => {
      if (response.isConfirmed) {
        handleReconfirm()
      }
    })
  }

  const handleReconfirm = () => {
    SweetAlert.fire({
      text: `Procedere con la cancellazione del lotto ${lot?.lot_number}?`,
      icon: "warning"
    }).then(response => {
      if (response.isConfirmed) {
        lotService.destroy(lot.uuid)
        .then(() => {
          toast.success('Operazione eseguita con successo')
          navigate('/lots')
        })
        .catch(err => toast.error(err?.response?.data?.message || "Errore"))
      }
    })
  }

  return (
    <button
      onClick={handleConfirm}
      className='text-nowrap btn btn-danger btn-icon'
      type='button'>
      <i className="fa fa-times"/>
    </button>
  )

}

export default LotDestroy
