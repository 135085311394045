import * as Yup from "yup";

const editInitialValues = {
  description: '',
  manufacturer_id: '',
  vehicle_id: '',
  brand_id: '',
  color_id: '',
  curvature_id: '',
  thickness_id: '',
  note: '',
}

const createInitialValues = {
  item_category_code: '',
  code: '',
  item_category_id: '',
  ...editInitialValues
}

const createValidation = Yup.object().shape({
  item_category_id: Yup.number().required("Obbligatorio"),
  description: Yup.string().max(255, "Max 255 caratteri").required("Obbligatorio"),
  note: Yup.string().notRequired(),
  code: Yup.string().min(0, "Min 0").max(500, "Max 1000").required("Obbligatorio")
});

const editValidation = Yup.object().shape({
  description: Yup.string().max(255, "Max 255 caratteri").required("Obbligatorio"),
  note: Yup.string().notRequired(),
});

const setEditInitialValues = (item) => {
  return {
    description: item?.description,
    manufacturer_id: item.manufacturer?.id || '',
    vehicle_id: item.vehicle?.id || '',
    brand_id: item.brand?.id || '',
    color_id: item.color?.id || '',
    curvature_id: item.curvature?.id || '',
    thickness_id: item.thickness?.id || '',
    note: item.note || '',
  }
}


const itemFormConfigs = {
  createInitialValues,
  createValidation,
  editInitialValues,
  editValidation,
  setEditInitialValues
}

export default itemFormConfigs
