import React, { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import tableOptions from '../../../../../constants/tableOptions';
import denverCols from './denverCols';
import { useParams } from 'react-router-dom';
import DenverAction from './DenverAction';
import LotSearch from '../LotSearch';
import LotResult from '../LotResult';

const DenverShow = ({ toolConfig }) => {

  const { id } = useParams()

  const [data, setData] = useState(undefined)

  return (
    <>
      <Row>
        <Col lg={3} md={6}>
          <LotSearch
            toolConfig={toolConfig}
            setData={setData}
          />
        </Col>
        <Col lg={9} md={6}>
          <DenverAction toolUuid={toolConfig?.uuid}/>
        </Col>
      </Row>


      {data ?
        <Card>
          <CardBody>
            <LotResult
              lot={data?.lot}
              job_count={data?.job_count}
              job_success={data?.job_success}
              start={data?.start}
              end={data?.end}
            />

            <h3>Dettaglio</h3>
            <PaginatedTable
              options={tableOptions}
              columns={denverCols}
              data={data.tool_jobs}
            />
          </CardBody>
        </Card>
        : <></>
      }
    </>
  )

}

export default DenverShow
