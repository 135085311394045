import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from "use-react-router-breadcrumbs";
import appRoutes from '../../../routes';


function Breadcrumbs() {

  const breadcrumbs = useBreadcrumbs(appRoutes);

  return (
    <ol className='breadcrumb px-0 sticky-top mx-0 bg-body d-print-none' style={{ top: "55px" }}>
      {breadcrumbs.map(({ match, breadcrumb }, index, row) =>
        index + 1 === row.length
          ? <li className='breadcrumb-item' key={match.pathname}>{breadcrumb.props.children ?? ""}</li>
          : <Link className='breadcrumb-item' to={match.pathname} key={match.pathname}>
            {breadcrumb.props.children ?? ""}
          </Link>
      )}
    </ol>
  );
}

export default Breadcrumbs;
