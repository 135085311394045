import React from 'react'
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Nav, NavItem, NavLink} from "reactstrap";
import classnames from "classnames";
import CheckUserModule from '../../../components/Custom/Permissions/CheckUserModule';

const SystemTables = () => {

    const {pathname} = useLocation()
    const navigate = useNavigate()

    const tabs = [
        {label: 'Dime/Telai', path: 'templates'},
        {label: 'Programmi', path: 'programs'},
        {label: 'Strumenti', path: 'tools'},
        {label: 'Clienti', path: 'customers'},
    ]

    return <CheckUserModule module={['can_manage_production_module']}>

        <Nav tabs>
            {tabs.map((tab, index) =>
                <NavItem key={index}>
                    <NavLink className={classnames({active: pathname.includes(tab.path)})}
                             onClick={() => navigate(tab.path)}>
                        {tab.label}
                    </NavLink>
                </NavItem>
            )}
        </Nav>

        <Outlet/>

    </CheckUserModule>


}

export default SystemTables
