import React from 'react'
import UsersWrapper from "../../pages/Administration/Users/Users/UsersWrapper";
import Users from "../../pages/Administration/Users/Users/Users";

const usersRoutes = {
  path: '/users/*',
  breadcrumb: "Lista Utenti",
  element: <UsersWrapper/>,
  children: [
    {
      path: '',
      breadcrumb: "Utente",
      element: <Users/>
    }
  ]
}

export default usersRoutes
