import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useRequest } from 'ahooks';
import userService from '../../../../services/userService';
import { FormSelect } from '../../../../components/Custom/Form/FormSelect';
import { InputField } from '../../../../components/Custom/Form/InputField';
import { WorkTypeArray } from '../../../../constants/enums/WorkTypesEnum';
import SearchButton from '../../../../components/Custom/Form/Buttons/SearchButton';

export const ActivitiesCheckerFilters = ({ handleUpdateActivities }) => {

  const { data: users = [] } = useRequest(userService.selectable)

  const formik = useFormik({
    initialValues: {
      checked: false,
      user_id: '',
      work_type: '',
      lot_number: ''
    },
    onSubmit: (values, { setSubmitting }) => {
      handleUpdateActivities({ filter: { ...values } })
      setSubmitting(false)
    }
  })

  return (
    <div className='card card-body'>
      <FormikProvider value={formik}>
        <Form className='d-flex flex-column gap-2'>
          <div className='row'>
            <div className="col-lg-3">
              <FormSelect
                name="user_id"
                placeholder="Seleziona un utente"
                options={users}
              />
            </div>
            <div className="col-lg-3">
              <InputField name='lot_number' placeholder='Numero Lotto'/>
            </div>
            <div className="col-lg-3">
              <FormSelect
                name='work_type'
                placeholder='Seleziona una lavorazione'
                options={WorkTypeArray}
              />
            </div>
            <div className="col-lg-3 d-flex justify-content-end gap-4 align-items-center">
              <SearchButton />
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  )

}
