import React from 'react'
import WorkTypeBadge from "./WorkTypeBadge";

const WorkTypeList = ({workTypes}) => {

    return <>
        {workTypes.map((workType, index) => <WorkTypeBadge workType={workType} key={index} />)}
    </>

}

export default WorkTypeList