import React, {useEffect, useState} from 'react'
import {useRequest} from "ahooks";
import {FormSelect} from "../../FormSelect";
import itemCategoryService from "../../../../../services/itemCategoryService";
import ItemCategoryService from "../../../../../services/itemCategoryService";
import {useFormikContext} from "formik";
import CanvasCreateEditButtons from "../../../UI/Canvas/CanvasCreateEditButtons";
import ItemCategoryOffCanvas from "./ItemCategoryOffCanvas";
import {toast} from "react-toastify";

const ItemCategoryPicker = ({onSelected, name}) => {

    const {data, loading, runAsync} = useRequest(itemCategoryService.selectable);

    const [currentSelected, setCurrentSelected] = useState(undefined);
    const {values, setFieldValue} = useFormikContext()
    const [isCanvasOpen, setIsCanvasOpen] = useState(false);

    useEffect(() => {
        if (loading)
            return;

        if (values[name]) {
            const option = data.find(d => d.value === values[name])
            option
                ? ItemCategoryService.fetch(option.uuid).then(res => setCurrentSelected(res))
                : setCurrentSelected(undefined)
        } else {
            setCurrentSelected(undefined)
        }

    }, [data, values[name]])

    const handleResponse = (value) => {
        runAsync().then(() => {
            onSelected({
                value: value.id,
                label: value.name,
                name: value.name
            })
            setIsCanvasOpen(false)
        })
    }

    const handleDelete = () => {
        ItemCategoryService.destroy(currentSelected.uuid)
            .then(res => runAsync()
                .then(() => {
                    setFieldValue(name, undefined)
                    toast.success('Operazione eseguita con successo')
                })
            ).catch(err => toast.error(err.response.data.message))
    }


    return (
        <React.Fragment>
            <FormSelect
                name={name}
                onChange={(category) => onSelected(data.find(d => d.value === category))}
                loading={loading}
                placeholder="Seleziona una categoria"
                options={data}
                multiple={false}
            />

            <CanvasCreateEditButtons
                value={values[name]}
                canvasOpen={isCanvasOpen}
                setCanvasOpen={setIsCanvasOpen}
                handleDelete={handleDelete}
            />

            <ItemCategoryOffCanvas
                isOpen={isCanvasOpen}
                toggle={setIsCanvasOpen}
                data={currentSelected}
                handleResponse={handleResponse}
            />

        </React.Fragment>
    )
}

export default ItemCategoryPicker