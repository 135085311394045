import React from 'react'

const EditButton = ({ disabled, handleClick }) =>
  <button
    className="btn btn-warning"
    disabled={disabled || false}
    onClick={handleClick}
    type="button">
    <i className="fa fa-pen me-1"/>&nbsp;Modifica
  </button>

export default EditButton
