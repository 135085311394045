import React, { useState } from "react"
import logo from "../../../assets/images/logo-dark.png"
import { useSanctum } from 'react-sanctum';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import ProduzioneDropdown from './ProduzioneDropdown';
import AmministrazioneDropdown from './AmministrazioneDropdown';
import Industria40Dropdown from './Industria40Dropdown';
import ProfileDropdown from './ProfileDropdown';
import { Link } from 'react-router-dom';

const Header = () => {

  const { user } = useSanctum()
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <header>
      <Navbar expand='md' color='light' fixed='top' className='d-print-none'>
          <Link to={'/'} className='navbar-brand'>
            <img src={logo} alt='Sekurglass' height="30" className="d-inline-block align-text-top"/>
          </Link>
        <NavbarToggler onClick={toggle}/>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            {user.can_manage_data_entry_module &&
            !user.can_manage_administration_module &&
            !user.can_manage_production_module &&
            !user.can_manage_industry_module
              ? <></>
              : <NavItem><NavLink href="/">Dashboard</NavLink></NavItem>
            }

            <ProduzioneDropdown user={user}/>
            <AmministrazioneDropdown user={user}/>
            <Industria40Dropdown user={user}/>
          </Nav>
          <Nav navbar>
            <ProfileDropdown/>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  )
}

export default Header
