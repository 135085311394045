import * as Yup from "yup";
import { find, get } from 'lodash';

const validation = Yup.object().shape({
  item_works: Yup.array().required()
});

const initialValues = {
  item_works: []
};

const setInitialValues = (lotWorkConfigs = [], itemWorks = []) => ({
  item_works: lotWorkConfigs.map(lotWorkConfig => {
    const configuredItemWork = itemWorks.find(iw => iw.work_type === lotWorkConfig.value)
    return {
      uuid: get(configuredItemWork, 'uuid', undefined),
      work_type: lotWorkConfig.value,
      work_type_name: lotWorkConfig.label,
      selected: configuredItemWork?.selected ?? false,
      template_id: get(configuredItemWork, 'template.id', undefined),
      program_id: get(configuredItemWork, 'program.id', undefined),
      extra_data: lotWorkConfig.extra_data.map(et => ({
        name: et.name,
        label: et.label,
        value: find(get(configuredItemWork, 'extra_data'), congExtraData => congExtraData.name === et.name)?.value,
        options: et.values
      })),
      has_template: lotWorkConfig.has_template,
      has_program: lotWorkConfig.has_program,
    }
  })
})

const itemWorkFormConfigs = {
  validation,
  initialValues,
  setInitialValues
}

export default itemWorkFormConfigs
