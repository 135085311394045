import React, {useState} from "react"
import {Formik} from "formik";
import {Link, useNavigate} from "react-router-dom"
import * as Yup from "yup"
import {InputField} from "../../components/Custom/Form/InputField";
import FormGroup from "../../components/Custom/Form/FormGroup";
import authService from "../../services/authService";
import {Alert} from "reactstrap";

const ForgetPasswordPage = props => {

    const navigate = useNavigate()

    const initialValues = {
        email: ""
    };

    const validation = Yup.object().shape({
        email: Yup.string().email().required("Email obbligatoria")
    });

    const [message, setMessage] = useState(undefined);

    return (<>

        <h3>Password dimenticata?</h3>
        <p>Inserisci l&apos;indirizzo email dell&apos;account che vuoi recuperare.</p>

        {message && <Alert color={message.color}>{message.message}</Alert>}

        <Formik initialValues={initialValues}
                validationSchema={validation}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    setMessage(undefined)
                    authService.forgotPassword(values.email)
                        .then(res => {
                            setMessage({
                                color: 'success',
                                message: res.data.message
                            })
                            resetForm()
                        })
                        .catch(err => setMessage({
                            color: 'danger',
                            message: err.response.data.message
                        }))
                        .finally(() => setSubmitting(false))
                }}>
            {({isSubmitting, handleSubmit, isValid, values}) => (
                <form onSubmit={handleSubmit} className="form-horizontal">
                    <FormGroup name="email" label="Email" required={true}
                               inline={false}>
                        <InputField name="email" type="text"/>
                    </FormGroup>

                    <div className="d-flex align-content-center justify-content-between">
                        <Link to="/login" type="button" className="text-muted align-self-center">
                            Annulla
                        </Link>
                        <button disabled={isSubmitting || !isValid} className="btn btn-primary btn-block" type="submit">
                            Invia Link
                        </button>
                    </div>

                </form>
            )}
        </Formik>
    </>)
}


export default ForgetPasswordPage
