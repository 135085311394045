import React, { useEffect } from 'react';
import { useRequest } from 'ahooks';
import { activityService } from '../../../services/ActivityService';
import { Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';

const ActivityCheckerWidget = () => {

  const { data: activities = [], run, loading } = useRequest(activityService.fetchFromDB, { manual: true })
  const handleRun = () => run({ filter: { checked: false } })


  useEffect(() => handleRun(), [])

  return (
    <div className='alert alert-light'>
      {loading
        ? <div>
          <Spinner size='sm' className='me-1'/>
          Controllo attività in corso
        </div>
        : <div>
          {activities.length > 0
            ? (
              <div className='text-danger'>
                <i className='fa fa-exclamation-circle me-1'/>
                Ci sono {activities.length} attività da controllare!&nbsp;
                <Link className='fw-bold text-danger' to={'activities-checker'}>
                  Clicca qui
                </Link>
              </div>
            ) : (
              <div className='text-success'>
                <i className='fa fa-check me-1'/>
                Non ci sono attività da controllare!
              </div>
            )
          }
          <button
            type='button'
            onClick={handleRun}
            className='btn btn-link text-muted btn-sm'>
            Aggiorna
          </button>
        </div>
      }
    </div>
  )

}


export default ActivityCheckerWidget
