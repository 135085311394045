import React, {useEffect, useState} from 'react'
import {useRequest} from "ahooks";
import manufacturerService from "../../../../../services/manufacturerService";
import {useFormikContext} from "formik";
import _ from 'lodash'
import {FormSelect} from "../../FormSelect";
import CanvasCreateEditButtons from "../../../UI/Canvas/CanvasCreateEditButtons";
import ManufacturerOffCanvas from "./ManufacturerOffCanvas";
import {toast} from "react-toastify";

const ManufacturerPicker = ({name, disabled, onSelected, withCreate, required}) => {

    const {data, loading, runAsync} = useRequest(manufacturerService.selectable);

    const [currentSelected, setCurrentSelected] = useState(undefined);
    const {values, setFieldValue} = useFormikContext()
    const [isCanvasOpen, setIsCanvasOpen] = useState(false);

    useEffect(() => {
        if (loading)
            return;

        if (_.get(values, name)) {
            const option = data.find(d => d.value === _.get(values, name))
            option
                ? manufacturerService.fetch(option.uuid).then(res => setCurrentSelected(res))
                : setCurrentSelected(undefined)
        } else {
            setCurrentSelected(undefined)
        }

    }, [data, _.get(values, name)])

    const handleResponse = (value) => {
        runAsync().then(() => {
            onSelected({
                value: value.id,
                label: value.name
            })
            setIsCanvasOpen(false)
        })
    }

    const handleDelete = () => {
        manufacturerService.destroy(currentSelected.uuid)
            .then(res => runAsync()
                .then(() => {
                    setFieldValue(name, undefined)
                    toast.success('Operazione eseguita con successo')
                })
            ).catch(err => toast.error(err.response.data.message))
    }

    return (
        <React.Fragment>
            <FormSelect
                disabled={disabled}
                name={name}
                onChange={(manufacturer) => onSelected(data.find(d => d.value === manufacturer))}
                loading={loading}
                placeholder="Seleziona una casa automobilistica"
                options={data}
                multiple={false}
            />
            {withCreate &&
                <CanvasCreateEditButtons
                    value={_.get(values, name)}
                    canvasOpen={isCanvasOpen}
                    setCanvasOpen={setIsCanvasOpen}
                    handleDelete={handleDelete}
                />
            }

            {withCreate &&
                <ManufacturerOffCanvas
                    isOpen={isCanvasOpen}
                    toggle={setIsCanvasOpen}
                    data={currentSelected}
                    handleResponse={handleResponse}
                />
            }

        </React.Fragment>
    )
}

export default ManufacturerPicker