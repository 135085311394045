import React, { useCallback, useEffect } from "react";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { activityService } from "../../../../../../../../services/ActivityService";
import { toast } from "react-toastify";
import { useRequest } from "ahooks";
import { SweetAlert } from "../../../../../../../../helpers/SwalHelper";

const ActivityFileUploader = ({ activity, lot, lotWork }) => {

  const { data = [], run, loading = true, mutate } = useRequest(activityService.fetchAllMedia, { manual: true });

  useEffect(() => {
    if (activity && lot && lotWork) {
      run(lot.uuid, lotWork.uuid, activity.uuid)
    }
  }, [activity, lot, lotWork]);


  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(file => {
      activityService.addMedia(lot.uuid, lotWork.uuid, activity.uuid, file)
      .then(res => {
        mutate(d => [...d, res])
        toast.success(res.name + ' caricato con successo');
      })
      .catch(err => toast.error(file.name + ' ' + err?.response?.data?.message))
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const handleConfirm = (media) => {
    SweetAlert.fire({
      text: `Sei sicuro di voler eliminare il file ${media.name}?`, icon: "warning"
    }).then(response => {
      if (response.isConfirmed) {
        activityService.deleteMedia(lot.uuid, lotWork.uuid, activity.uuid, media.uuid)
        .then(res => {
          mutate(data.filter(d => d.uuid !== media.uuid))
          toast.success(media.name + ' eliminato con successo');
        }).catch(err => toast.error(err?.response?.data?.message))
      }
    })
  }

  const handleDownload = (media) => activityService.downloadMedia(lot?.uuid, lotWork?.uuid, activity?.uuid, media?.uuid, media.file_name)

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone d-flex bg-light justify-content-center align-items-center' })}
           style={{ minHeight: "150px" }}>
        <input {...getInputProps()} disabled={loading}/>
        <span className="fw-bold fs-6 text-muted"><i
          className="fa fa-upload me-1"/>{loading ? "Caricamento..." : "Trascina i file"}</span>
      </div>

      {!loading && <ListGroup flush={true}>
        {data.length > 0
          ? data.map((media, index) =>
            <ListGroupItem key={index} className="p-1 d-flex justify-content-between align-items-center">
              <div className='d-flex justify-content-between align-items-center gap-2'>
                <i className="fa fa-file-pdf me-1 text-danger fa-lg"/>
                <div>
                  <div className="link-primary pointer fw-bold" onClick={() => handleDownload(media)}>
                    {media.name}
                  </div>
                  <span className="text-muted">{media.size}</span>
                </div>
              </div>
              <Button type="button" color="link" onClick={() => handleConfirm(media)}>
                <span className="text-danger"><i className='fa fa-trash me-1'/> Elimina file</span>
              </Button>
            </ListGroupItem>
          )
          : <ListGroupItem>Nessun file caricato</ListGroupItem>}
      </ListGroup>}

    </div>
  )
}

export default ActivityFileUploader
