import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { PaginatedTable } from "@dvsoftsrl/react-laravel-paginated-table";
import tableOptions from "../../../../constants/tableOptions";
import { useRequest } from "ahooks";
import userService from "../../../../services/userService";
import CreateButton from "../../../../components/Custom/Form/Buttons/CreateButton";
import UserOffCanvas from "../UserOffCanvas";
import UserPasswordOffCanvas from "../UserPasswordOffCanvas";
import { toast } from 'react-toastify';

const Users = () => {

  const { data, loading, run } = useRequest(userService.fetchAll)
  const [toggleCanvas, setToggleCanvas] = useState(false);
  const [togglePasswordCanvas, setTogglePasswordCanvas] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [selectedPasswordUser, setSelectedPasswordUser] = useState(undefined);

  const handleUserCreatedOrUpdated = (user) => {
    if (!selectedUser) {
      toast.success('Operazione eseguita con successo')
    }
    run()
  }

  const handleUserDeleted = () => {
    setSelectedUser(() => undefined)
    toast.success('Operazione eseguita con successo')
    run()
  }

  const handleUserPasswordUpdated = () => {
    setSelectedPasswordUser(() => undefined)
    toast.success('Operazione eseguita con successo')
  }

  useEffect(() => {
    if (selectedUser) {
      setToggleCanvas(true)
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!toggleCanvas) {
      setSelectedUser(undefined)
    }
  }, [toggleCanvas]);


  useEffect(() => {
    if (selectedPasswordUser) {
      setTogglePasswordCanvas(true)
    }
  }, [selectedPasswordUser]);

  useEffect(() => {
    if (!togglePasswordCanvas) {
      setSelectedPasswordUser(undefined)
    }
  }, [togglePasswordCanvas]);


  const columns = React.useMemo(() => [
    {
      id: 'username', Header: 'Nome Utente', accessor: 'username', Cell: ({ value, row }) =>
        <button type='button' className='btn btn-link p-1 fw-bold'
                onClick={() => setSelectedUser(row.original)}>
          {value}
        </button>
    },
    { Header: 'Nome & Cognome', disableSortBy: true, accessor: 'full_name' },
    { Header: 'Email', disableSortBy: false, accessor: 'email' },
    { Header: "Produzione", disableSortBy: true, accessor: 'can_manage_production_module', Cell: ({ value, row }) => value ? 'SI' : 'NO' },
    { Header: "Amministrazione", disableSortBy: true, accessor: 'can_manage_administration_module', Cell: ({ value, row }) => value ? 'SI' : 'NO' },
    { Header: "Industria4.0", disableSortBy: true, accessor: 'can_manage_industry_module', Cell: ({ value, row }) => value ? 'SI' : 'NO' },
    { Header: "Tempi", disableSortBy: true, accessor: 'can_manage_data_entry_module', Cell: ({ value, row }) => value ? 'SI' : 'NO' },
    {
      Header: "Azioni", disableSortBy: true, Cell: ({ row }) =>
        <button type='button' className='btn btn-info btn-icon btn-sm'
                onClick={() => setSelectedPasswordUser(row.original)}>
          <i className='fa fa-lock'/> Password
        </button>
    }
  ], [])

  return (<>
    <Card>
      <CardBody>
        <PaginatedTable
          options={tableOptions}
          TableActions={
            <CreateButton handleClick={() => setToggleCanvas(!toggleCanvas)}>Nuovo utente</CreateButton>
          }
          columns={columns}
          data={data ?? []}
          isLoading={loading}
          filterable
          sortable
          onParamsChange={run}
        />
      </CardBody>
    </Card>

    <UserOffCanvas
      isOpen={toggleCanvas}
      handleResponse={handleUserCreatedOrUpdated}
      data={selectedUser}
      toggle={() => setToggleCanvas(false)}
      onDelete={handleUserDeleted}
    />

    <UserPasswordOffCanvas
      isOpen={togglePasswordCanvas}
      handleResponse={() => handleUserPasswordUpdated()}
      data={selectedPasswordUser}
      toggle={() => setTogglePasswordCanvas(false)}
    />

  </>);

}

export default Users;

