import React from 'react';
import { Card, CardBody, Col, InputGroup, InputGroupText, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import toolConfigService from '../../../../../services/toolConfigService';
import * as Yup from 'yup';
import { InputField } from '../../../../../components/Custom/Form/InputField';
import FormGroup from '../../../../../components/Custom/Form/FormGroup';
import LotPicker from '../../../../../components/Custom/Form/Pickers/LotPicker/LotPicker';
import { LotStatesEnum } from '../../../../../constants/enums/LosStatesEnum';
import { FileListReader } from '../FileListReader/FileListReader';

const ForvetAction = ({ toolUuid }) => {

  const initialValues = {
    file_name: '',
    lot_number: '',
    qty: 0
  }
  const validation = Yup.object().shape({
    file_name: Yup.string().required(),
    lot_number: Yup.string().required(),
    qty: Yup.number().min(1).required()
  })


  return (
    <Card>
      <CardBody>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          enableReinitialize={true}
          onSubmit={(values, { resetForm, setSubmitting }) => toolConfigService.execute(toolUuid, values)
          .then(res => {
            toast.success('Lavorazione inviata con successo')
            resetForm()
          })
          .catch(err => toast.error(err.response.data.message))
          .finally(() => setSubmitting(false))
          }>

          {({ isSubmitting, isValid, setFieldValue }) => (
            <Form>
              <Row>
                <Col lg={4}>

                  <FileListReader toolUuid={toolUuid}/>

                </Col>
                <Col lg={4}>
                  <FormGroup label="Numero lotto"
                             name="lot_number"
                             required={true}
                             inline={false}>
                    <LotPicker
                      handleChange={v => setFieldValue("lot_number", v?.value ?? '')}
                      states={[LotStatesEnum.in_progress]}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4}>
                  <FormGroup label="Quantità d produrre"
                             name="qty"
                             required={true}
                             inline={false}>
                    <InputField type='number' min={0} name="qty"/>
                  </FormGroup>
                </Col>
              </Row>
              <div className='d-flex justify-content-end align-items-center'>
                <button type="submit"
                        disabled={isSubmitting || !isValid}
                        className="btn btn-sm btn-primary ">
                  <i className='fa fa-paper-plane me-1'/>
                  Invia lavorazione
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

export default ForvetAction
