import React from "react";
import DateFormatDisplay from "../../../../components/Custom/Form/Display/DateFormatDisplay";
import {NumberDisplay} from "../../../../components/Custom/Form/Display/NumberDisplay";

const ArchivedLotEntry = ({entry}) => {


    return (
        <tr>
            <td><DateFormatDisplay value={entry.shipment_date}/></td>
            <td><NumberDisplay value={entry.qty} suffix={'pz'}/></td>
            <td><NumberDisplay value={entry.dx_qty} suffix={'pz'}/></td>
            <td><NumberDisplay value={entry.sx_qty} suffix={'pz'}/></td>
        </tr>
    )

}

export default ArchivedLotEntry
