import React from "react";
import { FormCheckbox } from "../../../../../components/Custom/Form/FormCheckbox";
import FormGroup from "../../../../../components/Custom/Form/FormGroup";
import { FormSelect } from "../../../../../components/Custom/Form/FormSelect";
import { useFormikContext } from "formik";

const ItemWorkForm = ({ work, templates, programs, index }) => {

  const { values } = useFormikContext()

  return (
    <div className='card card-body'>
      <h5><FormCheckbox name={`item_works[${index}].selected`} label={`${index + 1} - ${work.work_type_name}`}/></h5>
      {work.has_template &&
        <FormGroup label="Dima/Telaio" required={false} name="template_id">
          <FormSelect
            name={`item_works[${index}].template_id`}
            placeholder="Seleziona una dima/telaio"
            disabled={!values.item_works[index].selected}
            options={(templates || []).filter(t => t.work_types.includes(work.work_type))}
            multiple={false}
          />
        </FormGroup>}

      {work.has_program &&
        <FormGroup label="Programma" required={false} name="program_id">
          <FormSelect
            name={`item_works[${index}].program_id`}
            placeholder="Seleziona un programma"
            disabled={!values.item_works[index].selected}
            options={(programs || []).filter(t => t.work_types.includes(work.work_type))}
            multiple={false}
          />
        </FormGroup>}

      {work.extra_data.length > 0 && work.extra_data.map((extraData, extraDataIndex) =>
        <div key={`${work.work_type}_${extraData.value}`}>
          <FormGroup
            label={extraData.label}
            name={`item_works[${index}].extra_data[${extraDataIndex}].value`}
            required={false}>
            <FormSelect
              name={`item_works[${index}].extra_data[${extraDataIndex}].value`}
              placeholder={`Seleziona ${extraData.label}`}
              disabled={!values.item_works[index].selected}
              options={extraData.options}
              multiple={false}
            />
          </FormGroup>
        </div>
      )}

    </div>
  )

}

export default ItemWorkForm
