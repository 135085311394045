import { format, parseJSON } from 'date-fns';
import { get, map, merge, times } from 'lodash';
import { parseBool } from './activitiesHelper';

const toHoursAndMinutes = totalMinutes => {
  return `${padToTwoDigits(Math.floor(totalMinutes / 60))}:${padToTwoDigits(totalMinutes % 60)}`;
}

const padToTwoDigits = num => num.toString().padStart(2, '0');

export const setActivityInitialValues = (activity = undefined) => {

  return {
    date: activity?.date ? format(parseJSON(activity.date), 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
    hour_amount: toHoursAndMinutes(activity?.hour_amount ?? 0),
    stop_hour_amount: toHoursAndMinutes(activity?.stop_hour_amount ?? 0),
    stop_cause: activity?.stop_cause ?? '',
    note: activity?.note ?? '',

    manufactured_qty: activity?.manufactured_qty ?? 0,
    wasted_qty: activity?.wasted_qty ?? 0,
    rework_qty: activity?.rework_qty ?? 0,
    to_be_reworked_qty: activity?.to_be_reworked_qty ?? 0,

    manufactured_dx_qty: activity?.manufactured_dx_qty ?? 0,
    wasted_dx_qty: activity?.wasted_dx_qty ?? 0,
    rework_dx_qty: activity?.rework_dx_qty ?? 0,
    to_be_reworked_dx_qty: activity?.to_be_reworked_dx_qty ?? 0,

    manufactured_sx_qty: activity?.manufactured_sx_qty ?? 0,
    wasted_sx_qty: activity?.wasted_sx_qty ?? 0,
    rework_sx_qty: activity?.rework_sx_qty ?? 0,
    to_be_reworked_sx_qty: activity?.to_be_reworked_sx_qty ?? 0,

    control_reports: activity?.control_reports ?? []

  }
}


// ############################################################
// TAGLIO
// ############################################################

export const setTaglioInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    slab_manufacturer: activity?.activity_data?.slab_manufacturer ?? '',
    c_lot_number: activity?.activity_data?.c_lot_number ?? '',
    slab_number: activity?.activity_data?.slab_number ?? '',
    slab_dimensions: activity?.activity_data?.slab_dimensions ?? '',
    primary_dimensions: activity?.activity_data?.primary_dimensions ?? '',
    operator: merge(map(activity?.activity_data?.operator, 'id'), [user?.id])
  }
})

// ############################################################
// MOLATURA
// ############################################################

export const setMolaturaInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    belt_passage: get(activity, 'activity_data.belt_passage', false),
    operator: merge(map(activity?.activity_data?.operator, 'id'), [user?.id])
  }
})

// ############################################################
// FORATURA E LAVAGGIO FASE 1
// ############################################################

export const setForaturaELavaggioFaseUnoInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    load_operator: map(activity?.activity_data?.load_operator, 'id'),
    unload_operator: map(activity?.activity_data?.unload_operator, 'id')
  }
})

// ############################################################
// FORATURA E LAVAGGIO FASE 2
// ############################################################

export const setForaturaELavaggioFaseDueInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    load_operator: map(activity?.activity_data?.load_operator, 'id'),
    unload_operator: map(activity?.activity_data?.unload_operator, 'id')
  }
})


// ############################################################
// LAVAGGIO
// ############################################################

export const setLavaggioInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    load_operator: map(activity?.activity_data?.load_operator, 'id'),
    unload_operator: map(activity?.activity_data?.unload_operator, 'id')
  }
})

// ############################################################
// IMBALLO DOPO LAVAGGIO
// ############################################################

export const setImballoDopoLavaggioInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    operator: merge(map(activity?.activity_data?.operator, 'id'), [user?.id])
  }
})

// ############################################################
// SABBIATURA
// ############################################################

export const setSabbiaturaInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    operator: merge(map(activity?.activity_data?.operator, 'id'), [user?.id])
  }
})

// ############################################################
// LAVAGGIO DOPO SABBIATURA
// ############################################################

export const setLavaggioDppoSabbiaturaInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    load_operator: map(activity?.activity_data?.load_operator, 'id'),
    unload_operator: map(activity?.activity_data?.unload_operator, 'id')
  }
})

// ############################################################
// SERIGRAFIA LATERALI
// ############################################################

export const setSerigrafiaLateraliInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    black_ink_supplier: activity?.activity_data?.black_ink_supplier ?? '',
    black_ink_lot: activity?.activity_data?.black_ink_lot ?? '',
    load_operator: map(activity?.activity_data?.load_operator, 'id'),
    unload_operator: map(activity?.activity_data?.unload_operator, 'id')
  }
})

// ############################################################
// CONTROLLO FACCIATA
// ############################################################

export const setControlloFacciataInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    operator: merge(map(activity?.activity_data?.operator, 'id'), [user?.id])
  }
})

// ############################################################
// SERIGRAFIA LUNOTTI FASE UNO
// ############################################################

export const setSerigrafiaLunottiFaseUnoInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    black_ink_supplier: activity?.activity_data?.black_ink_supplier ?? '',
    black_ink_lot: activity?.activity_data?.black_ink_lot ?? '',
    load_operator: map(activity?.activity_data?.load_operator, 'id'),
    unload_operator: map(activity?.activity_data?.unload_operator, 'id')
  }
})

// ############################################################
// SERIGRAFIA LUNOTTI FASE DUE
// ############################################################

export const setSerigrafiaLunottiFaseDueInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    silver_paste_supplier: activity?.activity_data?.silver_paste_supplier ?? '',
    silver_paste_lot: activity?.activity_data?.silver_paste_lot ?? '',
    silver_paste_qty: activity?.activity_data?.silver_paste_qty ?? 0,
    load_operator: map(activity?.activity_data?.load_operator, 'id'),
    unload_operator: map(activity?.activity_data?.unload_operator, 'id')
  }
})

// ############################################################
// SERIGRAFIA LUNOTTI FASE TRE
// ############################################################

export const setSerigrafiaLunottiFaseTreInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    black_ink_supplier: activity?.activity_data?.black_ink_supplier ?? '',
    black_ink_lot: activity?.activity_data?.black_ink_lot ?? '',
    load_operator: map(activity?.activity_data?.load_operator, 'id'),
    unload_operator: map(activity?.activity_data?.unload_operator, 'id')
  }
})

// ############################################################
// PASSAGGIO AL FORNO
// ############################################################

export const setPassaggioAlFornoInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    load_operator: map(activity?.activity_data?.load_operator, 'id'),
    unload_operator: map(activity?.activity_data?.unload_operator, 'id')
  }
})

// ############################################################
// TEMPERA
// ############################################################

export const setTemperaInitialValues = (activity, user) => {

  return {
    ...setActivityInitialValues(activity),
    activity_data: {
      tool: get(activity, 'activity_data.tool.id', undefined),
      oven_temperature: activity?.activity_data?.oven_temperature ?? 0,
      with_fragmentation: activity?.activity_data?.with_fragmentation ?? false,
      load_operator: map(activity?.activity_data?.load_operator, 'id'),
      unload_operator: map(activity?.activity_data?.unload_operator, 'id'),
      prova_frammentazione: buildProvaFrammentazione(activity?.activity_data?.prova_frammentazione),
    }
  }
}

const buildProvaFrammentazione = (prova_frammentazione = []) => {
  if (prova_frammentazione.length < 1) {
    return ['A', 'B', 'C'].map(letter => buildDefaultProvaTipo(letter))
  }

  return prova_frammentazione.map(pf => formatProvaTipo(pf));
}

const formatProvaTipo = provaTipo => ({
  letter: provaTipo.letter,
  prove: provaTipo.prove.map(pt => ({
    orario: pt?.orario ?? undefined,
    esito: parseBool(pt?.esito)
  })),
  acquisizione_digitale: parseBool(provaTipo?.acquisizione_digitale),
  eseguito_da: provaTipo?.eseguito_da ?? '',
  li: provaTipo?.li ?? '',
  note: provaTipo?.note ?? '',
})

const buildDefaultProvaTipo = letter => ({
  letter: letter,
  prove: times(4, () => buildProvaRottura()),
  acquisizione_digitale: undefined,
  eseguito_da: '',
  li: '',
  note: ''
})

const buildProvaRottura = () => {
  return {
    orario: '',
    esito: undefined
  }
}

// ############################################################
// IMBALLO DOPO TEMPERA
// ############################################################

export const setImballoDopoTemperaInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    operator: merge(map(activity?.activity_data?.operator, 'id'), [user?.id])
  }
})

// ############################################################
// ATTACCO TERMINALI
// ############################################################

export const setAttaccoTerminaliInitialValues = (activity, user) => ({
  ...setActivityInitialValues(activity),
  activity_data: {
    tool: get(activity, 'activity_data.tool.id', undefined),
    terminals: activity?.activity_data?.terminals ?? '',
    terminals_supplier: activity?.activity_data?.terminals_supplier ?? '',
    terminals_lot: activity?.activity_data?.terminals_lot ?? '',
    current_load: activity?.activity_data?.current_load ?? 0,
    operator: merge(map(activity?.activity_data?.operator, 'id'), [user?.id])
  }
})
