import api from '../helpers/axios';

const BASE_URL = '/lots';

const fetchAll = filters => api.get(BASE_URL, { params: filters }).then(res => res.data);
const selectable = filters => api.get('selectable' + BASE_URL, { params: filters }).then(res => res.data);
const fetch = id => api.get(BASE_URL + '/' + id).then(res => res.data);
const sendEmailToProd = id => api.get(BASE_URL + '/' + id + '/notify-prod').then(res => res.data);
const store = attributes => api.post(BASE_URL, attributes).then(res => res.data);
const update = (id, attributes) => api.patch(BASE_URL + '/' + id, attributes).then(res => res.data);
const fetchState = id => api.get(BASE_URL + '/' + id + '/state').then(res => res.data);
const changeState = (id, state) => api.post(BASE_URL + '/' + id + '/change-state', { state }).then(res => res.data);
const destroy = id => api.delete(BASE_URL + '/' + id);
const nextNumber = id => api.get(BASE_URL + '-next-number').then(res => res.data)
const stats = id => api.get(BASE_URL + '/' + id + '/stats').then(res => res.data)
const statsAll = period => api.post('/stats', period).then(res => res.data)
const shipmentsStatus = id => api.get(BASE_URL + '/' + id + '/shipments-status').then(res => res.data)
const generatePrint = id => api.get(`${BASE_URL}/${id}/generate-print`).then(res => res.data)
const generatePrintMat = id => api.get(`${BASE_URL}/${id}/generate-print-mat`, { responseType: 'blob' }).then(res => res.data)


export const lotService = {
  fetchAll,
  selectable,
  fetch,
  store,
  update,
  sendEmailToProd,
  fetchState,
  changeState,
  destroy,
  nextNumber,
  generatePrint,
  generatePrintMat,
  statsAll,
  shipmentsStatus,
  stats,
  BASE_URL
};
