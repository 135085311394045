import React from 'react';
import { Card, CardBody, Col, InputGroup, InputGroupText, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import toolConfigService from '../../../../../services/toolConfigService';
import * as Yup from 'yup';
import { InputField } from '../../../../../components/Custom/Form/InputField';
import FormGroup from '../../../../../components/Custom/Form/FormGroup';
import LotPicker from '../../../../../components/Custom/Form/Pickers/LotPicker/LotPicker';
import { FormDisplay } from '../../../../../components/Custom/Form/Display/FormDisplay';
import { LotStatesEnum } from '../../../../../constants/enums/LosStatesEnum';
import { FileListReader } from '../FileListReader/FileListReader';

const DenverAction = ({ toolUuid }) => {

  const initialValues = {
    file_name: '',
    lot_number: '',
    qty: 0,
    working_area: 1,
    thickness: ''
  }
  const validation = Yup.object().shape({
    file_name: Yup.string().required(),
    lot_number: Yup.string().required(),
    working_area: Yup.number().required(),
    thickness: Yup.number().required(),
    qty: Yup.number().min(1).required()
  })

  return (
    <Card>
      <CardBody>
        <Formik
          initialValues={initialValues}
          validationSchema={validation}
          enableReinitialize={true}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            toolConfigService.execute(toolUuid, {
              ...values,
              thickness: values.thickness * 10
            })
            .then(res => {
              toast.success('Lavorazione inviata con successo')
              resetForm()
            })
            .catch(err => toast.error(err.response.data.message))
            .finally(() => setSubmitting(false))
          }}>

          {({ isSubmitting, isValid, setFieldValue, values }) => (
            <Form>
              <Row>
                <Col lg={4} md={6}>
                  <FileListReader toolUuid={toolUuid} />


                  <FormGroup label="Nome del file"
                             name="file_name"
                             required={true}
                             inline={false}>
                    <InputGroup>
                      <InputField name="file_name"/>
                      <InputGroupText>.txt</InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg={4} md={6}>
                  <FormGroup label="Numero lotto"
                             name="lot_number"
                             required={true}
                             inline={false}>
                    <LotPicker
                      handleChange={v => setFieldValue("lot_number", v?.value ?? '')}
                      states={[LotStatesEnum.in_progress]}
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} md={6}>
                  <FormGroup label="Quantità d produrre"
                             name="qty"
                             required={true}
                             inline={false}>
                    <InputField type='number' min={0} name="qty"/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6}>
                  <FormGroup label="Area di lavoro"
                             name="working_area"
                             required={true}
                             inline={false}>
                    <InputField name="working_area" step={1} min={1} max={256}/>
                  </FormGroup>
                </Col>
                <Col lg={4} md={6}>
                  <FormGroup label="Spessore in millimetri"
                             name="thickness"
                             required={true}
                             description={`Pari a decimi di millemetro ${(values.thickness ?? 0) * 10}`}
                             inline={false}>
                    <InputField name="thickness" step={1} min={1} max={5000}/>
                  </FormGroup>
                </Col>
                <Col lg={4} md={6}>
                  <FormDisplay inline={false} label='Numero di ripetizioni'>
                    1
                  </FormDisplay>
                </Col>
              </Row>
              <div className='d-flex justify-content-end align-items-center'>
                <button type="submit"
                        disabled={isSubmitting || !isValid}
                        className="btn btn-sm btn-primary ">
                  <i className='fa fa-paper-plane me-1'/>
                  Invia lavorazione
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  )
}

export default DenverAction
