import React, {useEffect, useState} from 'react'
import {Form, Formik} from "formik";
import {InputField} from "../../../../components/Custom/Form/InputField";
import FormGroup from "../../../../components/Custom/Form/FormGroup";
import {FormSelect} from "../../../../components/Custom/Form/FormSelect";
import {WorkTypeArray} from "../../../../constants/enums/WorkTypesEnum";
import toolService from "../../../../services/toolService";
import GenericOffCanvas from "../../../../components/Custom/UI/Canvas/GenericOffCanvas";
import GenericCanvasActions from "../../../../components/Custom/UI/Canvas/GenericCanvasActions";
import {toolForm} from "./toolForm";
import DeleteButton from "../../../../components/Custom/Form/Buttons/DeleteButton";
import {toast} from "react-toastify";

const ToolOffCanvas = ({isOpen, toggle, data, handleResponse}) => {

    const [initialValues, setInitialValues] = useState(toolForm.initialValues)

    const handleSubmit = (values) => data ? toolService.update(data.uuid, values) : toolService.store(values);

    useEffect(() => {
        data ? setInitialValues({
            name: data.name || '',
            description: data.description || '',
            note: data.note || '',
            work_types: data.work_types ? data.work_types.map(wt => wt.value) : [],
        }) : setInitialValues(toolForm.initialValues)
    }, [data]);

    const handleDelete = () => toolService.destroy(data.uuid)
        .then(res => {
            toast.success('Operazione eseguita con successo')
            handleResponse(undefined)
            toggle(false)
        })
        .catch(err => toast.error(err.response.data.message))


    return (<GenericOffCanvas
            title={data ? 'Modifica ' + data.name : 'Crea strumento'}
            isOpen={isOpen}
            toggle={toggle}>
            <Formik
                initialValues={initialValues}
                validationSchema={toolForm.validation}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                        .then(res => {
                            toast.success('Operazione eseguita con successo')
                            handleResponse(res)
                            toggle(false)
                        })
                        .catch(err => toast.error(err.response.data.message))
                        .finally(() => setSubmitting(false))
                }}>
                {({setFieldValue}) => (
                    <Form>
                        <FormGroup name="name" label="Nome" required={true} inline={false}>
                            <InputField name="name" type="text" maxLength={100}/>
                        </FormGroup>
                        <FormGroup name="description" label="Descrizione" required={false}
                                   inline={false}>
                            <InputField name="description" type="text"/>
                        </FormGroup>

                        <FormGroup name="work_types" label="Tipo di lavorazione" inline={false}>
                            <FormSelect multiple={true}
                                        name="work_types"
                                        onChange={(value) => setFieldValue('work_types', value)}
                                        placeholder="Seleziona un tipo di lavorazione"
                                        options={WorkTypeArray}/>
                        </FormGroup>

                        <FormGroup name="note" label="Note" required={false} inline={false}>
                            <InputField name="note" component="textarea" rows={6}/>
                        </FormGroup>
                        <GenericCanvasActions toggle={toggle}>
                            {data?.uuid &&
                                <DeleteButton handleClick={() => handleDelete()}/>
                            }
                        </GenericCanvasActions>
                    </Form>
                )}
            </Formik>
        </GenericOffCanvas>
    )

}

export default ToolOffCanvas
