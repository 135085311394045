import React, { useContext } from 'react';
import { WorkTypesEnum } from '../../../../../../constants/enums/WorkTypesEnum';
import { NumberDisplay } from '../../../../../../components/Custom/Form/Display/NumberDisplay';
import { LotContext } from '../../../LotWrapper';

const LotWorkRecapTable = ({ lotWork }) => {

  const { lot } = useContext(LotContext)


  return (
    <>
      <div className='table-responsive'>
        <table className='table table-hover'>
          <thead>
          <tr className='table-primary'>
            <th></th>
            <th className='text-center'>Idonei</th>
            <th className='text-center'>Scarti</th>
            {lotWork?.type.value !== WorkTypesEnum.taglio && <th className='text-center'>Da rilavorare</th>}
            {lotWork?.type.value !== WorkTypesEnum.taglio && <th className='text-center'>Rilavorati</th>}
            <th className='text-center'>Prodotti</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td className='text-end'>PIANI</td>
            <td className='text-center'><NumberDisplay value={lotWork?.manufactured_qty}/></td>
            <td className='text-center'><NumberDisplay value={lotWork?.wasted_qty} limit={999999999}/></td>
            {lotWork?.type.value !== WorkTypesEnum.taglio &&
              <td className='text-center'><NumberDisplay value={lotWork?.to_be_reworked_qty}/></td>}
            {lotWork?.type.value !== WorkTypesEnum.taglio &&
              <td className='text-center'><NumberDisplay value={lotWork?.rework_qty}/></td>}
            <td className="text-center">
              <NumberDisplay value={lotWork?.total_qty} limit={lot.total_qty}/> / {lot.total_qty}
            </td>
          </tr>
          <tr>
            <td className='text-end'>DX</td>
            <td className='text-center'><NumberDisplay value={lotWork?.manufactured_dx_qty}/></td>
            <td className='text-center'><NumberDisplay value={lotWork?.wasted_dx_qty} limit={999999999}/></td>
            {lotWork?.type.value !== WorkTypesEnum.taglio &&
              <td className='text-center'><NumberDisplay value={lotWork?.to_be_reworked_dx_qty}/></td>}
            {lotWork?.type.value !== WorkTypesEnum.taglio &&
              <td className='text-center'><NumberDisplay value={lotWork?.rework_dx_qty}/></td>}
            <td className="text-center">
              <NumberDisplay value={lotWork?.dx_qty} limit={lot.dx_qty}/> / {lot.dx_qty}
            </td>
          </tr>
          <tr>
            <td className='text-end'>SX</td>
            <td className='text-center'><NumberDisplay value={lotWork?.manufactured_sx_qty}/></td>
            <td className='text-center'><NumberDisplay value={lotWork?.wasted_sx_qty} limit={999999999}/></td>
            {lotWork?.type.value !== WorkTypesEnum.taglio &&
              <td className='text-center'><NumberDisplay value={lotWork?.to_be_reworked_sx_qty}/></td>}
            {lotWork?.type.value !== WorkTypesEnum.taglio &&
              <td className="text-center"><NumberDisplay value={lotWork?.rework_sx_qty}/></td>}
            <td className="text-center">
              <NumberDisplay value={lotWork?.sx_qty} limit={lot.sx_qty}/> / {lot.sx_qty}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </>
  )

}

export default LotWorkRecapTable
