import React, { useContext } from 'react';
import { useRequest } from 'ahooks';
import { lotWorkService } from '../../../../services/lotWorkService';
import { ApplicationContext } from '../../../../components/Layout/Layout';
import { Alert, Col, Row, Table } from 'reactstrap';
import LotWorkState from '../../../../components/Custom/UI/States/LotWorkState';
import { FormDisplay } from '../../../../components/Custom/Form/Display/FormDisplay';
import { WorkTypesEnum } from '../../../../constants/enums/WorkTypesEnum';
import { NumberDisplay } from '../../../../components/Custom/Form/Display/NumberDisplay';

const IndustryLotWorks = ({ lot }) => {

  const { lot_work_configs } = useContext(ApplicationContext)
  const { data: lotWorks = [] } = useRequest(lotWorkService.fetchAll, { defaultParams: [lot?.uuid] })

  return <>
    <h3>Lavorazioni</h3>
    {lotWorks.map((lotWork, index) => {
      const lotWorkConfig = lot_work_configs.find(lwc => lwc.value === lotWork.type.value)

      return (
        <div key={index}>
          <div className='d-flex align-content-center justify-content-start gap-1'>
            <h5>{index + 1}. {lotWork.type.label}</h5>
            <LotWorkState isCompleted={lotWork?.is_completed}/>
          </div>

          {lotWork?.lot_work_data.extraData.length > 0 &&
            <Row>
              {lotWork?.lot_work_data?.extraData.map((extraData, index) =>
                <Col lg={4} md={6} key={index}>
                  <Alert color='warning' className="gap-2 d-flex">
                    <span>{extraData.name.replace('_', ' ').toUpperCase()}:</span>
                    <span className="fw-bold">{extraData.value || "Non specificato"}</span>
                  </Alert>
                </Col>
              )}
            </Row>
          }

          <Row>
            <Col lg={8}>
              <Table size='xs' responsive={true} striped={true}>
                <thead>
                <tr>
                  <th style={{width: "100px"}}></th>
                  <th style={{width: "20%"}}>Idonei</th>
                  <th style={{width: "20%"}}>Scarti</th>
                  {lotWork?.type.value !== WorkTypesEnum.taglio && <th style={{width: "20%"}}>Da rilavorare</th>}
                  {lotWork?.type.value !== WorkTypesEnum.taglio && <th style={{width: "20%"}}>Rilavorati</th>}
                  <th>Prodotti</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className='text-end'>PIANI</td>
                  <td><NumberDisplay value={lotWork?.manufactured_qty}/></td>
                  <td><NumberDisplay value={lotWork?.wasted_qty} limit={999999999}/></td>
                  {lotWork?.type.value !== WorkTypesEnum.taglio &&
                    <td><NumberDisplay value={lotWork?.to_be_reworked_qty}/></td>}
                  {lotWork?.type.value !== WorkTypesEnum.taglio &&
                    <td><NumberDisplay value={lotWork?.rework_qty}/></td>}
                  <td className="fw-bolder">
                    <NumberDisplay value={lotWork?.total_qty} limit={lot.total_qty}/> / {lot.total_qty}
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold text-end'>DX</td>
                  <td><NumberDisplay value={lotWork?.manufactured_dx_qty}/></td>
                  <td><NumberDisplay value={lotWork?.wasted_dx_qty} limit={999999999}/></td>
                  {lotWork?.type.value !== WorkTypesEnum.taglio &&
                    <td><NumberDisplay value={lotWork?.to_be_reworked_dx_qty}/></td>}
                  {lotWork?.type.value !== WorkTypesEnum.taglio &&
                    <td><NumberDisplay value={lotWork?.rework_dx_qty}/></td>}
                  <td className="fw-bolder">
                    <NumberDisplay value={lotWork?.dx_qty} limit={lot.dx_qty}/> / {lot.dx_qty}
                  </td>
                </tr>
                <tr>
                  <td className='fw-bold text-end'>SX</td>
                  <td><NumberDisplay value={lotWork?.manufactured_sx_qty}/></td>
                  <td><NumberDisplay value={lotWork?.wasted_sx_qty} limit={999999999}/></td>
                  {lotWork?.type.value !== WorkTypesEnum.taglio &&
                    <td><NumberDisplay value={lotWork?.to_be_reworked_sx_qty}/></td>}
                  {lotWork?.type.value !== WorkTypesEnum.taglio &&
                    <td><NumberDisplay value={lotWork?.rework_sx_qty}/></td>}
                  <td className="fw-bolder">
                    <NumberDisplay value={lotWork?.sx_qty} limit={lot.sx_qty}/> / {lot.sx_qty}
                  </td>
                </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={4}>
              {(lotWorkConfig.has_template || lotWorkConfig.has_program) ?
                <>
                  {lotWorkConfig.has_template && <FormDisplay label='Dima/telaio'>
                    {lotWork?.lot_work_data?.template?.name}
                  </FormDisplay>}
                  {lotWorkConfig.has_program && <FormDisplay label='Programma'>
                    {lotWork?.lot_work_data?.program?.name}
                  </FormDisplay>}
                </>
                : <FormDisplay label="Nessuna configurazione"/>}
            </Col>
          </Row>
          <hr />
        </div>
      )

    })}
  </>
}

export default IndustryLotWorks
