import React from 'react';

const LavaggioReportsTable = ({ children, action = undefined }) => {

  return (
    <div className='table-responsive'>
      <table className='table table-hover align-middle'>
        <thead>
        <tr>
          <th className='text-end'>Controllo</th>
          <th className='text-center'>Visivo</th>
        </tr>
        <tr>
          <th className='text-end'>Pulizia vetro</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {children}
        </tbody>
        {action}
      </table>
    </div>
  )

}

export default LavaggioReportsTable
