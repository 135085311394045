import api from '../helpers/axios';

const fetchAll = (itemId) => api.get(`items/${itemId}/item-works`).then(res => res.data);
const store = (itemId, attributes = {}) => api.post(`items/${itemId}/item-works`, attributes).then(res => res.data);

const itemWorkService = {
  fetchAll,
  store
};

export default itemWorkService;
