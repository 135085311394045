const glass = 'glass';
const poly = 'poly';

export const ThicknessTypeEnum = {
    glass,
    poly
}

export const ThicknessTypeArray = [
    {value: 'glass', label: 'Vetro'},
    {value: 'poly', label: 'Policarbonato'}
]