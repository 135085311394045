import React from "react";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import FormGroup from "../../../../components/Custom/Form/FormGroup";
import {InputField} from "../../../../components/Custom/Form/InputField";
import {Form, Formik} from "formik";

const ArchivedLotFilters = ({onChangeFilters}) => {

    const initialValues = {
        filter: {
            lot_number: '',
            item_code: '',
            item_description: ''
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, {setSubmitting}) => {
                onChangeFilters(values)
                setSubmitting(false)
            }}>
            {({isSubmitting, handleSubmit, isValid, setFieldValue}) => (
                <Form onSubmit={handleSubmit}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xxl={4} lg={6}>
                                    <FormGroup name="filter.lot_number" label="Nr. Lotto" inline={false}>
                                        <InputField name="filter.lot_number" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col xxl={4} lg={6}>
                                    <FormGroup name="filter.item_code" label="Codice articolo" inline={false}>
                                        <InputField name="filter.item_code" type="text"/>
                                    </FormGroup>
                                </Col>
                                <Col xxl={4} lg={6}>
                                    <FormGroup name="filter.item_description" label="Descrizione articolo"
                                               inline={false}>
                                        <InputField name="filter.item_description" type="text"/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Button type="submit" className="float-end" size="sm" color="info">
                                <i className="fa fa-filter"/> Filtra risultati
                            </Button>
                        </CardBody>
                    </Card>
                </Form>
            )}
        </Formik>
    );

}

export default ArchivedLotFilters;
