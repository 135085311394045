import React from "react";

const HourFormatDisplay = ({ minutes, withLabel = true }) => {

  const addLeadingZero = (num) => String(num).padStart(2, '0')

  return <>{addLeadingZero(Math.floor(minutes / 60))}:{addLeadingZero(minutes % 60)} {withLabel ? 'ore' : ''}</>
}

export default HourFormatDisplay;
