import * as Yup from "yup";

const validation = Yup.object().shape({
    business_name: Yup.string().max(100, "Max 100 caratteri").required("Obbligatorio"),
    note: Yup.string().notRequired()
})

const initialValues = {
    business_name: '',
    note: '',
}
export const customerForm = {
    validation,
    initialValues
}