import React from 'react'
import LotDetails from './components/LotDetails/LotDetails';
import LotWorksDetail from './components/LotWorksDetails/LotWorksDetail';
import LotShipmentDetails from './components/LotShipmentsDetail/LotShipmentDetails';

const Lot = () => {


  return (
    <>
      <LotDetails/>
      <LotWorksDetail/>
      <LotShipmentDetails/>
    </>
  )


}

export default Lot
