import React from 'react';
import FormGroup from '../../../../../../../../components/Custom/Form/FormGroup';
import { InputField } from '../../../../../../../../components/Custom/Form/InputField';
import TaglioReportsTable from './TaglioReportsTable';
import SelectAllCheckboxesButton from '../../components/SelectAllCheckboxesButton';
import ReportCheckboxFormRows from '../../components/ReportCheckboxFormRows';
import { useFormikContext } from 'formik';
import ActivityCopyExtraDataButton from '../../components/ActivityCopyExtraDataButton';

const TaglioForm = ({ workConfig }) => {

  const { setValues } = useFormikContext()

  const handleUpdateValues = v => setValues(values => ({
    ...values,
    activity_data: {
      ...values.activity_data,
      slab_manufacturer: v.slab_manufacturer ?? '',
      c_lot_number: v.c_lot_number ?? '',
      slab_number: v.slab_number ?? '',
      slab_dimensions: v.slab_dimensions ?? '',
      primary_dimensions: v.primary_dimensions ?? '',
    }
  }))


  return (
    <>
      <div className='row'>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Lastra pervenuta da" name="activity_data.slab_manufacturer" required={true}>
            <InputField name="activity_data.slab_manufacturer"/>
          </FormGroup>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="C/lotto n°" name="activity_data.c_lot_number" required={false}>
            <InputField name="activity_data.c_lot_number"/>
          </FormGroup>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Quantità lastre utilizzate" name="activity_data.slab_number" required={true}>
            <InputField name="activity_data.slab_number" type="number" min={0}/>
          </FormGroup>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Dimensione della lastra utilizzata" name="activity_data.slab_dimensions" required={true}>
            <InputField name="activity_data.slab_dimensions"/>
          </FormGroup>
        </div>
        <div className='col-lg-4 col-md-6'>
          <FormGroup label="Dimensione del primario" name="activity_data.primary_dimensions" required={true}>
            <InputField name="activity_data.primary_dimensions"/>
          </FormGroup>
        </div>
        <div className='col-lg-4 col-md-6 d-flex align-items-end pb-3 justify-content-end'>
          <ActivityCopyExtraDataButton handleUpdateData={handleUpdateValues}/>
        </div>
      </div>


      <TaglioReportsTable action={<SelectAllCheckboxesButton workConfig={workConfig}/>}>
        <ReportCheckboxFormRows workConfig={workConfig}/>
      </TaglioReportsTable>

    </>
  )

}

export default TaglioForm
