import React from "react"
import Header from "./Header/Header"
import { useRequest } from "ahooks";
import { lotWorkService } from "../../services/lotWorkService";
import Breadcrumbs from './Header/Breadcrumbs';

export const ApplicationContext = React.createContext({
  lot_work_configs: []
})

const Layout = props => {

  const { data: lotWorkConfigs } = useRequest(lotWorkService.configs);

  return (
    <ApplicationContext.Provider value={{
      lot_work_configs: lotWorkConfigs || []
    }}>
      <Header/>
      <div className='page-content' style={{ minHeight: '100vh' }}>
        <Breadcrumbs />
        {props.children}
      </div>
    </ApplicationContext.Provider>
  )
}

export default Layout
