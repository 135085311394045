import React from "react"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useSanctum } from "react-sanctum";

const ProfileDropdown = () => {
  const { user, signOut } = useSanctum();

  return (
    <UncontrolledDropdown>
      <DropdownToggle nav caret>
        {user?.full_name}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={signOut}>
          <i className="bx bx-power-off text-danger"/>&nbsp;
          Esci
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default ProfileDropdown
