import React from "react";
import {Badge} from "reactstrap";

const LotWorkState = ({isCompleted}) => {

    return <Badge className="m-0 p-1 align-self-start" color={isCompleted ? 'success' : 'warning'}>
        {isCompleted ? 'Completato' : 'Incompleto'}
    </Badge>
}

export default LotWorkState