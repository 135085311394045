import React from 'react'
import { FormDisplay } from "../../../../../../../../components/Custom/Form/Display/FormDisplay";
import DisplayBooleanOrUndefined from "../../../../../../../../components/Custom/Form/Display/DisplayBooleanOrUndefined";
import ReportCheckboxShowRows from '../../components/ReportCheckboxShowRows';
import TemperaReportsTable from './TemperaReportsTable';
import ProvaDiFrammentazioneShow from './components/show/ProvaDiFrammentazioneShow';

const TemperaShow = ({ activity, workConfig }) => {

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <FormDisplay label="Temperatura del forno">
            {activity?.activity_data?.oven_temperature}
          </FormDisplay>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormDisplay label="Eseguita frammentazione">
            <DisplayBooleanOrUndefined value={activity?.activity_data?.with_fragmentation}/>
          </FormDisplay>
        </div>
      </div>

      {activity?.activity_data?.with_fragmentation &&
        <ProvaDiFrammentazioneShow provaFrammentazione={activity?.activity_data.prova_frammentazione}/>
      }

      <TemperaReportsTable>
        <ReportCheckboxShowRows activity={activity} workConfig={workConfig}/>
      </TemperaReportsTable>
    </>
  )
}

export default TemperaShow
