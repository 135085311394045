import React from 'react';
import GenericOffCanvas from '../../../../../../components/Custom/UI/Canvas/GenericOffCanvas';
import { useRequest } from 'ahooks';
import { lotService } from '../../../../../../services/lotService';
import { Spinner } from 'reactstrap';
import MixedStackedBarChart from '../../../../../../components/Common/MixedStackedBarChart';

const LotStatsCanvas = ({ lot, isOpen, toggle }) => {

  const { data = [], loading } = useRequest(lotService.stats, {
    defaultParams: [lot?.uuid],
    ready: lot?.uuid !== undefined,
    refreshDeps: [lot?.uuid]
  })

  return (
    <GenericOffCanvas
      title='Statistiche lotto'
      isOpen={isOpen}
      toggle={toggle}>
      {loading
        ? <div className="text-center"><Spinner size='sm'/> Caricamento</div>
        : data
          ? <MixedStackedBarChart
            name={`Lotto ${lot?.lot_number}`}
            series={data?.series ?? []}
            categories={data?.categories ?? []}
          />
          : <div className='alert alert-light'>Nessun dato presente</div>
      }
    </GenericOffCanvas>
  )

}

export default LotStatsCanvas
