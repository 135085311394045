import React from "react";
import {useRequest} from "ahooks";
import {useNavigate} from "react-router-dom";
import {archiveService} from "../../../services/archiveService";
import {Card, CardBody} from "reactstrap";
import {PaginatedTable} from "@dvsoftsrl/react-laravel-paginated-table";
import tableOptions from "../../../constants/tableOptions";
import {archivedLotColumns} from "./archivedLotColumns";
import ArchivedLotFilters from "./_components/ArchivedLotFilters";

const Archive = () => {

    const {data = [], loading, run} = useRequest(archiveService.fetchAll)
    const navigate = useNavigate()

    const columns = React.useMemo(() => archivedLotColumns, [])


    return <>

        <ArchivedLotFilters onChangeFilters={(filters) => run(filters)} />

        <Card>
            <CardBody>
                <PaginatedTable
                    options={tableOptions}
                    columns={columns}
                    data={data ?? []}
                    isLoading={loading}
                    filterable={false}
                    onParamsChange={run}
                />
            </CardBody>
        </Card>

    </>

}

export default Archive
