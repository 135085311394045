import React from 'react';

const ReportsTableLabelCeil = ({index, workConfig}) => {

  return (
    <td className='text-end'>
      {index === 0
        ? 'Verifica 1 vetro a inizio produzione'
        : `Verifica vetro da ${(((index - 1) * workConfig?.checkbox_every) + 1)} a ${(index * workConfig?.checkbox_every)} pz`}
    </td>
  )

}
export default ReportsTableLabelCeil
