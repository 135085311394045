const taglio = 'taglio';
const molatura = 'molatura';
const foratura_e_lavaggio_fase_uno = 'foratura_e_lavaggio_fase_uno';
const foratura_e_lavaggio_fase_due = 'foratura_e_lavaggio_fase_due';
const lavaggio = 'lavaggio';
const imballo_dopo_lavaggio = 'imballo_dopo_lavaggio';
const controllo_facciata = 'controllo_facciata';
const sabbiatura = 'sabbiatura';
const lavaggio_dopo_sabbiatura = 'lavaggio_dopo_sabbiatura';
const serigrafia_laterali = 'serigrafia_laterali';
const serigrafia_lunotti_fase_uno = 'serigrafia_lunotti_fase_uno';
const serigrafia_lunotti_fase_due = 'serigrafia_lunotti_fase_due';
const serigrafia_lunotti_fase_tre = 'serigrafia_lunotti_fase_tre';
const tempera = 'tempera';
const passaggio_forno = 'passaggio_forno';
const imballo_dopo_tempera = 'imballo_dopo_tempera';
const attacco_terminali = 'attacco_terminali';


export const WorkTypesEnum = {
    taglio,
    molatura,
    foratura_e_lavaggio_fase_uno,
    foratura_e_lavaggio_fase_due,
    lavaggio,
    imballo_dopo_lavaggio,
    sabbiatura,
    lavaggio_dopo_sabbiatura,
    serigrafia_laterali,
    controllo_facciata,
    serigrafia_lunotti_fase_uno,
    serigrafia_lunotti_fase_due,
    serigrafia_lunotti_fase_tre,
    passaggio_forno,
    tempera,
    imballo_dopo_tempera,
    attacco_terminali,
}


export const WorkTypeArray = [
    {value: 'taglio', label: 'Taglio'},
    {value: 'molatura', label: 'Molatura'},
    {value: 'foratura_e_lavaggio_fase_uno', label: 'Foratura e lavaggio fase 1'},
    {value: 'foratura_e_lavaggio_fase_due', label: 'Foratura e lavaggio fase 2'},
    {value: 'lavaggio', label: 'Lavaggio'},
    {value: 'imballo_dopo_lavaggio', label: 'Imballo dopo lavaggio'},
    {value: 'sabbiatura', label: 'Sabbiatura'},
    {value: 'lavaggio_dopo_sabbiatura', label: 'Lavaggio dopo sabbiatura'},
    {value: 'serigrafia_laterali', label: 'Serigrafia laterali'},
    {value: 'controllo_facciata', label: 'Controllo facciata'},
    {value: 'serigrafia_lunotti_fase_uno', label: 'Serigrafia lunotti fase 1'},
    {value: 'serigrafia_lunotti_fase_due', label: 'Serigrafia lunotti fase 2'},
    {value: 'serigrafia_lunotti_fase_tre', label: 'Serigrafia lunotti fase 3'},
    {value: 'passaggio_forno', label: 'Passaggio al forno'},
    {value: 'tempera', label: 'Tempera'},
    {value: 'imballo_dopo_tempera', label: 'Imballo dopo tempera'},
    {value: 'attacco_terminali', label: 'Attacco terminali'},
]