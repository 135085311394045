import React from "react";
import FormGroup from "../../../../../../../components/Custom/Form/FormGroup";
import { FormSelect } from "../../../../../../../components/Custom/Form/FormSelect";

const ActivityOperatorForm = ({ users, isMulti = false }) => {

  return (
    <div className='row'>
      {!isMulti &&
        <div className='col-lg-4 col-md-6'>
          <FormGroup label="Operatori" name="activity_data.operator"
                     required={true}
                     inline={false}>
            <FormSelect
              name="activity_data.operator"
              placeholder="Seleziona un utente"
              options={users}
              multiple={true}
            />
          </FormGroup>
        </div>
      }
      {isMulti &&
        <div className='col-lg-4 col-md-6'>
          <FormGroup label="Operatori al carico" name="activity_data.load_operator"
                     required={true}
                     inline={false}>
            <FormSelect
              name="activity_data.load_operator"
              placeholder="Seleziona un utente"
              options={users}
              multiple={true}
            />
          </FormGroup>
        </div>
      }
      {isMulti &&
        <div className='col-lg-4 col-md-6'>
          <FormGroup label="Operatori allo scarico" name="activity_data.unload_operator"
                     required={true}
                     inline={false}>
            <FormSelect
              name="activity_data.unload_operator"
              placeholder="Seleziona un utente"
              options={users}
              multiple={true}
            />
          </FormGroup>
        </div>
      }
    </div>
  )

}

export default ActivityOperatorForm
