import React from 'react'
import FormGroup from "../../../../../../../../../components/Custom/Form/FormGroup";
import { InputField } from "../../../../../../../../../components/Custom/Form/InputField";
import SerigrafiaLunottiReportsTable from '../SerigrafiaLunottiReportsTable';
import SelectAllCheckboxesButton from '../../../components/SelectAllCheckboxesButton';
import ReportCheckboxFormRows from '../../../components/ReportCheckboxFormRows';
import ActivityCopyExtraDataButton from '../../../components/ActivityCopyExtraDataButton';
import { useFormikContext } from 'formik';

const SerigrafiaLunottiFaseUnoForm = ({ workConfig }) => {

  const { setValues } = useFormikContext()
  const handleUpdateValues = v => setValues(values => ({
    ...values,
    activity_data: {
      ...values.activity_data,
      black_ink_supplier: v.black_ink_supplier ?? '',
      black_ink_lot: v.black_ink_lot ?? ''
    }
  }))

  return (
    <>
      <div className='row'>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Fornitore vernice nera"
                     name="activity_data.black_ink_supplier"
                     required={true}
                     inline={false}>
            <InputField name="activity_data.black_ink_supplier"/>
          </FormGroup>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Lotto vernice nera n°" name="activity_data.black_ink_lot"
                     required={false}
                     inline={false}>
            <InputField name="activity_data.black_ink_lot"/>
          </FormGroup>
        </div>
        <div className='col-lg-4 col-md-6 d-flex align-items-end pb-3 justify-content-end'>
          <ActivityCopyExtraDataButton handleUpdateData={handleUpdateValues}/>
        </div>

      </div>
      <SerigrafiaLunottiReportsTable action={<SelectAllCheckboxesButton workConfig={workConfig}/>}>
        <ReportCheckboxFormRows workConfig={workConfig}/>
      </SerigrafiaLunottiReportsTable>
    </>
  )
}

export default SerigrafiaLunottiFaseUnoForm
