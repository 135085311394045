import React from "react";
import {Field} from "formik";

export const FormCheckbox = (props) => (
    <div className="form-check">
        <Field type="checkbox" className="form-check-input" {...props}/>
        <label>{props.label}</label>
    </div>
)

