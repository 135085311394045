import React, {useEffect, useState} from 'react'
import {Form, Formik} from "formik";
import {InputField} from "../../InputField";
import FormGroup from "../../FormGroup";
import ManufacturerPicker from "../ManufacturerPicker/ManufacturerPicker";
import vehicleService from "../../../../../services/vehicleService";
import GenericOffCanvas from "../../../UI/Canvas/GenericOffCanvas";
import GenericCanvasActions from "../../../UI/Canvas/GenericCanvasActions";
import {vehicleForm} from "./vehicleForm";
import {toast} from "react-toastify";

const VehicleOffCanvas = ({isOpen, toggle, data, handleResponse}) => {

    const [initialValues, setInitialValues] = useState(vehicleForm.initialValues)
    const handleSubmit = (values) => data ? vehicleService.update(data.uuid, values) : vehicleService.store(values);

    useEffect(() => {
        data ? setInitialValues({
            name: data.name || '',
            manufacturer_id: data.manufacturer_id || '',
            description: data.description || '',
            note: data.note || '',
        }) : setInitialValues(vehicleForm.initialValues)
    }, [data]);

    return (<GenericOffCanvas
            title={data ? 'Modifica ' + data.name : 'Crea modello vettura'}
            isOpen={isOpen}
            toggle={toggle}>
            <Formik
                initialValues={initialValues}
                validationSchema={vehicleForm.validation}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                        .then(res => {
                            toast.success('Operazione eseguita con successo')
                            handleResponse(res)
                            toggle(false)
                        })
                        .catch(err => toast.error(err.response.data.message))
                        .finally(() => setSubmitting(false))
                }}>
                {({setFieldValue}) => (
                    <Form>
                        <FormGroup name="name" label="Nome" required={true} inline={false}>
                            <InputField name="name" type="text" maxLength={100}/>
                        </FormGroup>
                        <FormGroup name="description" label="Descrizione" required={false}
                                   inline={false}>
                            <InputField name="description" type="text"/>
                        </FormGroup>
                        <FormGroup name="manufacturer_id" label="Produttore" inline={false}>
                            <ManufacturerPicker
                                name="manufacturer_id"
                                onSelected={(manufacturerId) => setFieldValue('manufacturer_id', manufacturerId || '')}
                            />
                        </FormGroup>
                        <FormGroup name="note" label="Note" required={false} inline={false}>
                            <InputField name="note" component="textarea" rows={6}/>
                        </FormGroup>
                        <GenericCanvasActions toggle={toggle}/>
                    </Form>
                )}
            </Formik>
        </GenericOffCanvas>
    )

}

export default VehicleOffCanvas