import React, { useContext, useState } from "react";
import StateChanger from "../../../../../../components/Custom/UI/States/StateChanger";
import { LotContext } from "../../../LotWrapper";
import { lotService } from "../../../../../../services/lotService";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import LotExpiration from "../../../components/LotExpiration";
import { useRequest } from "ahooks";
import { printService } from "../../../../../../services/printService";
import { toast } from "react-toastify";
import LotDestroy from './LotDestroy';
import { LotStatesEnum } from '../../../../../../constants/enums/LosStatesEnum';
import { useSanctum } from 'react-sanctum';
import fileDownload from 'js-file-download';
import DateFormatDisplay from '../../../../../../components/Custom/Form/Display/DateFormatDisplay';

const LotHeader = ({ setEditLotCanvasOpen, setLotWorksCanvasOpen, setLotStatsCanvasOpen }) => {
  const { lot } = useContext(LotContext);
  const { user } = useSanctum()

  const [loading, setLoading] = useState(false);

  const { run, cancel } = useRequest(printService.fetch, {
    pollingInterval: 5000,
    pollingErrorRetryCount: 1,
    manual: true,
    onError: (err) => {
      toast.success('Operazione eseguita con successo')
      setLoading(false)
      cancel()
    },
    onSuccess: (res, params) => {
      console.log('PENDING ...', res, params)
      if (res.status === 200) {
        setLoading(false)
        console.log('PDF URL ...', res?.data?.url)
        window.open(res?.data?.url, '_blank').focus()
        cancel()
      }
    }
  });
  const handleDownloadPDF = () => lotService.generatePrint(lot.uuid).then(res => {
    setLoading(true)
    run(res.uuid)
  })

  const handleDownloadMatPDF = () => lotService.generatePrintMat(lot.uuid).then(res => fileDownload(res, lot.lot_number + '.pdf'))

  const handleSendEmail = () => lotService.sendEmailToProd(lot.uuid)
  .then(res => toast.success('Notifica inviata con successo'))
  .catch(err => toast.error(err.response.data.message))

  const canEditLot = () => (
    lot?.state?.value === LotStatesEnum.open ||
    lot?.state?.value === LotStatesEnum.in_progress
  ) && user.can_manage_production_module;

  const canEditLotWorks = () => {
    return true
  }


  return (
    <div className='sticky-top bg-body bg-opacity-75' style={{ top: "90px" }}>
      <div className='d-flex align-items-center justify-content-between mb-3'>
        <h3>Numero Lotto: {lot?.lot_number}</h3>
        <div className='d-flex align-items-center justify-content-end gap-2 d-print-none'>
          {user.can_manage_production_module && <StateChanger/>}

          {canEditLot() && user.can_manage_production_module &&
            <>
              <UncontrolledTooltip target={`edit_btn_${lot.uuid}`}>Modifica lotto</UncontrolledTooltip>
              <button
                id={`edit_btn_${lot.uuid}`}
                type="button"
                className='btn btn-primary btn-icon'
                onClick={() => setEditLotCanvasOpen(true)}>
                <i className='fa fa-pen'/>
              </button>
            </>
          }

          {canEditLotWorks() && user.can_manage_production_module &&
            <>
              <UncontrolledTooltip target={`lot_work_btn_${lot.uuid}`}>Configura lavorazioni</UncontrolledTooltip>
              <button
                id={`lot_work_btn_${lot.uuid}`}
                type="button"
                className='btn btn-primary btn-icon'
                onClick={() => setLotWorksCanvasOpen(true)}>
                <i className='fa fa-cog'/>
              </button>
            </>
          }

          {user.can_manage_production_module &&
            <>
              <UncontrolledTooltip target={`lot_stats_btn_${lot.uuid}`}>Visualizza statistiche</UncontrolledTooltip>
              <button
                id={`lot_stats_btn_${lot.uuid}`}
                type="button"
                className="btn btn-primary btn-icon"
                onClick={() => setLotStatsCanvasOpen(true)}
              >
                <i className='fa fa-chart-bar'/>
              </button>
            </>
          }

          {user.can_manage_production_module &&
            <>
              <UncontrolledTooltip target={`lot_print_btn_${lot.uuid}`}>Stampa schede</UncontrolledTooltip>
              <button
                id={`lot_print_btn_${lot.uuid}`}
                type='button'
                className='btn btn-icon btn-info'
                disabled={loading}
                onClick={() => handleDownloadPDF()}>
                {loading ? <Spinner size='sm'/> : <i className='fa fa-print'/>}
              </button>
            </>}

          <>
            <UncontrolledTooltip target={`lot_print_mat_btn_${lot.uuid}`}>Stampa schede identificazione materiale</UncontrolledTooltip>
            <button
              id={`lot_print_mat_btn_${lot.uuid}`}
              type='button'
              className='btn btn-icon btn-info'
              disabled={loading}
              onClick={() => handleDownloadMatPDF()}>
              {loading ? <Spinner size='sm'/> : <i className='fa fa-box'/>}
            </button>
          </>


          {user.can_manage_production_module &&
            <>
              {lot?.notified_at !== null
                ? <>
                  <UncontrolledTooltip target={`lot_sent_email_btn_${lot.uuid}`}>
                    Notifica inviata <DateFormatDisplay value={lot.notified_at} formatString={'dd/MM/yyyy HH:mm:ss'}/>
                  </UncontrolledTooltip>
                  <span id={`lot_sent_email_btn_${lot.uuid}`} className='btn btn-info btn-icon'><i className='fa fa-envelope'/></span>
                </>
                :
                <>
                  <UncontrolledTooltip target={`lot_send_email_btn_${lot.uuid}`}>
                    Invia notifica a email di produzione
                  </UncontrolledTooltip>
                  <button
                    id={`lot_send_email_btn_${lot.uuid}`}
                    type="button"
                    className="btn btn-primary btn-info"
                    onClick={handleSendEmail}
                  >
                    <i className='fa fa-envelope'/>
                  </button>
                </>
              }
            </>
          }


          {user.can_manage_production_module &&
          <LotDestroy lot={lot}/>}

        </div>
      </div>

      <div className='row'>
        <div className='col-md-3 col-6 d-flex align-items-stretch'>
          <div className='card card-body'>
            Totale Pezzi
            <h5>{lot?.total_qty} pz</h5>
          </div>
        </div>
        <div className='col-md-3 col-6 d-flex align-items-stretch'>
          <div className='card card-body'>
            Destri
            <h5>{lot?.dx_qty} pz</h5>
          </div>
        </div>
        <div className='col-md-3 col-6 d-flex align-items-stretch'>
          <div className='card card-body'>
            Sinistri
            <h5>{lot?.sx_qty} pz</h5>
          </div>
        </div>
        <div className='col-md-3 col-6 d-flex align-items-stretch'>
          <div className='card card-body'>
            Data consegna
            <h5><LotExpiration value={lot?.forecast_date} lotState={lot?.state.value}/></h5>
          </div>
        </div>
      </div>

    </div>
  )
}
export default LotHeader
