import * as Yup from "yup";
import { format } from "date-fns";

const validation = Yup.object().shape({
  total_qty: Yup.number("Numero non valido").min(0).required("Quantità totale obbligatoria"),
  dx_qty: Yup.number("Numero non valido").min(0, "Numero non valido").test("max",
    "Destri e sinistri devono coincidere con la quantità totale",
    (value, context) => {
      const { total_qty, sx_qty } = context.parent;
      if (sx_qty === 0 && value === 0)
        return true;
      return sx_qty + value === total_qty
    }).required("Quantità destri obbligatoria"),
  sx_qty: Yup.number("Numero non valido").min(0, "Numero non valido").test("max",
    "Destri e sinistri devono coincidere con la quantità totale",
    (value, context) => {
      const { total_qty, dx_qty } = context.parent;
      if (dx_qty === 0 && value === 0)
        return true;
      return dx_qty + value <= total_qty
    }).required("Quantità sinistri obbligatoria"),
  start_date: Yup.date("Data non valida").required("Data di inizio obbligatoria"),
  forecast_date: Yup.date("Data non valida").nullable().notRequired(),
  derogation: Yup.string("Deroga non valida")
  .max(255, "Al massimo 255 caratteri")
  .nullable()
  .notRequired(),
  with_fragmentation: Yup.bool("Valore non valido").required(),
  note: Yup.string("Note non valide").nullable().notRequired(),
  item_note: Yup.string("Note articolo non valide").nullable().notRequired(),

  customer_id: Yup.number("Cliente non valido").required("Cliente obbligatorio"),
  item_id: '',
  manufacturer_id: '',
  vehicle_id: '',
  brand_id: Yup.number("Marchio non valido").nullable().notRequired(),
  color_id: Yup.number("Colore non valido").nullable().notRequired(),
  curvature_id: Yup.number("Curvatura non valida").nullable().notRequired(),
  template_id: Yup.number("Dima non valida").nullable().notRequired(),
  cut_program_id: Yup.number("Programm di taglio non valido").nullable().notRequired(),
  drill_program_id: Yup.number("Programm di foratura non valido").nullable().notRequired(),
  grind_program_id: Yup.number("Programm di molatura non valido").nullable().notRequired(),
  thickness_id: Yup.number("Spessore non valido").nullable().notRequired(),
  is_urgent: Yup.boolean().nullable().notRequired(),
})

const initialValues = {
  total_qty: 0,
  dx_qty: 0,
  sx_qty: 0,
  start_date: format(new Date(), 'yyyy-MM-dd'),
  forecast_date: '',
  derogation: '',
  with_fragmentation: false,
  note: '',
  item_note: '',
  customer_id: '',
  item_id: '',
  manufacturer_id: '',
  vehicle_id: '',
  brand_id: '',
  color_id: '',
  curvature_id: '',
  thickness_id: '',
  is_urgent: false
}


const setItem = (item) => {

  return {
    manufacturer_id: item?.manufacturer?.id || '',
    vehicle_id: item?.vehicle?.id || '',
    brand_id: item?.brand?.id || '',
    color_id: item?.color?.id || '',
    curvature_id: item?.curvature?.id || '',
    thickness_id: item?.thickness?.id || '',
    item_note: item?.note || ''
  }

}


export const formConfig = {
  validation,
  initialValues,
  setItem
}