import React from 'react'
import {useRequest} from "ahooks";
import {itemService} from "../../../../../services/itemService";
import {useFormikContext} from "formik";
import Select from "react-select";

const ItemPicker = props => {

    const {data, loading} = useRequest(itemService.selectable);
    const {setFieldValue} = useFormikContext()

    const handleChange = (value) => {
        if (loading)
            return;

        if (value?.uuid) {
            itemService.fetch(value.uuid).then(res => {
                props.onSelected(res)
                setFieldValue(props.name, value.id)
            })
        } else {
            props.onSelected(undefined)
            setFieldValue(props.name, undefined)
        }
    }


    return (
        <React.Fragment>
            <Select
                isClearable={true}
                placeholder="Seleziona un articolo"
                loading={loading}
                name={props.name}
                onChange={(value) => handleChange(value)}
                options={data || []}
            />
        </React.Fragment>
    )
}

export default ItemPicker