import React from 'react';
import { Form, Formik } from 'formik';
import { InputField } from '../../../../components/Custom/Form/InputField';
import ProgramPicker from '../../../../components/Custom/Form/Pickers/ProgramPicker/ProgramPicker';
import TemplatePicker from '../../SystemTables/Templates/TemplatePicker';
import SearchButton from '../../../../components/Custom/Form/Buttons/SearchButton';


const ItemsFilters = ({ run }) => {

  const initialValues = {
    q: '',
    filter: {
      program: undefined,
      template: undefined
    }
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            run(values)
            setSubmitting(false)
          }}>
          {() => (
            <Form>
              <div className='row'>
                <div className='col-lg-3 col-md-6'>
                  <InputField name={'q'} placeholder={'Codice/Descrizione'}/>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <ProgramPicker name={'filter.program'}/>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <TemplatePicker name={'filter.template'} onSelected={() => {
                  }}/>
                </div>
                <div className='col-lg-3 col-md-6 d-flex align-items-center justify-content-end gap-1'>
                  <SearchButton/>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )

}

export default ItemsFilters
