import React, { useContext, useMemo } from 'react';
import { LotContext } from '../../../../LotWrapper';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';
import activitiesHelper from '../activitiesHelper';
import ActivityTableShow from '../../Activities/ActivityTableShow';
import { WorkTypesEnum } from '../../../../../../../constants/enums/WorkTypesEnum';
import ActivityNotesShow from './ActivityNotesShow';
import ActivityOperatorsShow from './ActivityOperatorsShow';
import { useSanctum } from 'react-sanctum';

const ShowActivity = () => {

  const { lot } = useContext(LotContext)
  const  {user} = useSanctum()
  const { lot_work_id, activity_id } = useParams()

  const lotWork = find(lot.lot_works, lw => lw.uuid === lot_work_id);
  const activity = find(lotWork?.activities, act => act.uuid === activity_id);

  return activity
    ? (
      <>
        <ActivityTableShow
          lot={lot}
          lotWork={lotWork}
          activity={activity}
          canShow={false} // Non devo mai far vedere la show perchè sono già nella show!
          canCheck={user.can_manage_production_module}
          canEdit={user.can_manage_production_module}
          canDestroy={user.can_manage_production_module}
          withReworked={lotWork?.type.value !== WorkTypesEnum.taglio}
        />

        <div className='card-body pt-0'>
          {activitiesHelper.resolveShowProductionDataTemplate(lotWork, activity)}
          <ActivityNotesShow activity={activity}/>
          <ActivityOperatorsShow activity={activity}/>
        </div>
      </>
    )
    :
    <></>


}

export default ShowActivity
