import React from 'react';
import ReportsTableLabelCeil from './ReportsTableLabelCeil';

const ReportCheckboxFormRows = ({ workConfig, activity }) => {

  return (
    <>
      {activity?.control_reports?.map((cr, index) => (
        <tr key={`checkbox_${index}`}>
          <ReportsTableLabelCeil
            index={index}
            workConfig={workConfig}
          />
          {Array(workConfig.checkbox_count).fill({}).map((c, checkIndex) =>
            (
              <td className='text-center' key={`check_container_${checkIndex}`}>
                {cr[checkIndex]
                  ? <i className='fa fa-check-circle fa-2x text-success'/>
                  : <i className='fa fa-times-circle fa-2x text-danger'/>
                }
              </td>
            )
          )}
        </tr>
      ))}
    </>
  )

}
export default ReportCheckboxFormRows
