import React, { useContext } from "react";
import ActivityTableShow from './ActivityTableShow';
import { WorkTypesEnum } from '../../../../../../constants/enums/WorkTypesEnum';
import { useParams } from 'react-router-dom';
import { LotContext } from '../../../LotWrapper';
import { find } from 'lodash';

const LotWorkActivities = () => {

  const { lot_work_id } = useParams()
  const { lot } = useContext(LotContext)
  const lotWork = find(lot.lot_works, lw => lw.uuid === lot_work_id);

  return (
    <>
      <div className='card-body pb-0'>
        <div className='card-title fs-3'>
          Attività
        </div>
      </div>
      {
        lotWork.activities.length > 0
          ? lotWork.activities.map(activity =>
            <ActivityTableShow
              lotWork={lotWork}
              lot={lot}
              canShow={true}
              activity={activity}
              withReworked={lotWork?.type.value !== WorkTypesEnum.taglio}
              key={activity.uuid}
            />
          )
          : <div className='alert alert-light mx-3'>Nessuna attività inserita</div>
      }
    </>
  )

}

export default LotWorkActivities
