import { useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { filter, find } from 'lodash';
import { LotContext } from '../../../../LotWrapper';
import activitiesHelper, { calculateFormControlReports, calculateTotal } from '../activitiesHelper';
import ActivityTopForm from '../components/ActivityTopForm';
import ActivityTableForm from '../components/ActivityTableForm';
import ActivityBottomForm from '../components/ActivityBottomForm';
import ActivityOperatorForm from '../components/ActivityOperatorForm';
import SubmitButton from '../../../../../../../components/Custom/Form/Buttons/SubmitButton';
import { activityService } from '../../../../../../../services/ActivityService';
import { toast } from 'react-toastify';
import { lotWorkService } from '../../../../../../../services/lotWorkService';
import CancelButton from '../../../../../../../components/Custom/Form/Buttons/CancelButton';
import { useSanctum } from 'react-sanctum';

const CreateActivity = () => {

  const { lot, mutateLot, tools, users } = useContext(LotContext)
  const { id, lot_work_id } = useParams()
  const { user } = useSanctum()

  const work = useMemo(() => find(lot.lot_works, lw => lw.uuid === lot_work_id), [lot_work_id]);
  const workConfig = useMemo(() => activitiesHelper.resolveFormConfigs(work), [lot_work_id]);
  const initialValues = useMemo(() => activitiesHelper.resolveInitialValues(work, undefined, user), [lot_work_id]);
  const validationSchema = useMemo(() => activitiesHelper.resolveValidationSchema(work), [lot_work_id]);
  const formContent = activitiesHelper.resolveFormTemplate(work, workConfig)
  const navigate = useNavigate()

  const handleSubmit = (values, { setSubmitting }) => activityService.store(id, lot_work_id, values)
  .then(activityRes => {
    lotWorkService.fetch(id, lot_work_id)
    .then(lotWorkRes => {
      mutateLot(lot => ({ ...lot, lot_works: lot.lot_works.map(lw => lw.uuid !== lot_work_id ? lw : lotWorkRes) }))
    })
    .catch(err => toast.error(err.response.data.message))
    .finally(() => {
      toast.success('Lavorazione inviata con successo')
      navigate('../')
    })
  })
  .catch(err => toast.error(err.response.data.message))
  .finally(() => setSubmitting(false))

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  const [total, setTotal] = useState(0)

  useEffect(() => {
      setTotal(t => calculateTotal(
        formik.values.manufactured_qty,
        formik.values.manufactured_dx_qty,
        formik.values.manufactured_sx_qty
      ))
    }, [formik.values.manufactured_qty, formik.values.manufactured_dx_qty, formik.values.manufactured_sx_qty]
  )

  useEffect(() => {
    formik.setFieldValue('control_reports',
      calculateFormControlReports(
        total,
        workConfig.checkbox_every,
        workConfig.checkbox_count,
        formik.values.control_reports
      ))
  }, [total]);


  return (
    <FormikProvider value={formik}>
      <Form>
        <div className='card'>
          <div className='card-body'>
            <h3>Nuova attività di {work?.type?.label}</h3>
            <ActivityTopForm
              withTools={workConfig?.with_tools}
              tools={filter(tools, t => t.work_types.includes(work.type.value))}
            />
            <ActivityTableForm
              withReworked={workConfig?.with_reworked}
              hasMono={workConfig?.has_mono}
              hasDx={workConfig?.has_dx}
              hasSx={workConfig?.has_sx}
            />
            <ActivityBottomForm/>
            {formContent}
            <ActivityOperatorForm users={users} isMulti={workConfig?.is_multi}/>
            <div className='d-flex align-items-center justify-content-end gap-1'>
              <CancelButton handleClick={() => navigate('../')}/>
              <SubmitButton/>
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  )

}

export default CreateActivity
