import React from 'react';

const ProvaDiFrammentazioneHeader = ({ title, description, children, color }) => {

  return (
    <div className={`row text-${color} border-bottom p-2`}>
      <div className='col-3'>{description}</div>
      <div className='col-9'>{children}</div>
    </div>
  )

}

export default ProvaDiFrammentazioneHeader
