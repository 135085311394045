import React from 'react';
import { useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { lotService } from '../../../services/lotService';
import { Card, CardBody, Spinner } from 'reactstrap';
import IndustryLotDetail from './components/industryLotDetail';
import IndustryLotWorks from './components/IndustryLotWorks';

const IndustryLot = () => {

  const { id } = useParams();
  const { data: lot, loading: loadingLot } = useRequest(lotService.fetch, { defaultParams: [id] })

  return loadingLot
    ? <Spinner/>
    : <Card>
      <CardBody>
        <IndustryLotDetail lot={lot}/>
        <IndustryLotWorks lot={lot}/>
      </CardBody>
    </Card>


}

export default IndustryLot
