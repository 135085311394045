import React from 'react'
import loginLogo from "../../assets/images/logo/logo_sekurglass.jpg";
import background from "../../assets/images/auth_bg.jpg";

const NonAuthLayout = (props) => {
    return (
        <>
            <div className="vh-100 account-pages h-100 w-100 d-flex align-content-center justify-content-center" style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
                <div className="align-self-center" style={{width: '350px'}}>
                    <div className="card overflow-hidden">
                        <div className="card-body text-center">
                            <img src={loginLogo} height="32rem" className='mb-3'/>
                            <div className="p-2 text-start">
                                {props.children}
                            </div>
                        </div>
                    </div>
                    <div className="text-center text-white">
                        © {new Date().getFullYear()} Sekurglass
                    </div>
                </div>
            </div>
        </>
    )
}

export default NonAuthLayout
