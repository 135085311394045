import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {FormDisplay} from "../../../../../components/Custom/Form/Display/FormDisplay";
import LotNumberGetter from "./LotNumberGetter";
import FormGroup from "../../../../../components/Custom/Form/FormGroup";
import {InputField} from "../../../../../components/Custom/Form/InputField";
import CustomerPicker from "../../../../../components/Custom/Form/Pickers/CustomerPicker/CustomerPicker";
import {useFormikContext} from "formik";
import {useSanctum} from "react-sanctum";
import { FormCheckbox } from '../../../../../components/Custom/Form/FormCheckbox';

const LotDetailCardForm = () => {

    const {values, setValues, setFieldTouched} = useFormikContext()
    const {user} = useSanctum()

    return (
        <Card>
            <CardBody>

                <h3>Scheda di produzione</h3>
                <Row>
                    <Col lg={4}>
                        <FormDisplay label="Lotto di produzione">
                            <h3><LotNumberGetter name='lot_number'/></h3>
                        </FormDisplay>
                    </Col>
                    <Col lg={4}>
                        <FormGroup name="start_date" label="Data" required={true} inline={false}>
                            <InputField name="start_date" type="date"/>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup name="forecast_date" label="Data consegna" inline={false}>
                            <InputField name="forecast_date" type="date"/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <FormGroup name="total_qty" label="Quantità richiesta" required={true}
                                   inline={false}>
                            <InputField
                                name="total_qty"
                                type="number"
                            />
                            <div
                                className='d-flex align-content-center justify-content-start gap-1 my-1'>
                                <button className='btn btn-sm btn-info' tabIndex={-1} type="button"
                                        onClick={() => setValues({
                                            ...values,
                                            dx_qty: 0,
                                            sx_qty: 0
                                        })}>
                                    <i className="fa fa-window-restore"/>&nbsp;Reset quantità DX/SX
                                </button>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup name="dx_qty" label="Dei quali lato DX" required={true}
                                   inline={false}>
                            <InputField
                                name="dx_qty"
                                type="number"
                                onBlur={() => {
                                    setFieldTouched('dx_qty')
                                    const v = values.dx_qty || 0;
                                    setValues({
                                        ...values,
                                        dx_qty: v,
                                        sx_qty: v ? (values.total_qty || 0) - v : 0
                                    })
                                }}
                                onChange={(e) => {
                                    setFieldTouched('dx_qty')
                                    const v = e.target.value || 0;
                                    setValues({
                                        ...values,
                                        dx_qty: v,
                                        sx_qty: v ? (values.total_qty || 0) - v : 0
                                    })
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup name="sx_qty" label="Dei quali lato SX" required={true}
                                   inline={false}>
                            <InputField
                                name="sx_qty"
                                type="number"
                                onBlur={() => {
                                    setFieldTouched('sx_qty')
                                    const v = values.sx_qty || 0;
                                    setValues({
                                        ...values,
                                        dx_qty: v ? (values.total_qty || 0) - v : 0,
                                        sx_qty: v,
                                    })
                                }}
                                onChange={(e) => {
                                    setFieldTouched('sx_qty')
                                    const v = e.target.value || 0;
                                    setValues({
                                        ...values,
                                        dx_qty: v ? (values.total_qty || 0) - v : 0,
                                        sx_qty: v,
                                    })
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <FormGroup name="customer_id" label="Cliente" inline={false}
                                   required={true}>
                            <CustomerPicker name='customer_id' withCreate={false}
                                            onSelected={() => {
                                            }}/>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormGroup name="derogation" label="Deroga numero" inline={false}>
                            <InputField name="derogation"/>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormDisplay inline={false} label='Documento emesso'>
                            {user?.full_name}
                        </FormDisplay>
                    </Col>
                </Row>

                <Row>
                    <Col lg={4}>
                        <FormCheckbox
                          name="is_urgent"
                          label="Il lotto è urgente"
                        />
                    </Col>
                </Row>

                <FormGroup name="note" label="Note lotto" inline={false}>
                    <InputField name="note" component="textarea" rows={3}/>
                </FormGroup>
            </CardBody>
        </Card>
    )

}

export default LotDetailCardForm
