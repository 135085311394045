const tableOptions = {
    pagination: {
        buttonIcons: false,
        nextButtonText: ">",
        prevButtonText: "<"
    },
    defaultPerPageOption: 15,
    perPageOptions: [15, 30, 50, 100],
    table: {
        tableClass: "table table-hover align-middle table-row-bordered gy-5 gs-7",
        containerClass: "table-responsive"
    },
    labels: {
        search: "Cerca",
        loading: "Carica...",
        show: "Per pagina",
        total_result: "totali"
    },
}

export default tableOptions
