import React from 'react'
import FormGroup from "../../../../../../../../components/Custom/Form/FormGroup";
import { InputField } from "../../../../../../../../components/Custom/Form/InputField";
import { FormCheckbox } from "../../../../../../../../components/Custom/Form/FormCheckbox";
import SelectAllCheckboxesButton from '../../components/SelectAllCheckboxesButton';
import ReportCheckboxFormRows from '../../components/ReportCheckboxFormRows';
import TemperaReportsTable from './TemperaReportsTable';
import { useFormikContext } from 'formik';
import ProvaDiFrammentazioneForm from './components/form/ProvaDiFrammentazioneForm';
import ActivityCopyExtraDataButton from '../../components/ActivityCopyExtraDataButton';

const TemperaForm = ({ workConfig }) => {

  const { values, setValues } = useFormikContext()

  const handleUpdateValues = v => setValues(values => ({
    ...values,
    activity_data: {
      ...values.activity_data,
      oven_temperature: v.oven_temperature ?? '',
    }
  }))

  return (
    <>
      <div className='row'>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Temperatura del forno"
                     name="activity_data.oven_temperature"
                     required={true}
                     inline={false}>
            <InputField
              name="activity_data.oven_temperature"
              step={0.01}
              type="number"
              min={0}/>
          </FormGroup>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Eseguita frammentazione"
                     name="activity_data.with_fragmentation"
                     required={true}
                     inline={false}>
            <FormCheckbox
              name="activity_data.with_fragmentation"
              label="Si, è stata eseguita"
            />
          </FormGroup>
        </div>
        <div className='col-lg-4 col-md-6 d-flex align-items-end pb-3 justify-content-end'>
          <ActivityCopyExtraDataButton handleUpdateData={handleUpdateValues}/>
        </div>
      </div>

      {values.activity_data.with_fragmentation === true && <ProvaDiFrammentazioneForm/>}

      <TemperaReportsTable action={<SelectAllCheckboxesButton workConfig={workConfig}/>}>
      <ReportCheckboxFormRows workConfig={workConfig}/>
      </TemperaReportsTable>
    </>
  )
}

export default TemperaForm
