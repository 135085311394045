import api from '../helpers/axios';

const BASE_URL = '/tool-configs';

const fetchAll = (params) => api.get(BASE_URL, { params }).then(res => res.data);
const fetch = (id) => api.get(BASE_URL + '/' + id).then(res => res.data);
const execute = (id, params) => api.post(BASE_URL + '/' + id + '/execute-job', params).then(res => res.data);
const getFilesList = (id) => api.get(`${BASE_URL}/${id}/get-files-list`).then(res => res.data);

const toolConfigService = {
  fetchAll,
  fetch,
  execute,
  getFilesList
};

export default toolConfigService
