import React, {useEffect, useState} from 'react'
import {Formik} from "formik";
import {InputField} from "../../InputField";
import FormGroup from "../../FormGroup";
import {FormSelect} from "../../FormSelect";
import {ThicknessTypeArray} from "../../../../../constants/enums/ThicknessTypesEnum";
import thicknessService from "../../../../../services/thicknessService";
import GenericCanvasActions from "../../../UI/Canvas/GenericCanvasActions";
import GenericOffCanvas from "../../../UI/Canvas/GenericOffCanvas";
import {programForm} from "../../../../../pages/Production/SystemTables/Programs/programForm";
import {thicknessForm} from "./thicknessForm";
import {toast} from "react-toastify";

const ThicknessOffCanvas = ({isOpen, toggle, type, data, handleResponse}) => {

    const [initialValues, setInitialValues] = useState(thicknessForm.initialValues)
    const handleSubmit = (values) => data ? thicknessService.update(data.uuid, values) : thicknessService.store(values);

    useEffect(() => {
        data ? setInitialValues({
            value: data.value || undefined,
            description: data.description || '',
            type: data.type?.value || type || '',
            note: data.note || '',
        }) : setInitialValues({
            ...programForm.initialValues,
            type: type || ''
        })
    }, [data]);



    return (
        <GenericOffCanvas
            title={data ? 'Modifica ' + data.name : 'Crea spessore'}
            isOpen={isOpen}
            toggle={toggle}>
            <Formik
                initialValues={initialValues}
                validationSchema={thicknessForm.validation}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                        .then(res => {
                            toast.success('Operazione eseguita con successo')
                            handleResponse(res)
                            toggle(false)
                        })
                        .catch(err => toast.error(err.response.data.message))
                        .finally(() => setSubmitting(false))
                }}>
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <FormGroup name="value" label="Valore (mm)" required={true} inline={false}>
                            <InputField name="value" type="number" step={0.01} min={0.01}/>
                        </FormGroup>
                        <FormGroup name="description" label="Descrizione" required={false}
                                   inline={false}>
                            <InputField name="description" type="text"/>
                        </FormGroup>
                        <FormGroup name="type" label="Tiplogia" inline={false} required={true}>
                            <FormSelect
                                multiple={false}
                                name="type"
                                disabled={type}
                                onChange={(value) => setFieldValue('type', value)}
                                placeholder="Seleziona una tipologia di spessore"
                                options={ThicknessTypeArray}
                            />
                        </FormGroup>
                        <FormGroup name="note" label="Note" required={false} inline={false}>
                            <InputField name="note" component="textarea" rows={6}/>
                        </FormGroup>
                        <GenericCanvasActions toggle={toggle}/>
                    </form>
                )}
            </Formik>
        </GenericOffCanvas>
    )

}

export default ThicknessOffCanvas
