import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LotContext } from '../../../../LotWrapper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { filter, find } from 'lodash';
import activitiesHelper, { calculateFormControlReports, calculateTotal } from '../activitiesHelper';
import { Form, FormikProvider, useFormik } from 'formik';
import ActivityTopForm from '../components/ActivityTopForm';
import ActivityTableForm from '../components/ActivityTableForm';
import ActivityBottomForm from '../components/ActivityBottomForm';
import ActivityOperatorForm from '../components/ActivityOperatorForm';
import SubmitButton from '../../../../../../../components/Custom/Form/Buttons/SubmitButton';
import { activityService } from '../../../../../../../services/ActivityService';
import { toast } from 'react-toastify';
import { lotWorkService } from '../../../../../../../services/lotWorkService';
import CancelButton from '../../../../../../../components/Custom/Form/Buttons/CancelButton';
import { useUpdateEffect } from 'ahooks';

const EditActivity = () => {

  const location = useLocation();
  const { lot, mutateLot, tools, users } = useContext(LotContext)
  const { id, lot_work_id, activity_id } = useParams()
  const { activity } = location.state;
  const work = useMemo(() => find(lot.lot_works, lw => lw.uuid === lot_work_id), [lot_work_id]);
  const workConfig = useMemo(() => activitiesHelper.resolveFormConfigs(work), [lot_work_id]);
  const validationSchema = useMemo(() => activitiesHelper.resolveValidationSchema(work), [activity_id]);
  const formContent = activitiesHelper.resolveFormTemplate(work, workConfig)
  const navigate = useNavigate()
  const [total, setTotal] = useState(0)

  const handleSubmit = (values, { setSubmitting }) => activityService.update(id, lot_work_id, activity_id, values)
  .then(activityRes => {
    lotWorkService.fetch(id, lot_work_id)
    .then(lotWorkRes => mutateLot(lot => ({ ...lot, lot_works: lot.lot_works.map(lw => lw.uuid !== lot_work_id ? lw : lotWorkRes) })))
    .catch(err => toast.error(err.response.data.message))
    .finally(() => {
      toast.success('Lavorazione inviata con successo')
      navigate('../')
    })
    .catch(err => toast.error(err.response.data.message))
    .finally(() => setSubmitting(false))
  }).catch(err => toast.error(err.response.data.message))
  .finally(() => setSubmitting(false))

  const formik = useFormik({
    // in questo caso non passo lo user altrimenti me lo seleziona di default
    initialValues: activitiesHelper.resolveInitialValues(work, activity, undefined),
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  useEffect(() => {
      setTotal(t => calculateTotal(
        formik.values.manufactured_qty,
        formik.values.manufactured_dx_qty,
        formik.values.manufactured_sx_qty
      ))
    }, [formik.values.manufactured_qty, formik.values.manufactured_dx_qty, formik.values.manufactured_sx_qty]
  )

  // Salta il primo rendering
  useUpdateEffect(() => {
      formik.setFieldValue('control_reports',
        calculateFormControlReports(
          total,
          workConfig.checkbox_every,
          workConfig.checkbox_count,
          formik.values.control_reports
        ))
    }, [total]
  );


  return (
    <FormikProvider value={formik}>
      <Form>
        <div className='card-body'>
          <h3>Modifica attività di {work?.type?.label}</h3>
          <ActivityTopForm
            withTools={workConfig?.with_tools}
            tools={filter(tools, t => t.work_types.includes(work.type.value))}
          />
          <ActivityTableForm
            withReworked={workConfig?.with_reworked}
            hasMono={workConfig?.has_mono}
            hasDx={workConfig?.has_dx}
            hasSx={workConfig?.has_sx}
          />
          <ActivityBottomForm/>
          {formContent}
          <ActivityOperatorForm users={users} isMulti={workConfig?.is_multi}/>
          <div className='d-flex align-items-center justify-content-end gap-1'>
            <CancelButton handleClick={() => navigate('../')}/>
            <SubmitButton/>
          </div>
        </div>
      </Form>
    </FormikProvider>
  )
}

export default EditActivity
