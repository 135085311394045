import React from 'react';
import { NumberDisplay } from '../../../../../../components/Custom/Form/Display/NumberDisplay';

const LotShipmentDetailsHeader = ({ shipmentStatus }) => {

  return (
    <div className='row'>

      <div className="col-lg-4">
        <div className="p-3 bg-light bg-opacity-50 mb-3">
          <div className='row'>
            <div className='col-md-6'>
              Piani spediti
              <h5><NumberDisplay value={shipmentStatus?.shipped}/></h5>
            </div>
            <div className='col-md-6'>
              Piani spedibili
              <h5><NumberDisplay value={shipmentStatus?.shippable}/></h5>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="p-3 bg-light bg-opacity-50 mb-3">
          <div className='row'>
            <div className='col-md-6'>
              Destri spediti
              <h5><NumberDisplay value={shipmentStatus?.dx_shipped}/></h5>
            </div>
            <div className='col-md-6'>
              Destri spedibili
              <h5><NumberDisplay value={shipmentStatus?.dx_shippable}/></h5>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="p-3 bg-light bg-opacity-50 mb-3">
          <div className='row'>
            <div className='col-md-6'>
              Sinistri spediti
              <h5><NumberDisplay value={shipmentStatus?.sx_shipped}/></h5>
            </div>
            <div className='col-md-6'>
              Sinistri spedibili
              <h5><NumberDisplay value={shipmentStatus?.sx_shippable}/></h5>
            </div>
          </div>
        </div>
      </div>

    </div>
  )

}

export default LotShipmentDetailsHeader
