import React from 'react';
import ProvaDiFrammentazioneHeader from './ProvaDiFrammentazioneHeader';

const ProvaDiFrammentazione = ({ children }) => {

  return (
    <div>
      <h5>Scheda registrazione prove di frammentazione del prodotto finito</h5>
      <ProvaDiFrammentazioneHeader color='info' description='Prova tipo A'>
        Per ogni nuovo modello di prodotto (PROVE TIPO)<br/>
        Vengono eseguite le prove di frammentazione con relativa acquisizione digitale per
        ogni punto d'impatto descritto"
      </ProvaDiFrammentazioneHeader>
      <ProvaDiFrammentazioneHeader color='danger' description='Prova tipo B'>
        Alla partenza di ogni campagna/lotto di produzione<br/>
        Viene eseguita la prova di frammentazione per il punto d'impatto 3 con relativa
        acquisizione digitale
      </ProvaDiFrammentazioneHeader>
      <ProvaDiFrammentazioneHeader color='dark' description='Prova tipo C'>
        Durante la produzione di serie<br/>
        Viene eseguita la prova di frammentazione (senza acquisizione digitale) ogni 4 ore di
        produzione di serie oppure alla ripartenza dopo una variazione sui parametri di
        processo o dopo un guasto dell'impianto per il punto di impatto 3
      </ProvaDiFrammentazioneHeader>

      <div className='table-responsive'>
        <table className='table table-hover align-middle'>
          {children}
        </table>
      </div>
    </div>
  )

}

export default ProvaDiFrammentazione
