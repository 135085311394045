import { WorkTypesEnum } from '../../../../../../constants/enums/WorkTypesEnum';
import TaglioForm from './WorkTypes/Taglio/TaglioForm';
import React from 'react';
import TaglioShow from './WorkTypes/Taglio/TaglioShow';
import SerigrafiaLateraliShow from './WorkTypes/SerigrafiaLaterali/SerigrafiaLateraliShow';
import SerigrafiaLunottiFaseUnoShow from './WorkTypes/SerigrafiaLunotti/SerigrafiaLunottiFaseUno/SerigrafiaLunottiFaseUnoShow';
import SerigrafiaLunottiFaseTreShow from './WorkTypes/SerigrafiaLunotti/SerigrafiaLunottiFaseTre/SerigrafiaLunottiFaseTreShow';
import SerigrafiaLunottiFaseDueShow from './WorkTypes/SerigrafiaLunotti/SerigrafiaLunottiFaseDue/SerigrafiaLunottiFaseDueShow';
import TemperaShow from './WorkTypes/Tempera/TemperaShow';
import AttaccoTerminaliShow from './WorkTypes/AttaccoTerminali/AttaccoTerminaliShow';
import {
  setAttaccoTerminaliInitialValues,
  setControlloFacciataInitialValues,
  setForaturaELavaggioFaseDueInitialValues,
  setForaturaELavaggioFaseUnoInitialValues,
  setImballoDopoLavaggioInitialValues,
  setImballoDopoTemperaInitialValues,
  setLavaggioDppoSabbiaturaInitialValues, setLavaggioInitialValues,
  setMolaturaInitialValues,
  setPassaggioAlFornoInitialValues,
  setSabbiaturaInitialValues,
  setSerigrafiaLateraliInitialValues,
  setSerigrafiaLunottiFaseDueInitialValues,
  setSerigrafiaLunottiFaseTreInitialValues,
  setSerigrafiaLunottiFaseUnoInitialValues,
  setTaglioInitialValues,
  setTemperaInitialValues
} from './activitiesInitialValues.config';
import {
  controlloFacciataValidation,
  foraturaELavaggioFaseDueValidation,
  foraturaELavaggioFaseUnoValidation,
  imballoDopoLavaggioValidation,
  imballoDopoTemperaValidation,
  lavaggioDopoSabbiaturaValidation,
  lavaggioValidation,
  molaturaValidation,
  passaggioAlFornoValidation,
  sabbiaturaValidation,
  serigrafiaLateraliValidation,
  serigrafiaLunottiFaseDueValidation,
  serigrafiaLunottiFaseTreValidation,
  serigrafiaLunottiFaseUnoValidation,
  taglioValidation,
  temperaValidation
} from './activitiesValidations.config';
import MolaturaForm from './WorkTypes/Molatura/MolaturaForm';
import MolaturaShow from './WorkTypes/Molatura/MolaturaShow';
import SerigrafiaLateraliForm from './WorkTypes/SerigrafiaLaterali/SerigrafiaLateraliForm';
import SerigrafiaLunottiFaseUnoForm from './WorkTypes/SerigrafiaLunotti/SerigrafiaLunottiFaseUno/SerigrafiaLunottiFaseUnoForm';
import SerigrafiaLunottiFaseDueForm from './WorkTypes/SerigrafiaLunotti/SerigrafiaLunottiFaseDue/SerigrafiaLunottiFaseDueForm';
import SerigrafiaLunottiFaseTreForm from './WorkTypes/SerigrafiaLunotti/SerigrafiaLunottiFaseTre/SerigrafiaLunottiFaseTreForm';
import TemperaForm from './WorkTypes/Tempera/TemperaForm';
import AttaccoTerminaliForm from './WorkTypes/AttaccoTerminali/AttaccoTerminaliForm';
import ForaturaELavaggioShow from './WorkTypes/ForaturaELavaggio/ForaturaELavaggioShow';
import ForaturaELavaggioForm from './WorkTypes/ForaturaELavaggio/ForaturaELavaggioForm';
import LavaggioForm from './WorkTypes/Lavaggio/LavaggioForm';
import LavaggioShow from './WorkTypes/Lavaggio/LavaggioShow';
// import ForaturaELavaggioForm from './WorkTypes/ForaturaELavaggio/ForaturaELavaggioForm';
// import LavaggioForm from './WorkTypes/Lavaggio/LavaggioForm';
// import LavaggioShow from './WorkTypes/Lavaggio/LavaggioShow';


const resolveInitialValues = ({ type = undefined }, activity = undefined, user = undefined) => {

  switch (type?.value) {
    case WorkTypesEnum.taglio:
      return setTaglioInitialValues(activity, user)
    case WorkTypesEnum.molatura:
      return setMolaturaInitialValues(activity, user)
    case WorkTypesEnum.foratura_e_lavaggio_fase_uno:
      return setForaturaELavaggioFaseUnoInitialValues(activity, user)
    case WorkTypesEnum.foratura_e_lavaggio_fase_due:
      return setForaturaELavaggioFaseDueInitialValues(activity, user)
    case WorkTypesEnum.lavaggio:
      return setLavaggioInitialValues(activity, user)
    case WorkTypesEnum.imballo_dopo_lavaggio:
      return setImballoDopoLavaggioInitialValues(activity, user)
    case WorkTypesEnum.sabbiatura:
      return setSabbiaturaInitialValues(activity, user)
    case WorkTypesEnum.lavaggio_dopo_sabbiatura:
      return setLavaggioDppoSabbiaturaInitialValues(activity, user)
    case WorkTypesEnum.serigrafia_laterali:
      return setSerigrafiaLateraliInitialValues(activity, user)
    case WorkTypesEnum.controllo_facciata:
      return setControlloFacciataInitialValues(activity, user)
    case WorkTypesEnum.serigrafia_lunotti_fase_uno:
      return setSerigrafiaLunottiFaseUnoInitialValues(activity, user)
    case WorkTypesEnum.serigrafia_lunotti_fase_due:
      return setSerigrafiaLunottiFaseDueInitialValues(activity, user)
    case WorkTypesEnum.serigrafia_lunotti_fase_tre:
      return setSerigrafiaLunottiFaseTreInitialValues(activity, user)
    case WorkTypesEnum.passaggio_forno:
      return setPassaggioAlFornoInitialValues(activity, user)
    case WorkTypesEnum.tempera:
      return setTemperaInitialValues(activity, user)
    case WorkTypesEnum.imballo_dopo_tempera:
      return setImballoDopoTemperaInitialValues(activity, user)
    case WorkTypesEnum.attacco_terminali:
      return setAttaccoTerminaliInitialValues(activity, user)
    default:
      return {}
  }

}

const resolveValidationSchema = ({ type = undefined }) => {

  switch (type?.value) {
    case WorkTypesEnum.taglio:
      return taglioValidation
    case WorkTypesEnum.molatura:
      return molaturaValidation
    case WorkTypesEnum.foratura_e_lavaggio_fase_uno:
      return foraturaELavaggioFaseUnoValidation
    case WorkTypesEnum.foratura_e_lavaggio_fase_due:
      return foraturaELavaggioFaseDueValidation
    case WorkTypesEnum.lavaggio:
      return lavaggioValidation
    case WorkTypesEnum.imballo_dopo_lavaggio:
      return imballoDopoLavaggioValidation
    case WorkTypesEnum.sabbiatura:
      return sabbiaturaValidation
    case WorkTypesEnum.lavaggio_dopo_sabbiatura:
      return lavaggioDopoSabbiaturaValidation
    case WorkTypesEnum.serigrafia_laterali:
      return serigrafiaLateraliValidation
    case WorkTypesEnum.controllo_facciata:
      return controlloFacciataValidation
    case WorkTypesEnum.serigrafia_lunotti_fase_uno:
      return serigrafiaLunottiFaseUnoValidation
    case WorkTypesEnum.serigrafia_lunotti_fase_due:
      return serigrafiaLunottiFaseDueValidation
    case WorkTypesEnum.serigrafia_lunotti_fase_tre:
      return serigrafiaLunottiFaseTreValidation
    case WorkTypesEnum.passaggio_forno:
      return passaggioAlFornoValidation
    case WorkTypesEnum.tempera:
      return temperaValidation
    case WorkTypesEnum.imballo_dopo_tempera:
      return imballoDopoTemperaValidation
    case WorkTypesEnum.attacco_terminali:
      return controlloFacciataValidation
    default:
      return {}
  }

}


// OK
const resolveShowProductionDataTemplate = ({ type = undefined }, activity) => {

  let workConfig = activitiesHelper.resolveFormConfigs({ type });

  switch (type?.value) {
    case WorkTypesEnum.taglio:
      return <TaglioShow activity={activity} workConfig={workConfig}/>
    case WorkTypesEnum.molatura:
      return <MolaturaShow activity={activity} workConfig={workConfig}/>
    case WorkTypesEnum.foratura_e_lavaggio_fase_uno:
    case WorkTypesEnum.foratura_e_lavaggio_fase_due:
      return <ForaturaELavaggioShow activity={activity} workConfig={workConfig}/>
    case WorkTypesEnum.lavaggio:
      return <LavaggioShow activity={activity} workConfig={workConfig}/>
    case WorkTypesEnum.serigrafia_laterali:
      return <SerigrafiaLateraliShow activity={activity} workConfig={workConfig}/>
    case WorkTypesEnum.serigrafia_lunotti_fase_uno:
      return <SerigrafiaLunottiFaseUnoShow activity={activity} workConfig={workConfig}/>
    case WorkTypesEnum.serigrafia_lunotti_fase_due:
      return <SerigrafiaLunottiFaseDueShow activity={activity} workConfig={workConfig}/>
    case WorkTypesEnum.serigrafia_lunotti_fase_tre:
      return <SerigrafiaLunottiFaseTreShow activity={activity} workConfig={workConfig}/>
    case WorkTypesEnum.tempera:
      return <TemperaShow activity={activity} workConfig={workConfig}/>
    case WorkTypesEnum.attacco_terminali:
      return <AttaccoTerminaliShow activity={activity} workConfig={workConfig}/>
    default:
      return <></>
  }

}


const resolveFormTemplate = ({ type = undefined }, workConfig) => {

  switch (type?.value) {
    case WorkTypesEnum.taglio:
      return <TaglioForm workConfig={workConfig}/>
    case WorkTypesEnum.molatura:
      return <MolaturaForm workConfig={workConfig}/>
    case WorkTypesEnum.foratura_e_lavaggio_fase_uno:
    case WorkTypesEnum.foratura_e_lavaggio_fase_due:
      return <ForaturaELavaggioForm workConfig={workConfig}/>
    case WorkTypesEnum.lavaggio:
    return <LavaggioForm workConfig={workConfig}/>
    case WorkTypesEnum.imballo_dopo_lavaggio:
    case WorkTypesEnum.sabbiatura:
    case WorkTypesEnum.lavaggio_dopo_sabbiatura:
    case WorkTypesEnum.controllo_facciata:
    case WorkTypesEnum.passaggio_forno:
    case WorkTypesEnum.imballo_dopo_tempera:
      return <></>
    case WorkTypesEnum.serigrafia_laterali:
      return <SerigrafiaLateraliForm workConfig={workConfig}/>
    case WorkTypesEnum.serigrafia_lunotti_fase_uno:
      return <SerigrafiaLunottiFaseUnoForm workConfig={workConfig}/>
    case WorkTypesEnum.serigrafia_lunotti_fase_due:
      return <SerigrafiaLunottiFaseDueForm workConfig={workConfig}/>
    case WorkTypesEnum.serigrafia_lunotti_fase_tre:
      return <SerigrafiaLunottiFaseTreForm workConfig={workConfig}/>
    case WorkTypesEnum.tempera:
      return <TemperaForm workConfig={workConfig}/>
    case WorkTypesEnum.attacco_terminali:
      return <AttaccoTerminaliForm workConfig={workConfig}/>
  }

}

const resolveFormConfigs = ({ type = undefined }) => {

  switch (type?.value) {
    case WorkTypesEnum.taglio:
      return {
        with_tools: true,
        with_reworked: false,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: false,
        checkbox_every: 50,
        checkbox_count: 2
      }
    case WorkTypesEnum.molatura:
      return {
        with_tools: true,
        with_reworked: true,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: false,
        checkbox_every: 50,
        checkbox_count: 3
      }
    case WorkTypesEnum.sabbiatura:
      return {
        with_tools: true,
        with_reworked: true,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: false,
        checkbox_every: 0,
        checkbox_count: 0
      }
    case WorkTypesEnum.attacco_terminali:
      return {
        with_tools: true,
        with_reworked: true,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: false,
        checkbox_every: 30,
        checkbox_count: 2
      }
    case WorkTypesEnum.foratura_e_lavaggio_fase_uno:
    case WorkTypesEnum.foratura_e_lavaggio_fase_due:
      return {
        with_tools: true,
        with_reworked: true,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: true,
        checkbox_every: 50,
        checkbox_count: 3
      }
    case WorkTypesEnum.lavaggio:
      return {
        with_tools: true,
        with_reworked: true,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: true,
        checkbox_every: 50,
        checkbox_count: 1
      }
    case WorkTypesEnum.lavaggio_dopo_sabbiatura:
      return {
        with_tools: true,
        with_reworked: true,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: true,
        checkbox_every: 50,
        checkbox_count: 0
      }
    case WorkTypesEnum.serigrafia_laterali:
      return {
        with_tools: true,
        with_reworked: true,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: true,
        checkbox_every: 50,
        checkbox_count: 2
      }
    case WorkTypesEnum.serigrafia_lunotti_fase_uno:
    case WorkTypesEnum.serigrafia_lunotti_fase_due:
    case WorkTypesEnum.serigrafia_lunotti_fase_tre:
      return {
        with_tools: true,
        with_reworked: true,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: true,
        checkbox_every: 50,
        checkbox_count: 2
      }
    case WorkTypesEnum.tempera:
      return {
        with_tools: true,
        with_reworked: true,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: true,
        checkbox_every: 30,
        checkbox_count: 6
      }
    case WorkTypesEnum.controllo_facciata:
    case WorkTypesEnum.imballo_dopo_tempera:
    case WorkTypesEnum.imballo_dopo_lavaggio:
      return {
        with_tools: false,
        with_reworked: true,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: false,
        checkbox_every: 0
      }
    case WorkTypesEnum.passaggio_forno:
      return {
        with_tools: false,
        with_reworked: true,
        has_mono: true,
        has_dx: true,
        has_sx: true,
        is_multi: true,
        checkbox_every: 0
      }
  }

}

export const parseBool = value => {
  if (value === undefined || value === null)
    return undefined;
  return value ? '1' : '0';
}

export const calculateTotal = (qty = '', dxQty = '', sxQty = '') => {
  let totalQty = parseInt(qty) + parseInt(dxQty) + parseInt(sxQty)
  return isNaN(totalQty) ? 0 : totalQty;
}

export const calculateFormControlReports = (total, qtyRate, checkboxCount, controlReports = []) => {

  if (qtyRate === 0)
    return [];

  let rowNumber = Math.ceil(total / qtyRate);

  rowNumber = total > 1 ? rowNumber + 1 : rowNumber;

  const arr = Array(rowNumber)
  .fill(Array(checkboxCount)
  .fill(false));

  return arr.map((row, rowIndex) =>
    row.map((col, colIndex) => controlReports[rowIndex]
      ? controlReports[rowIndex][colIndex]
      : false
    )
  )

}


const activitiesHelper = {
  resolveInitialValues,
  resolveValidationSchema,
  resolveShowProductionDataTemplate,
  resolveFormTemplate,
  resolveFormConfigs
}

export default activitiesHelper
