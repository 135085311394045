import React from "react";
import FormGroup from "../../../../components/Custom/Form/FormGroup";
import { InputField } from "../../../../components/Custom/Form/InputField";
import { FormSelect } from "../../../../components/Custom/Form/FormSelect";
import { LotStatusEnumArray } from "../../../../constants/enums/LosStatesEnum";
import { Form, Formik } from "formik";
import ItemPicker from "../../../../components/Custom/Form/Pickers/ItemPicker/ItemPicker";
import { useSanctum } from 'react-sanctum';
import SearchButton from '../../../../components/Custom/Form/Buttons/SearchButton';

const LotFilters = ({ onChangeFilters, filters, handleResetFilters }) => {

  const { user } = useSanctum()

  return (
    <Formik
      initialValues={filters}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        onChangeFilters(values)
        setSubmitting(false)
      }}>
      {({ handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className='card card-body'>
            <div className='row'>
              <div className='col-lg-3 col-md-6'>
                <FormGroup name="filter.lot_number" inline={false}>
                  <InputField name="filter.lot_number" placeholder="Nr. Lotto" type="text"/>
                </FormGroup>
              </div>
              <div className='col-lg-3 col-md-6'>
                <FormGroup name="filter.item_id" inline={false}>
                  <ItemPicker
                    name="filter.item_id"
                    onSelected={(item) => setFieldValue('filter.item_id', item?.id)}
                  />
                </FormGroup>
              </div>

              <div className='col-lg-3 col-md-6'>
                {user.can_manage_production_module ?
                  <FormGroup name="filter.state" inline={false}>
                    <FormSelect name="filter.state"
                                placeholder="Seleziona uno stato"
                                multiple={true}
                                options={LotStatusEnumArray}
                    />
                  </FormGroup>
                  : <FormGroup name="filter.description" inline={false}>
                    <InputField name="filter.description" placeholder="Descrizione" type="text"/>
                  </FormGroup>
                }
              </div>
              <div className='col-lg-3 col-md-6'>
                <FormGroup name="filter.thickness" inline={false}>
                  <InputField name="filter.thickness" type="text" placeholder="Spessore"/>
                </FormGroup>
              </div>
              <div className='col-lg-3 col-md-6'>
                <FormGroup name="filter.color" inline={false}>
                  <InputField name="filter.color" type="text" placeholder="Colore"/>
                </FormGroup>
              </div>
              <div className='col-lg-3 col-md-6'>
                <FormGroup name="filter.curvature" inline={false}>
                  <InputField name="filter.curvature" placeholder="Curvatura" type="text"/>
                </FormGroup>
              </div>
              <div className='col d-flex align-items-center justify-content-end gap-2'>
                <FormGroup name="filter.curvature" inline={false}>
                  <SearchButton/>
                </FormGroup>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

}

export default LotFilters;
