import React from 'react'
import clsx from "clsx";
import {Badge} from "reactstrap"

const StateBadge = ({ value, className, prefix, suffix, ...rest }) => {

    const { color, label } = value;

    return (
        <Badge color={color} className={clsx("mx-1 p-2", className)} {...rest}>
            {prefix}
            {label}
            {suffix}
        </Badge>
    );

}

export default StateBadge