import * as Yup from "yup";
import { format, parseJSON } from "date-fns";

const validation = Yup.object().shape({
  total_qty: Yup.number("Numero non valido").min(0).required("Quantità totale obbligatoria"),
  dx_qty: Yup.number("Numero non valido").min(0, "Numero non valido").test("max",
    "Destri e sinistri devono coincidere con la quantità totale",
    (value, context) => {
      const { total_qty, sx_qty } = context.parent;
      if (sx_qty === 0 && value === 0)
        return true;
      return sx_qty + value === total_qty
    }).required("Quantità destri obbligatoria"),
  sx_qty: Yup.number("Numero non valido").min(0, "Numero non valido").test("max",
    "Destri e sinistri devono coincidere con la quantità totale",
    (value, context) => {
      const { total_qty, dx_qty } = context.parent;
      if (dx_qty === 0 && value === 0)
        return true;
      return dx_qty + value <= total_qty
    }).required("Quantità sinistri obbligatoria"),
  forecast_date: Yup.date("Data non valida").nullable().notRequired(),
  derogation: Yup.string("Deroga non valida").max(255, "Al massimo 255 caratteri").nullable().notRequired(),
  note: Yup.string("Note non valide").nullable().notRequired(),
  is_urgent: Yup.boolean().nullable().notRequired()
})

const initialValues = {
  total_qty: 0,
  dx_qty: 0,
  sx_qty: 0,
  forecast_date: '',
  derogation: '',
  note: '',
  color_id: '',
  thickness_id: '',
  is_urgent: false
}


const setInitialValues = lot => {
  return {
    total_qty: lot?.total_qty || 0,
    dx_qty: lot?.dx_qty || 0,
    sx_qty: lot?.sx_qty || 0,
    forecast_date: lot?.forecast_date ? format(parseJSON(lot.forecast_date), 'yyyy-MM-dd') : '',
    derogation: lot?.derogation || '',
    note: lot?.note || '',
    color_id: lot?.lot_data?.color?.id,
    thickness_id: lot?.lot_data?.thickness?.id,
    is_urgent: lot?.is_urgent
  }
}


export const formConfig = {
  validation,
  initialValues,
  setInitialValues
}
