import React, {useEffect, useState} from 'react'
import {Form, Formik} from "formik";
import {InputField} from "../../../../components/Custom/Form/InputField";
import FormGroup from "../../../../components/Custom/Form/FormGroup";

import GenericOffCanvas from "../../../../components/Custom/UI/Canvas/GenericOffCanvas";
import GenericCanvasActions from "../../../../components/Custom/UI/Canvas/GenericCanvasActions";
import templateService from "../../../../services/templateService";
import {templateForm as teamplateForm} from "./templateForm";
import {FormSelect} from "../../../../components/Custom/Form/FormSelect";
import {WorkTypeArray} from "../../../../constants/enums/WorkTypesEnum";
import DeleteButton from "../../../../components/Custom/Form/Buttons/DeleteButton";
import {toast} from "react-toastify";

const TemplateOffCanvas = ({isOpen, toggle, data, handleResponse}) => {

    const [initialValues, setInitialValues] = useState(teamplateForm.initialValues)
    const handleSubmit = (values) => data ? templateService.update(data.uuid, values) : templateService.store(values);
    const handleDelete = () => templateService.destroy(data.uuid)
        .then(res => {
            toast.success('Operazione eseguita con successo')
            handleResponse(undefined)
            toggle(false)
        })
        .catch(err => toast.error(err.response.data.message))

    useEffect(() => {
        data ? setInitialValues({
            name: data.name || '',
            work_types: data.work_types ? data.work_types.map(wt => wt.value) : [],
            description: data.description || '',
            position: data.position || '',
            container: data.container || '',
            sample: data.sample || '',
            note: data.note || '',
        }) : setInitialValues(teamplateForm.initialValues)
    }, [data]);


    return <GenericOffCanvas
        title={data ? 'Modifica ' + data.name : 'Crea dima'}
        isOpen={isOpen}
        toggle={toggle}>
        <Formik initialValues={initialValues}
                validationSchema={teamplateForm.validation}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                        .then(res => {
                            toast.success('Operazione eseguita con successo')
                            handleResponse(res)
                            toggle(false)
                        })
                        .catch(err => toast.error(err.response.data.message))
                        .finally(() => setSubmitting(false))
                }}>
            {({setFieldValue}) => (
                <Form>
                    <FormGroup name="name" label="Nome" required={true} inline={false}>
                        <InputField name="name" type="text" maxLength={100}/>
                    </FormGroup>
                    <FormGroup name="work_type" label="Tipo di lavorazione" inline={false} required={true}>
                        <FormSelect
                            multiple={true}
                            name="work_types"
                            onChange={(value) => setFieldValue('work_types', value)}
                            placeholder="Seleziona un tipo di lavorazione"
                            options={WorkTypeArray}
                        />
                    </FormGroup>
                    <FormGroup name="description" label="Descrizione" required={false} inline={false}>
                        <InputField name="description" type="text"/>
                    </FormGroup>
                    <FormGroup name="position" label="Posizione" required={false} inline={false}>
                        <InputField name="position" type="text"/>
                    </FormGroup>
                    <FormGroup name="container" label="Contenitore" required={false} inline={false}>
                        <InputField name="container" type="text"/>
                    </FormGroup>
                    <FormGroup name="sample" label="Campione" required={false} inline={false}>
                        <InputField name="sample" type="text"/>
                    </FormGroup>
                    <FormGroup name="note" label="Note" required={false} inline={false}>
                        <InputField name="note" component="textarea" rows={6}/>
                    </FormGroup>
                    <GenericCanvasActions toggle={toggle}>
                        {data?.uuid &&
                            <DeleteButton
                                handleClick={() => handleDelete()}
                                message={data?.items_works_count ? 'Ci sono articoli collegati, Vuoi elimnarlo comunnque?' : null}
                            />
                        }
                    </GenericCanvasActions>
                </Form>
            )}
        </Formik>
    </GenericOffCanvas>

}

export default TemplateOffCanvas
