import React from 'react'

const NewButton = ({ children, disabled, handleClick }) =>
  <button
    className='btn btn-light'
    disabled={disabled || false}
    onClick={handleClick}
    type="button"
  >
    <i className="fa fa-arrow-left"/>&nbsp;{children ?? "Annulla"}
  </button>

export default NewButton
