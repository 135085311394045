import React from 'react'
import FormGroup from "../../../../../../../../components/Custom/Form/FormGroup";
import { InputField } from "../../../../../../../../components/Custom/Form/InputField";
import SelectAllCheckboxesButton from '../../components/SelectAllCheckboxesButton';
import ReportCheckboxFormRows from '../../components/ReportCheckboxFormRows';
import AttaccoTerminaliReportsTable from './AttaccoTerminaliReportsTable';
import { useFormikContext } from 'formik';
import ActivityCopyExtraDataButton from '../../components/ActivityCopyExtraDataButton';

const AttaccoTerminaliForm = ({ workConfig }) => {

  const { setValues } = useFormikContext()
  const handleUpdateValues = v => setValues(values => ({
    ...values,
    activity_data: {
      ...values.activity_data,
      terminals: v.terminals ?? '',
      terminals_supplier: v.terminals_supplier ?? '',
      terminals_lot: v.terminals_lot ?? '',
      current_load: v.current_load ?? '',
    }
  }))


  return (
    <>
      <div className='row'>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Terminali utilizzati"
                     name="activity_data.terminals"
                     required={true}
                     inline={false}>
            <InputField name="activity_data.terminals"/>
          </FormGroup>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Fornitore terminali"
                     name="activity_data.terminals_supplier"
                     required={true}
                     inline={false}>
            <InputField name="activity_data.terminals_supplier"/>
          </FormGroup>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Lotto terminali N°" name="activity_data.terminals_lot"
                     required={true}
                     inline={false}>
            <InputField name="activity_data.terminals_lot"/>
          </FormGroup>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Assorbimento a 12V (Ampere)"
                     name="activity_data.current_load"
                     required={true}
                     inline={false}>
            <InputField
              type="number"
              step={0.01}
              name="activity_data.current_load"
            />
          </FormGroup>
        </div>
        <div className='col-lg-8 col-md-6 d-flex align-items-end pb-3 justify-content-end'>
          <ActivityCopyExtraDataButton handleUpdateData={handleUpdateValues}/>
        </div>
      </div>


      <AttaccoTerminaliReportsTable action={<SelectAllCheckboxesButton workConfig={workConfig}/>}>
        <ReportCheckboxFormRows workConfig={workConfig}/>
      </AttaccoTerminaliReportsTable>

    </>
  )
}

export default AttaccoTerminaliForm
