import api from '../helpers/axios';

const BASE_URL = '/item-categories';
const fetchAll = () => api.get(BASE_URL).then(res => res.data);
const fetch = (id) => api.get(BASE_URL + '/' + id).then(res => res.data);
const store = (attributes = {}) => api.post(BASE_URL, attributes).then(res => res.data);
const update = (id, attributes = {}) => api.put(BASE_URL + '/' + id, attributes).then(res => res.data);
const destroy = (id) => api.delete(BASE_URL + '/' + id);
const selectable = () => api.get('/selectable' + BASE_URL).then(res => res.data);


const itemCategoryService = {
  fetchAll,
  selectable,
  fetch,
  store,
  update,
  destroy
};

export default itemCategoryService;
