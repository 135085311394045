import * as Yup from "yup";

const validation = Yup.object().shape({
    name: Yup.string().max(100, "Max 100 caratteri").required("Obbligatorio"),
    description: Yup.string().max(255, "Max 255 caratteri").notRequired(),
    note: Yup.string().notRequired()
})

const initialValues = {
    name: '',
    description: '',
    note: '',
}
export const brandForm = {
    validation,
    initialValues
}