import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { lotService } from "../../../services/lotService";
import toolService from "../../../services/toolService";
import userService from "../../../services/userService";
import templateService from '../../../services/templateService';
import programService from '../../../services/programService';
import LotHeader from './Lot/components/LotHeader/LotHeader';
import LotDetailsCanvas from './Lot/components/LotDetailsCanvas/LotDetailsCanvas';
import LotWorksCanvas from './Lot/components/LotWorksCanvas/LotWorksCanvas';
import LotStatsCanvas from './Lot/components/LotStatsCanvas/LotStatsCanvas';
import { useSanctum } from 'react-sanctum';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

export const LotContext = React.createContext({});
const LotWrapper = () => {

  const { id } = useParams();
  const { user } = useSanctum()

  const [editLotCanvasOpen, setEditLotCanvasOpen] = useState(false);
  const [lotWorksCanvasOpen, setLotWorksCanvasOpen] = useState(false);
  const [lotLotStatsCanvasOpen, setLotStatsCanvasOpen] = useState(false);

  const { data: lot, loading: loadingLot, mutate: mutateLot } = useRequest(lotService.fetch, { defaultParams: [id] })
  const {
    data: states = { state: undefined, available_states: [] },
    loading: loadingStates,
    run: fetchStates
  } = useRequest(lotService.fetchState, { defaultParams: [id] })
  const { data: templates = [] } = useRequest(templateService.selectable)
  const { data: programs = [] } = useRequest(programService.selectable)
  const { data: tools = [] } = useRequest(toolService.selectable)
  const { data: users = [] } = useRequest(userService.selectable)

  const handleLotUpdate = res => {
    toast.success('Operazione eseguita con successo')
    setEditLotCanvasOpen(false)
    mutateLot(res)
  }

  const handleLotWorksUpdate = res => {
    toast.success('Lavorazioni aggiornate con successo')
    setLotWorksCanvasOpen(false)
    mutateLot({ ...lot, lot_works: res })
  }

  const contextValue = {
    lot,
    templates,
    programs,
    loadingLot,
    mutateLot,
    states,
    loadingStates,
    fetchStates,
    tools,
    users
  }

  return (
    <LotContext.Provider value={contextValue}>
      {loadingLot
        ? <Spinner/>
        : <>
            <LotHeader
              setEditLotCanvasOpen={setEditLotCanvasOpen}
              setLotWorksCanvasOpen={setLotWorksCanvasOpen}
              setLotStatsCanvasOpen={setLotStatsCanvasOpen}
            />
          {user.can_manage_production_module && <>
            <LotDetailsCanvas
              lot={lot}
              handleUpdate={handleLotUpdate}
              isOpen={editLotCanvasOpen}
              toggle={setEditLotCanvasOpen}
            />
            <LotWorksCanvas
              lot={lot}
              handleUpdateLotWorks={handleLotWorksUpdate}
              isOpen={lotWorksCanvasOpen}
              toggle={setLotWorksCanvasOpen}
            />
            <LotStatsCanvas
              lot={lot}
              isOpen={lotLotStatsCanvasOpen}
              toggle={setLotStatsCanvasOpen}
            />
          </>
          }
          <Outlet/>
        </>
      }
    </LotContext.Provider>
  )
    ;

}

export default LotWrapper;
