import { lotService } from "./lotService";
import { lotWorkService } from "./lotWorkService";
import fileDownload from "js-file-download";
import api from '../helpers/axios';

const BASE_URL = '/activities';


const fetchFromDB = params => api.get(BASE_URL, { params }).then(res => res.data);
const fetchAll = (lotId, lotWorkId, filters) => api.get(`${lotService.BASE_URL}/${lotId}${lotWorkService.BASE_URL}/${lotWorkId}${BASE_URL}`,
  { params: filters }).then(res => res.data);
const store = (lotId, lotWorkId, attributes) => api.post(`${lotService.BASE_URL}/${lotId}${lotWorkService.BASE_URL}/${lotWorkId}${BASE_URL}`, attributes).then(
  res => res.data)
const update = (lotId, lotWorkId, activityId, attributes) => api.put(`${lotService.BASE_URL}/${lotId}${lotWorkService.BASE_URL}/${lotWorkId}${BASE_URL}/${activityId}`,
  attributes).then(res => res.data)
const toggleCheck = (lotId, lotWorkId, activityId) => api.post(`${lotService.BASE_URL}/${lotId}${lotWorkService.BASE_URL}/${lotWorkId}${BASE_URL}/${activityId}/toggle-checked`)
.then(res => res.data)
const destroy = (lotId, lotWorkId, activityId) => api.delete(`${lotService.BASE_URL}/${lotId}${lotWorkService.BASE_URL}/${lotWorkId}${BASE_URL}/${activityId}`)
.then(res => res.data.data)
const getActivityData =  (lotId, lotWorkId) => api.get(`${lotService.BASE_URL}/${lotId}${lotWorkService.BASE_URL}/${lotWorkId}/get-activity-data`)
.then(res => res.data)
const fetchAllMedia = (lotId, lotWorkId, activityId) => api.get(`${lotService.BASE_URL}/${lotId}${lotWorkService.BASE_URL}/${lotWorkId}${BASE_URL}/${activityId}/media`)
.then(res => res.data)
const addMedia = (lotId, lotWorkId, activityId, file) => {
  let formData = new FormData();
  formData.append("file", file);
  return api.post(`${lotService.BASE_URL}/${lotId}${lotWorkService.BASE_URL}/${lotWorkId}${BASE_URL}/${activityId}/media`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  }).then(res => res.data)
}
const downloadMedia = (lotId, lotWorkId, activityId, mediaId, filename) => api.get(`${lotService.BASE_URL}/${lotId}${lotWorkService.BASE_URL}/${lotWorkId}${BASE_URL}/${activityId}/media/${mediaId}`,
  { responseType: 'blob' })
.then(res => fileDownload(res.data, filename));
const deleteMedia = (lotId, lotWorkId, activityId, mediaId) => api.delete(`${lotService.BASE_URL}/${lotId}${lotWorkService.BASE_URL}/${lotWorkId}${BASE_URL}/${activityId}/media/${mediaId}`)
.then(res => res.data)

export const activityService = {
  fetchFromDB,
  fetchAll,
  store,
  update,
  toggleCheck,
  destroy,
  getActivityData,
  fetchAllMedia,
  addMedia,
  downloadMedia,
  deleteMedia,
  BASE_URL
};
