import React from 'react';
import IndustryLotsWrapper from '../../pages/Industry/IndustryLots/IndustryLotsWrapper';
import IndustryLots from '../../pages/Industry/IndustryLots/IndustryLots';
import IndustryLot from '../../pages/Industry/IndustryLots/IndustryLot';

const industryLotsRoutes = {
  path: '/industry-lots/*',
  breadcrumb: "Lotti Industria 4.0",
  element: <IndustryLotsWrapper/>,
  children: [
    {
      path: '',
      element: <IndustryLots/>,
    },
    {
      path: ':id',
      breadcrumb: "Lotto",
      element: <IndustryLot/>
    }
  ]
}

export default industryLotsRoutes
