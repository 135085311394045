import React from 'react'
import { FormDisplay } from "../../../../../../../../components/Custom/Form/Display/FormDisplay";
import TaglioReportsTable from './TaglioReportsTable';
import ReportCheckboxShowRows from '../../components/ReportCheckboxShowRows';

const TaglioShow = ({ activity, workConfig }) => {


  return (
    <>
      <div className='row'>
        <div className='col-lg-4 col-md-6'>
          <FormDisplay label="Lastra pervenuta da">
            {activity?.activity_data?.slab_manufacturer}
          </FormDisplay>
        </div>
        <div className='col-lg-4 col-md-6'>
          <FormDisplay label="C/lotto n°">
            {activity?.activity_data?.c_lot_number}
          </FormDisplay>
        </div>
        <div className='col-lg-4 col-md-6'>
          <FormDisplay label="Quantità lastre utilizzate">
            {activity?.activity_data?.slab_number}
          </FormDisplay>
        </div>
        <div className='col-lg-4 col-md-6'>
          <FormDisplay label="Dimensione della lastra utilizzata">
            {activity?.activity_data?.slab_dimensions}
          </FormDisplay>
        </div>
        <div className='col-lg-4 col-md-6'>
          <FormDisplay label="Dimensione del primario del primario">
            {activity?.activity_data?.primary_dimensions}
          </FormDisplay>
        </div>
      </div>

      <TaglioReportsTable>
        <ReportCheckboxShowRows activity={activity} workConfig={workConfig}/>
      </TaglioReportsTable>

    </>
  )
}

export default TaglioShow
