import React, { useContext } from 'react';
import GenericOffCanvas from '../../../../../../components/Custom/UI/Canvas/GenericOffCanvas';
import { LotContext } from '../../../LotWrapper';
import { ApplicationContext } from '../../../../../../components/Layout/Layout';
import formConfig from './form.config';
import { lotWorkService } from '../../../../../../services/lotWorkService';
import { toast } from 'react-toastify';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import LotWorkConfigForm from './LotWorkConfigForm';
import SubmitButton from '../../../../../../components/Custom/Form/Buttons/SubmitButton';

const LotWorksCanvas = ({ handleUpdateLotWorks, isOpen, toggle }) => {

  const { lot, templates, programs } = useContext(LotContext)
  const { lot_work_configs } = useContext(ApplicationContext)

  const handleSubmit = (values, { setSubmitting }) => {
    lotWorkService.store(lot.uuid, values)
    .then(res => handleUpdateLotWorks(res))
    .catch(err => toast.error(err.response.data.message))
    .finally(() => setSubmitting(false))
  }

  const formik = useFormik({
    initialValues: formConfig.setInitialValues(lot_work_configs, lot.lot_works),
    enableReinitialize: true,
    validationSchema: formConfig.validation,
    onSubmit: handleSubmit
  })

  return (
    <GenericOffCanvas
      title='Configura lavorazioni'
      isOpen={isOpen}
      toggle={toggle}>
      <FormikProvider value={formik}>
        <Form>
          <FieldArray
            name='lot_works'
            render={() => (
              <div className='row'>
                {formik.values.lot_works.map((lotWork, index) => (
                  <LotWorkConfigForm
                    key={lotWork.work_type}
                    index={index}
                    work={lotWork}
                    templates={templates}
                    programs={programs}
                  />
                ))}
              </div>
            )}
          />
          <div className='d-flex align-items-center justify-content-end'>
            <SubmitButton/>
          </div>
        </Form>
      </FormikProvider>
    </GenericOffCanvas>
  )

}

export default LotWorksCanvas
