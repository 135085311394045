import React from 'react';

const TaglioReportsTable = ({children, action = undefined}) => {

  return (
    <div className='table-responsive'>
      <table className='table table-hover align-middle'>
        <thead>
        <tr>
          <th className='text-end'>Controllo</th>
          <th className='text-center'>Aspetto del taglio</th>
          <th className='text-center'>Dimensione del taglio</th>
        </tr>
        <tr>
          <th className='text-end'>Aspetto visivo</th>
          <th className='text-center'>Guara</th>
          <th className='text-center'>+0,5mm/-0mm</th>
        </tr>
        </thead>
        <tbody>
        {children}
        </tbody>
        {action}
      </table>
    </div>
  )

}

export default TaglioReportsTable
