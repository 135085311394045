const open = 'open';
const in_progress = 'in_progress';
const suspended = 'suspended';
const closed = 'closed';
const shipped = 'shipped';

export const LotStatesEnum = {
    open,
    in_progress,
    suspended,
    closed,
    shipped
}

export const LotStatusEnumArray = [
    {value: 'open', label: 'Aperto', color: 'info'},
    {value: 'in_progress', label: 'In Corso', color: 'warning'},
    {value: 'suspended', label: 'In Sospeso', color: 'danger'},
    {value: 'closed', label: 'Chiuso', color: 'success'},
    {value: 'shipped', label: 'Spedito|In Magazzino', color: 'success'},
]
