import React, { useState } from "react";
import { Button, Card, CardBody, Spinner } from "reactstrap";
import moment from "moment";
import { useRequest } from "ahooks";
import { lotService } from "../../../services/lotService";
import DateFormatDisplay from "../../../components/Custom/Form/Display/DateFormatDisplay";
import MixedStackedBarChart from '../../../components/Common/MixedStackedBarChart';
import { format, parseISO } from 'date-fns';

const Stats = () => {

  const dateFormat = 'YYYY-MM-DD'

  const [period, setPeriod] = useState({
    start_date: moment().format(dateFormat),
    end_date: moment().format(dateFormat)
  });

  const setToday = () => {
    setPeriod({
      start_date: moment().format(dateFormat),
      end_date: moment().format(dateFormat)
    })
  }

  const setThisWeek = () => {
    setPeriod({
      start_date: moment().startOf('week').format(dateFormat),
      end_date: moment().endOf('week').format(dateFormat)
    })
  }
  const setThisMonth = () => {
    setPeriod({
      start_date: moment().startOf('month').format(dateFormat),
      end_date: moment().endOf('month').format(dateFormat)
    })
  }
  const setThisQuarter = () => {
    setPeriod({
      start_date: moment().startOf('quarter').format(dateFormat),
      end_date: moment().endOf('quarter').format(dateFormat)
    })
  }
  const setThisYear = () => {
    setPeriod({
      start_date: moment().startOf('years').format(dateFormat),
      end_date: moment().endOf('years').format(dateFormat)
    })
  }

  const { data = [], loading, run } = useRequest(lotService.statsAll, { manual: true })

  return <>
    <Card>
      <CardBody>
        <div className="d-flex align-content-start justify-content-center gap-2">
          <div>
            <input
              type={'date'}
              disabled={loading}
              value={period.start_date}
              onChange={(event) => setPeriod({ ...period, start_date: event.target.value })}
              className="form-control form-control-sm"
            />
          </div>
          <div>
            <input
              type={'date'}
              disabled={loading}
              value={period.end_date}
              onChange={(event) => setPeriod({ ...period, end_date: event.target.value })}
              className="form-control form-control-sm"
            />
          </div>
          <Button onClick={() => setToday()} type="button" color="link" size='sm'>Oggi</Button>
          <Button onClick={() => setThisWeek()} type="button" color="link" size='sm'>Settimana
            corrente</Button>
          <Button onClick={() => setThisMonth()} type="button" color="link" size='sm'>Mese corrente</Button>
          <Button onClick={() => setThisQuarter()} type="button" color="link" size='sm'>Trimestre
            corrente</Button>
          <Button onClick={() => setThisYear()} type="button" color="link" size='sm'>Anno corrente</Button>
          <Button disabled={loading} onClick={() => run(period)} type="button" color="primary" size="sm">
            Filtra <i className="fa fa-filter"/>
          </Button>
        </div>
      </CardBody>
    </Card>


    <div className="text-center text-primary d-flex gap-2 justify-content-center align-content-center fs-3 mb-3 fw-bold">
      <span>DAL</span>
      <DateFormatDisplay value={period.start_date}/>
      <span>AL</span>
      <DateFormatDisplay value={period.end_date}/>
    </div>

    <Card>
      <CardBody>

        {loading
          ? <div className="text-center"><Spinner size='sm'/> Caricamento</div>
          : data
            ? <MixedStackedBarChart
              name={`Periodo di rilevamento grafico lavorazioni dal ${format(parseISO(period.start_date), 'dd/MM/yyyy')} al ${format(parseISO(period.end_date), 'dd/MM/yyyy')}`}
              series={data?.series}
              categories={data?.categories}
            />
            : <div className='text-center'>Nessun data presente</div>
        }
      </CardBody>
    </Card>

  </>

}

export default Stats
