import FormGroup from '../../../../../../../../components/Custom/Form/FormGroup';
import { FormCheckbox } from '../../../../../../../../components/Custom/Form/FormCheckbox';
import React from 'react';
import SelectAllCheckboxesButton from '../../components/SelectAllCheckboxesButton';
import MolaturaReportsTable from './MolaturaReportsTable';
import ReportCheckboxFormRows from '../../components/ReportCheckboxFormRows';
import ActivityCopyExtraDataButton from '../../components/ActivityCopyExtraDataButton';
import { useFormikContext } from 'formik';

const MolaturaForm = ({ workConfig }) => {

  const { setValues } = useFormikContext()
  const handleUpdateValues = v => setValues(values => ({
    ...values,
    activity_data: {
      ...values.activity_data,
      belt_passage: v.belt_passage ?? false
    }
  }))

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <FormGroup label="Eseguito passaggio al nastro" name="activity_data.belt_passage" required={true} inline={false}>
            <FormCheckbox name="activity_data.belt_passage" label="Si, è stato eseguito"/>
          </FormGroup>
        </div>
        <div className='col-lg-8 col-md-6 d-flex align-items-end pb-3 justify-content-end'>
          <ActivityCopyExtraDataButton handleUpdateData={handleUpdateValues}/>
        </div>
      </div>

      <MolaturaReportsTable action={<SelectAllCheckboxesButton workConfig={workConfig}/>}>
        <ReportCheckboxFormRows workConfig={workConfig}/>
      </MolaturaReportsTable>

    </>
  )

}

export default MolaturaForm
