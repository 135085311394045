import * as Yup from 'yup';

export const activityValidationSchema = {
  date: Yup.date("Data non valida").required("Data attività obbligatoria"),
  hour_amount: Yup.string().test("is-greater-then-zero", "Mimimo un minuto", (value) => {
    return value !== '00:00';
  }).required("Obbligatorio"),

  stop_hour_amount: Yup.string().required("Obbligatorio"),
  stop_cause: Yup.string().max(250).notRequired().nullable(),
  note: Yup.string().max(250).notRequired().nullable(),

  manufactured_qty: Yup.number().min(0).required("Obbligatorio"),
  wasted_qty: Yup.number().min(0).required("Obbligatorio"),
  rework_qty: Yup.number().min(0).required("Obbligatorio"),
  to_be_reworked_qty: Yup.number().min(0).required("Obbligatorio"),

  manufactured_dx_qty: Yup.number().min(0).required("Obbligatorio"),
  wasted_dx_qty: Yup.number().min(0).required("Obbligatorio"),
  rework_dx_qty: Yup.number().min(0).required("Obbligatorio"),
  to_be_reworked_dx_qty: Yup.number().min(0).required("Obbligatorio"),

  manufactured_sx_qty: Yup.number().min(0).required("Obbligatorio"),
  wasted_sx_qty: Yup.number().min(0).required("Obbligatorio"),
  rework_sx_qty: Yup.number().min(0).required("Obbligatorio"),
  to_be_reworked_sx_qty: Yup.number().min(0).required("Obbligatorio"),

  control_reports: Yup.array().of(
    Yup.array().of(Yup.boolean().required())
  )
}

// ############################################################
// TAGLIO
// ############################################################
export const taglioValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    slab_manufacturer: Yup.string().max(100).required("Obbligatorio"),
    c_lot_number: Yup.string().max(20).notRequired().nullable(),
    slab_number: Yup.number().min(0).required("Obbligatorio"),
    slab_dimensions: Yup.string().max(100).required("Obbligatorio"),
    primary_dimensions: Yup.string().max(100).required("Obbligatorio"),
    operator: Yup.array().min(1, "Obbligatorio").required("Obbligatorio"),
  }),
});

// ############################################################
// MOLATURA
// ############################################################
export const molaturaValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    belt_passage: Yup.boolean().required("Obbligatorio"),
    operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// FORATURA E LAVAGGIO FASE 1
// ############################################################
export const foraturaELavaggioFaseUnoValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    load_operator: Yup.array().min(1).required("Obbligatorio"),
    unload_operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});


// ############################################################
// FORATURA E LAVAGGIO FASE 2
// ############################################################
export const foraturaELavaggioFaseDueValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    load_operator: Yup.array().min(1).required("Obbligatorio"),
    unload_operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// LAVAGGIO
// ############################################################

export const lavaggioValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    load_operator: Yup.array().min(1).required("Obbligatorio"),
    unload_operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// IMBALLO DOPO LAVAGGIO
// ############################################################

export const imballoDopoLavaggioValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// SABBIATURA
// ############################################################

export const sabbiaturaValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// LAVAGGIO DOPO SABBIATURA
// ############################################################

export const lavaggioDopoSabbiaturaValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    load_operator: Yup.array().min(1).required("Obbligatorio"),
    unload_operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// SERIGRAFIA LATERALI
// ############################################################

export const serigrafiaLateraliValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    black_ink_supplier: Yup.string().max(200).required("Obbligatorio"),
    black_ink_lot: Yup.string().max(200).required("Obbligatorio"),
    load_operator: Yup.array().min(1).required("Obbligatorio"),
    unload_operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});


// ############################################################
// CONTROLLO FACCIATA
// ############################################################

export const controlloFacciataValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// SERIGRAFIA LUNOTTI FASE UNO
// ############################################################

export const serigrafiaLunottiFaseUnoValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    black_ink_supplier: Yup.string().max(200).required("Obbligatorio"),
    black_ink_lot: Yup.string().max(200).required("Obbligatorio"),
    load_operator: Yup.array().min(1).required("Obbligatorio"),
    unload_operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// SERIGRAFIA LUNOTTI FASE DUE
// ############################################################

export const serigrafiaLunottiFaseDueValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    silver_paste_supplier: Yup.string().max(200).required("Obbligatorio"),
    silver_paste_lot: Yup.string().max(200).required("Obbligatorio"),
    silver_paste_qty: Yup.number().required(),
    load_operator: Yup.array().min(1).required("Obbligatorio"),
    unload_operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// SERIGRAFIA LUNOTTI FASE TRE
// ############################################################

export const serigrafiaLunottiFaseTreValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    black_ink_supplier: Yup.string().max(200).required("Obbligatorio"),
    black_ink_lot: Yup.string().max(200).required("Obbligatorio"),
    load_operator: Yup.array().min(1).required("Obbligatorio"),
    unload_operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// PASSAGGIO AL FORNO
// ############################################################

export const passaggioAlFornoValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    load_operator: Yup.array().min(1).required("Obbligatorio"),
    unload_operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// TEMPERA
// ############################################################

export const temperaValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    oven_temperature: Yup.number().required("Obbligatorio"),
    with_fragmentation: Yup.boolean().required("Obbligatorio"),
    load_operator: Yup.array().min(1).required("Obbligatorio"),
    unload_operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// IMBALLO DOPO TEMPERA
// ############################################################

export const imballoDopoTemperaValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});

// ############################################################
// ATTACCO TERMINALI
// ############################################################

const attaccoTerminaliValidation = Yup.object().shape({
  ...activityValidationSchema,
  activity_data: Yup.object().shape({
    tool: Yup.number().required("Obbligatorio"),
    terminals: Yup.string().max(200).required("Obbligatorio"),
    terminals_supplier: Yup.string().max(200).required("Obbligatorio"),
    terminals_lot: Yup.string().max(200).required("Obbligatorio"),
    current_load: Yup.number().required("Obbligatorio"),
    operator: Yup.array().min(1).required("Obbligatorio"),
  }),
});
