import React from 'react'
import { FormDisplay } from "../../../../../../../../components/Custom/Form/Display/FormDisplay";
import SerigrafiaLateraliReportsTable from './SerigrafiaLateraliReportsTable';
import ReportCheckboxShowRows from '../../components/ReportCheckboxShowRows';

const SerigrafiaLateraliShow = ({ activity, workConfig }) => {

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <FormDisplay label="Fornitore vernice nera">
            {activity?.activity_data?.black_ink_supplier}
          </FormDisplay>
        </div>
        <div className="col-lg-4 col-md-6">
          <FormDisplay label="Lotto vernice nera n°">
            {activity?.activity_data?.black_ink_lot}
          </FormDisplay>
        </div>
      </div>

      <SerigrafiaLateraliReportsTable>
        <ReportCheckboxShowRows activity={activity} workConfig={workConfig}/>
      </SerigrafiaLateraliReportsTable>
    </>
  )
}

export default SerigrafiaLateraliShow
