import React from 'react';
import SelectAllCheckboxesButton from '../../components/SelectAllCheckboxesButton';
import LavaggioReportsTable from './LavaggioReportsTable';
import ReportCheckboxFormRows from '../../components/ReportCheckboxFormRows';

const LavaggioForm = ({ workConfig }) => {

  return (
    <>
      <LavaggioReportsTable action={<SelectAllCheckboxesButton workConfig={workConfig}/>}>
        <ReportCheckboxFormRows workConfig={workConfig}/>
      </LavaggioReportsTable>
    </>
  )

}

export default LavaggioForm
