import React from 'react';

const AttaccoTerminaliReportsTable = ({children, action = undefined}) => {

  return (
    <div className='table-responsive'>
      <table className='table table-hover align-middle'>
        <thead>
        <tr>
          <th className='text-end'>Controllo capi corda</th>
          <th className='text-center'>Resistenza della saldatura</th>
          <th className='text-center'>Verifica conducibilità</th>
        </tr>
        </thead>
        <tbody>
        {children}
        </tbody>
        {action}
      </table>
    </div>
  )

}

export default AttaccoTerminaliReportsTable
