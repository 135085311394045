import React from 'react';
import { activityService } from '../../../../../../../services/ActivityService';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const ActivityCopyExtraDataButton = ({ handleUpdateData }) => {

  const { id, lot_work_id } = useParams()
  const handleClick = () => {
    activityService.getActivityData(id, lot_work_id)
    .then(res => handleUpdateData(res))
    .catch(err => toast.error(err.response.data.message))
  }

  return (
    <div className='d-flex align-items-center justify-content-end'>
      <button
        type='button'
        className='btn btn-primary'
        onClick={handleClick}
      >
        <i className='fa fa-copy'/>&nbsp;
        Copia da attività
      </button>
    </div>
  )

}

export default ActivityCopyExtraDataButton
