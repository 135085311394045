import React from "react";
import FormGroup from "../../../../../../../components/Custom/Form/FormGroup";
import { InputField } from "../../../../../../../components/Custom/Form/InputField";
import { FormSelect } from "../../../../../../../components/Custom/Form/FormSelect";
import { useFormikContext } from "formik";

const ActivityTopForm = ({ tools = [], withTools }) => {

  const { setFieldValue } = useFormikContext()

  return (

    <div className='row'>
      <div className='col-lg-4'>
        <FormGroup name="date" label="Data" required={true} inline={false}>
          <InputField name="date" type="date"/>
        </FormGroup>
      </div>
      <div className='col-lg-4'>
        {withTools &&
          <FormGroup label="Lavorazione eseguita con" required={true} inline={false} name="activity_data.tool">
            <FormSelect
              name="activity_data.tool"
              onChange={(value) => setFieldValue('activity_data.tool', value)}
              placeholder="Seleziona uno strumento"
              options={tools}
              multiple={false}
            />
          </FormGroup>
        }
      </div>
      <div className='col-lg-4'>
        <FormGroup name="hour_amount" label="Ore lavorate" required={true} inline={false}>
          <InputField name="hour_amount" type="time"/>
        </FormGroup>
      </div>
    </div>
  );

}

export default ActivityTopForm
