import React from "react";
import ItemDetails from './ItemDetails/ItemDetails';
import ItemWorks from './ItemWorks/ItemWorks';
import { useRequest } from 'ahooks';
import { itemService } from '../../../../services/itemService';
import { useParams } from 'react-router-dom';

const Item = () => {

  const { id } = useParams()
  const { data: item, loading, mutate: setItem } = useRequest(itemService.fetch, { defaultParams: [id] })

  return loading ? <></> : (
    <React.Fragment>
      <ItemDetails item={item} setItem={setItem}/>
      <ItemWorks/>
    </React.Fragment>
  )
}

export default Item;
