import React, { useContext, useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import lotShipmentService from '../../../../../../services/lotShipmentService';
import { lotService } from '../../../../../../services/lotService';
import LotShipmentDetailsHeader from './LotShipmentDetailsHeader';
import { LotStatesEnum } from '../../../../../../constants/enums/LosStatesEnum';
import tableOptions from '../../../../../../constants/tableOptions';
import { PaginatedTable } from '@dvsoftsrl/react-laravel-paginated-table';
import DateFormatDisplay from '../../../../../../components/Custom/Form/Display/DateFormatDisplay';
import { LotContext } from '../../../LotWrapper';
import LotShipmentOffCanvas from './LotShipmentOffCanvas';
import { useSanctum } from 'react-sanctum';

const LotShipmentDetails = () => {

  const { lot, fetchStates } = useContext(LotContext)
  const { user } = useSanctum()
  const { data = [], loading, run } = useRequest(lotShipmentService.fetchAll, { manual: true });
  const { data: shipmentStatus, run: fetchShipmentsStatus } = useRequest(lotService.shipmentsStatus, {
    defaultParams: [lot?.uuid],
    ready: lot?.uuid !== undefined
  });
  const [isCanvasOpen, setIsCanvasOpen] = useState(false)
  const [currentSelected, setCurrentSelected] = useState(undefined);

  useEffect(() => {
    if (currentSelected) {
      setIsCanvasOpen(true)
    }
  }, [currentSelected])

  useEffect(() => {
    if (!isCanvasOpen) {
      setCurrentSelected(undefined)
    }
  }, [isCanvasOpen])

  const columns = React.useMemo(() => [
    { accessor: 'qty', Header: 'Piani' },
    { accessor: 'dx_qty', Header: 'Destri' },
    { accessor: 'sx_qty', Header: 'Sinistri' },
    { accessor: "shipment_date", Header: 'Data Spedizione', Cell: ({ value }) => <DateFormatDisplay value={value}/> },
    { Header: 'Utente', accessor: 'username' },
    { accessor: "updated_at", Header: 'Data', Cell: ({ value }) => <DateFormatDisplay value={value}/> },
    {
      accessor: "id",
      Header: '', Cell: ({ row }) =>
        lot?.state?.value !== LotStatesEnum.shipped && user.can_manage_production_module &&
        <button
          type='button'
          className='btn btn-primary btn-icon btn-sm float-end'
          onClick={() => setCurrentSelected(row.original)}>
          <i className='fa fa-pen'/>
        </button>
    }], [lot])

  return (
    <>
      <div className='card card-body'>
        <div className='d-flex align-items-center justify-content-between mb-3'>
          <h3>Spedizioni</h3>

          {user.can_manage_production_module &&
            <button
              className='btn btn-primary btm-icon'
              onClick={() => setIsCanvasOpen(true)}>
              <i className='fa fa-plus'/>
            </button>
          }
        </div>
        <LotShipmentDetailsHeader shipmentStatus={shipmentStatus}/>

        <PaginatedTable
          sortable={false}
          options={tableOptions}
          columns={columns}
          data={data ?? []}
          isLoading={loading}
          onParamsChange={() => run(lot.uuid)}
        />
      </div>

      <LotShipmentOffCanvas
        toggle={setIsCanvasOpen}
        isOpen={isCanvasOpen}
        data={currentSelected}
        lot={lot}
        shipmentStatus={shipmentStatus}
        handleResponse={res => {
          setCurrentSelected(undefined)
          fetchShipmentsStatus(lot.uuid)
          fetchStates()
          run(lot.uuid)
        }}
      />
    </>
  )

}

export default LotShipmentDetails
