import React from 'react';
import { FormDisplay } from '../../../../../../../../../../components/Custom/Form/Display/FormDisplay';

const ProvaDiTipoShow = ({ color, provaTipo }) => {

  return (
    <tbody>
    <tr className={`table-${color}`}>
      <td>Classifica di esecuzione della prova</td>
      <td>TIPO {provaTipo.letter}</td>
      <td>Acquisizione digitale</td>
      <td className='fw-bold'>
        {provaTipo.acquisizione_digitale ? 'SI' : 'NO'}
      </td>
    </tr>

    {provaTipo.prove.map((prova, provaIndex) => (
      <tr key={provaIndex}>
        <td>Prova di rottura nel punto</td>
        <td>
          <div className='d-flex align-items-center justify-content-start gap-2'>
            <div className='text-nowrap'>
              <FormDisplay inline label={`3 Or${provaIndex > 0 ? 'e' : 'a'}`}>
                {prova?.orario ?? '---'}
              </FormDisplay>
            </div>
          </div>
        </td>
        <td colSpan={2}>
          <div className='d-flex align-items-center justify-content-start gap-2 text-nowrap'>
            <FormDisplay inline label='Esito'>
              {prova?.esito === null || prova.esito === undefined
                ? <span></span>
                : prova?.esito
                  ? <span className='fw-bold text-success'><i className='fa fa-check'/>&nbsp;Positivo</span>
                  : <span className='fw-bold text-danger'><i className='fa fa-times'/>&nbsp;Negativo</span>
              }
            </FormDisplay>
          </div>
        </td>
      </tr>
    ))}

    <tr>
      <td></td>
      <td>
        <div className='d-flex align-items-center justify-content-start gap-2 text-nowrap'>
          <FormDisplay inline label='Eseguito da'>
            {provaTipo.eseguito_da}
          </FormDisplay>
        </div>
      </td>
      <td colSpan={2}>
        <div className='d-flex align-items-center justify-content-start gap-2 text-nowrap'>
          <FormDisplay inline label='Li'>
            {provaTipo.li}
          </FormDisplay>
        </div>
      </td>
    </tr>
    <tr>
      <td></td>
      <td colSpan={3}>
        <div className='d-flex align-items-center justify-content-start gap-2 text-nowrap'>
          <FormDisplay inline label='Note'>
            {provaTipo.note}
          </FormDisplay>
        </div>
      </td>
    </tr>
    </tbody>
  )

}

export default ProvaDiTipoShow
