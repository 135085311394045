import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import FormGroup from "../../../../../components/Custom/Form/FormGroup";
import ItemPicker from "../../../../../components/Custom/Form/Pickers/ItemPicker/ItemPicker";
import {formConfig} from "../form.config";
import {FormDisplay} from "../../../../../components/Custom/Form/Display/FormDisplay";
import {useFormikContext} from "formik";

const LotItemDescriptionForm = ({item, setItem}) => {

    const {setValues, values} = useFormikContext()

    return (
        <Card>
            <CardBody>
                <h3>Descrizione codice</h3>
                <Row>
                    <Col lg={4}>
                        <FormGroup name='item_id' label="Articolo" inline={false} required={true}>
                            <ItemPicker name='item_id' onSelected={(item) => {
                                setValues({
                                    ...values,
                                    ...formConfig.setItem(item)
                                })
                                setItem(item)
                            }}/>
                        </FormGroup>
                    </Col>
                    <Col lg={4}>
                        <FormDisplay
                            label="Case automobilistica">{item?.manufacturer?.name}</FormDisplay>
                    </Col>
                    <Col lg={4}>
                        <FormDisplay label="Modello vettura">{item?.vehicle?.name}</FormDisplay>
                    </Col>
                </Row>
                <FormDisplay label="Descrizione">{item?.description}</FormDisplay>
            </CardBody>
        </Card>
    )

}

export default LotItemDescriptionForm
