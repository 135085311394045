import React from 'react';
import { Outlet } from 'react-router-dom';
import CheckUserModule from '../../../components/Custom/Permissions/CheckUserModule';

const ToolConfigWrapper = () => {

  return (
    <CheckUserModule module={['can_manage_industry_module']}>
      <Outlet/>
    </CheckUserModule>
  )

}

export default ToolConfigWrapper
