import React from 'react';
import ItemsWrapper from "../../pages/Production/Items/ItemsWrapper";
import Items from "../../pages/Production/Items/Items/Items";
import Item from "../../pages/Production/Items/Item/Item";

const itemsRoutes = {
  path: '/items',
  element: <ItemsWrapper/>,
  children: [
    {
      path: '',
      breadcrumb: "Elenco Articoli",
      element: <Items/>
    },
    {
      path: ':id/*',
      breadcrumb: "Articolo",
      element: <Item/>
    },
  ]
}


export default itemsRoutes
