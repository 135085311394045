import React from 'react';
import GenericOffCanvas from '../../../../../../../../components/Custom/UI/Canvas/GenericOffCanvas';
import ActivityFileUploader from './ActivityFileUploader';

const MediaOffCanvas = ({ isOpen, toggle, activity, lotWork, lot }) => {

  return (
    <GenericOffCanvas
      title='Gestione file'
      isOpen={isOpen}
      toggle={toggle}>

      <ActivityFileUploader
        activity={activity}
        lotWork={lotWork}
        lot={lot}
      />

    </GenericOffCanvas>
  )

}

export default MediaOffCanvas
