import React from 'react'
import {Offcanvas, OffcanvasBody, OffcanvasHeader} from "reactstrap";

const GenericOffCanvas = ({title, children, isOpen, toggle}) => {
    return (
        <Offcanvas backdrop={true} direction="end" isOpen={isOpen} toggle={() => toggle(!isOpen)}>
            <OffcanvasHeader>{title}</OffcanvasHeader>
            <OffcanvasBody>
                {children}
            </OffcanvasBody>
        </Offcanvas>
    )
}

export default GenericOffCanvas