import React, { useState } from 'react'
import { FormDisplay } from "../../../../../components/Custom/Form/Display/FormDisplay";
import EditButton from '../../../../../components/Custom/Form/Buttons/EditButton';
import ItemEditCanvas from '../../component/ItemEditCanvas';
import DeleteButton from '../../../../../components/Custom/Form/Buttons/DeleteButton';
import { itemService } from '../../../../../services/itemService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ItemDetails = ({ item, setItem }) => {

  const [canvasOpen, setCanvasOpen] = useState(false);
  const navigate = useNavigate()

  const handleDelete = () => {
    itemService.destroy(item.uuid)
    .then(res => {
      toast.success('Operazione eseguita con successo')
      navigate('/items')
    })
    .catch(err => toast.error(err.response.data.message))
  }

  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h3>Articolo</h3>
          <div className='row'>
            <div className='col-lg-6'>
              <FormDisplay label="Codice" inline={false}><h3>{item.code}</h3></FormDisplay>
              <FormDisplay label="Descrizione" inline={false}>{item.description}</FormDisplay>
            </div>
            <div className='col-lg-6'>
              <FormDisplay label="Casa automobilistica" inline={false}>{item.manufacturer?.name}</FormDisplay>
              <FormDisplay label="Modello Vettura" inline={false}>{item.vehicle?.name}</FormDisplay>
            </div>
          </div>
        </div>

        <div className='card-body'>
          <h3>Caratteristiche</h3>
          <div className='row'>
            <div className='col-lg-6'>
              <FormDisplay label="Marchio" inline={false}>{item.brand?.name}</FormDisplay>
            </div>
            <div className='col-lg-6'>
              <FormDisplay label="Colore" inline={false}>{item.color?.name}</FormDisplay>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'>
              <FormDisplay label="Curvatura" inline={false}>{item.curvature?.name}</FormDisplay>
            </div>
            <div className='col-lg-6'>
              <FormDisplay label="Spessore (mm)" inline={false}>{item.thickness?.value}</FormDisplay>
            </div>
          </div>
          <FormDisplay label="Note">{item.note}</FormDisplay>

          <div className='d-flex align-items-center justify-content-end gap-1'>
            <EditButton handleClick={() => setCanvasOpen(true)}/>
            <DeleteButton message="Sei sicuro di voler eliminare il codice?" handleClick={() => handleDelete()}/>
          </div>
        </div>

      </div>

      <ItemEditCanvas
        isOpen={canvasOpen}
        toggle={setCanvasOpen}
        data={item}
        handleResponse={res => {
          setCanvasOpen(c => false)
          setItem(item => ({ ...item, ...res }))
        }}
      />
    </>
  )

}

export default ItemDetails
