import React from "react";
import { useFormikContext } from "formik";
import { FormCheckbox } from '../../../../../../components/Custom/Form/FormCheckbox';
import FormGroup from '../../../../../../components/Custom/Form/FormGroup';
import { FormSelect } from '../../../../../../components/Custom/Form/FormSelect';

const LotWorkConfigForm = ({ work, templates, programs, index }) => {

  const { values } = useFormikContext()

  return (
    <div className='mb-3'>
      <h5>
        <FormCheckbox name={`lot_works[${index}].selected`} label={`${index + 1} - ${work.work_type_name}`}/>
      </h5>

      <div className='row'>

        {work.has_template &&
          <div className='col-md-6'>
            <FormGroup label="Dima/Telaio" required={false} name="template_id">
              <FormSelect
                name={`lot_works[${index}].template_id`}
                placeholder="Seleziona una dima/telaio"
                disabled={!values.lot_works[index].selected}
                options={(templates || []).filter(t => t.work_types.includes(work.work_type))}
                multiple={false}
              />
            </FormGroup>
          </div>}

        {work.has_program &&
          <div className='col-md-6'>
            <FormGroup label="Programma" required={false} name="program_id">
              <FormSelect
                name={`lot_works[${index}].program_id`}
                placeholder="Seleziona un programma"
                disabled={!values.lot_works[index].selected}
                options={(programs || []).filter(t => t.work_types.includes(work.work_type))}
                multiple={false}
              />
            </FormGroup>
          </div>}

      </div>


      {work.extra_data.length > 0 &&
        <div className='row'>
          {work.extra_data.map((extraData, extraDataIndex) =>
            <div className='col-md-6' key={`${work.work_type}_${extraData.value}`}>
              <FormGroup
                label={extraData.label}
                name={`lot_works[${index}].extra_data[${extraDataIndex}].value`}
                required={false}>
                <FormSelect
                  name={`lot_works[${index}].extra_data[${extraDataIndex}].value`}
                  placeholder={`Seleziona ${extraData.label}`}
                  disabled={!values.lot_works[index].selected}
                  options={extraData.options}
                  multiple={false}
                />
              </FormGroup>
            </div>
          )}
        </div>
      }

    </div>
  )

}

export default LotWorkConfigForm
