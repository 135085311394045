import React, { useState } from "react"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { Formik } from "formik"
import { InputField } from "../../components/Custom/Form/InputField";
import FormGroup from "../../components/Custom/Form/FormGroup";
import { useSanctum } from "react-sanctum";
import { Alert } from "reactstrap";

const Login = () => {

  const { signIn } = useSanctum();

  const initialValues = {
    username: "",
    password: ""
  }

  const validation = Yup.object().shape({
    username: Yup.string().required("Nome utente obbligatorio"),
    password: Yup.string().required("Password obbligatoria")
  })

  const [error, setError] = useState(undefined);

  return (<>
    <h3>Login</h3>
    {error && <Alert color="danger">{error}</Alert>}
    <Formik initialValues={initialValues}
            validationSchema={validation}
            onSubmit={(values, { setSubmitting }) => {
              setError(undefined)
              signIn(values.username, values.password, false)
              .then((response) => true)
              .catch((error) => setError(error.response.data.message))
              .finally(() => setSubmitting(false))
            }}>
      {({ isSubmitting, handleSubmit, isValid, values }) => (
        <form onSubmit={handleSubmit} className="form-horizontal">
          <FormGroup name="username" label="Username" required={true}
                     inline={false}>
            <InputField name="username" type="text"/>
          </FormGroup>
          <FormGroup name="password" label="Password" required={true}
                     inline={false}>
            <InputField name="password" type="password"/>
          </FormGroup>
          <div className="d-flex align-content-center justify-content-between">
            <Link to="/forgot-password" className="text-muted align-self-center">
              Password dimenticata?
            </Link>
            <button className="btn btn-primary btn-block" type="submit" disabled={isSubmitting || !isValid}>
              Accedi
            </button>
          </div>
        </form>)}
    </Formik>
  </>)
}

export default Login
