import React, { useMemo } from 'react';
import GenericOffCanvas from '../../../../components/Custom/UI/Canvas/GenericOffCanvas';
import { Form, FormikProvider, useFormik } from 'formik';
import formConfig from './form.config';
import { itemService } from '../../../../services/itemService';
import { toast } from 'react-toastify';
import CancelButton from '../../../../components/Custom/Form/Buttons/CancelButton';
import SubmitButton from '../../../../components/Custom/Form/Buttons/SubmitButton';
import ItemForm from './ItemForm';

const ItemEditCanvas = ({ isOpen, toggle, data, handleResponse }) => {

  const initialValues = useMemo(() => formConfig.setEditInitialValues(data), [isOpen])

  const handleSubmit = (values, { setSubmitting }) => itemService.update(data.uuid, values)
  .then(res => {
    toast.success('Operazione eseguita con successo')
    handleResponse(res)
  })
  .catch(err => toast.error(err.response.data.message))
  .finally(() => setSubmitting(false))


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formConfig.editValidation,
    onSubmit: handleSubmit
  })

  return (
    <GenericOffCanvas title='Modifica articolo' isOpen={isOpen} toggle={toggle}>
      <FormikProvider value={formik}>
        <Form>
          <ItemForm/>

          <div className="d-flex align-content-center justify-content-end gap-1">
            <CancelButton handleClick={() => toggle(false)}/>
            <SubmitButton/>
          </div>

        </Form>
      </FormikProvider>
    </GenericOffCanvas>

  )

}

export default ItemEditCanvas
