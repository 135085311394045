import React from 'react';

const malnatiCols = [
  {
    Header: 'Data',
    accessor: 'date',
  },
  {
    Header: 'Commessa',
    accessor: 'lot',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Vetri lavati',
    accessor: 'data[3]',
    Cell: ({ value }) => <>{value || ""}</>
  },
  {
    Header: 'Spessore inviato da Forvet',
    accessor: 'data[4]',
    Cell: ({ value }) => <>{Number(value).toFixed(2) || ""}</>
  },
  {
    Header: 'Spessore rilevato da Malnati',
    accessor: 'data[5]',
    Cell: ({ value }) => <>{Number(value).toFixed(2) || ""}</>
  },
  {
    Header: 'Velocità',
    accessor: 'data[6]',
    Cell: ({ value }) => <>{Number(value).toFixed(2) || ""}</>
  },
];

export default malnatiCols
