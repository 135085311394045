import React from 'react'
import { FormDisplay } from "../../../../../../../../components/Custom/Form/Display/FormDisplay";
import AttaccoTerminaliReportsTable from './AttaccoTerminaliReportsTable';
import ReportCheckboxShowRows from '../../components/ReportCheckboxShowRows';

const AttaccoTerminaliShow = ({ activity, workConfig }) => {

  return (
    <>
      <div className='row'>
        <div className='col-md-6 col-lg-4'>
          <FormDisplay label="Terminali utilizzati">
            {activity?.activity_data?.terminals}
          </FormDisplay>
        </div>
        <div className='col-md-6 col-lg-4'>
          <FormDisplay label="Fornitore terminali">
            {activity?.activity_data?.terminals_supplier}
          </FormDisplay>
        </div>
        <div className='col-md-6 col-lg-4'>
          <FormDisplay label="Lotto terminali N°">
            {activity?.activity_data?.terminals_lot}
          </FormDisplay>
        </div>
        <div className='col-md-6 col-lg-4'>
          <FormDisplay label="Assorbimento a 12V (Ampere)">
            {activity?.activity_data?.current_load}
          </FormDisplay>
        </div>
      </div>

      <AttaccoTerminaliReportsTable>
        <ReportCheckboxShowRows activity={activity} workConfig={workConfig}/>
      </AttaccoTerminaliReportsTable>
    </>
  )
}

export default AttaccoTerminaliShow
