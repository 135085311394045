import React from 'react';
import GenericOffCanvas from '../../../../components/Custom/UI/Canvas/GenericOffCanvas';
import { Form, FormikProvider, useFormik } from 'formik';
import { itemService } from '../../../../services/itemService';
import { toast } from 'react-toastify';
import CancelButton from '../../../../components/Custom/Form/Buttons/CancelButton';
import SubmitButton from '../../../../components/Custom/Form/Buttons/SubmitButton';
import ItemForm from './ItemForm';
import itemFormConfigs from './form.config';
import ItemCategoryPicker from '../../../../components/Custom/Form/Pickers/ItemCategoryPicker/ItemCategoryPicker';
import { InputField } from '../../../../components/Custom/Form/InputField';
import FormGroup from '../../../../components/Custom/Form/FormGroup';

const ItemCreateCanvas = ({ isOpen, toggle, data, handleResponse }) => {

  const handleSubmit = (values, { setSubmitting }) => itemService.store(values)
  .then(res => {
    toast.success('Operazione eseguita con successo')
    handleResponse(res)
  })
  .catch(err => toast.error(err.response.data.message))
  .finally(() => setSubmitting(false))

  const formik = useFormik({
    initialValues: itemFormConfigs.createInitialValues,
    enableReinitialize: true,
    validationSchema: itemFormConfigs.createValidation,
    onSubmit: handleSubmit
  })

  return (
    <GenericOffCanvas title='Nuovo articolo' isOpen={isOpen} toggle={toggle}>
      <FormikProvider value={formik}>
        <Form>
          <h3>Categoria e codice</h3>
          <h5>{formik.values.item_category_code}.{formik.values.code}</h5>
          <div className='row'>
            <div className="col-lg-6">
              <FormGroup name="item_category_id" label="Radice Codice" required={true}>
                <ItemCategoryPicker
                  name="item_category_id"
                  onSelected={(category) => formik.setValues({
                    ...formik.values,
                    'item_category_code': category?.code || '',
                    'item_category_id': category?.value || '',
                    'description': category?.name || ''
                  })}/>
              </FormGroup>
            </div>
            <div className="col-lg-6">
              <FormGroup name="code" label="Codice" required={true}>
                <InputField name="code" type="text" maxLength={100}/>
              </FormGroup>
            </div>
          </div>

          <ItemForm/>

          <div className="d-flex align-content-center justify-content-end gap-1">
            <CancelButton handleClick={() => toggle(false)}/>
            <SubmitButton/>
          </div>

        </Form>
      </FormikProvider>
    </GenericOffCanvas>

  )

}

export default ItemCreateCanvas
